// @dynamic
export class Routes {
  // static coreServer = 'https://api-v3.prepd.in';
  // static webSocketServer = 'wss://websockets.prepd.in';
  // static dashboardClient = 'https://dashboard.prepd.in';
  // static classroomClient = 'https://classroom.prepd.in';
  // static signupClient = 'https://signup.prepd.in';

  static coreServer = 'https://api-v3-test.prepd.in';
  static webSocketServer = 'wss://websock-test.prepd.in';
  static dashboardClient = 'https://dashboard-test.prepd.in';
  static classroomClient = 'https://classroom-test.prepd.in';
  static signupClient = 'https://signup-test.prepd.in';

  // servers
  static get getBase() {
    return `${this.coreServer}`;
  }

  static get ws() {
    return `${this.webSocketServer}/ws`;
  }

  static get libraryWsToken() {
    return `${this.coreServer}/ws/authorize/library`;
  }

  static get extempWsToken() {
    return `${this.coreServer}/ws/authorize/extemp`;
  }

  static get congressWsToken() {
    return `${this.coreServer}/ws/authorize/congress`;
  }

  static get tokenLibrary() {
    return `${this.coreServer}/ws/authorize/library`;
  }

  static get tokenExtemp() {
    return `${this.coreServer}/ws/authorize/extemp`;
  }

  static get tokenCongress() {
    return `${this.coreServer}/ws/authorize/congress`;
  }

  static get librarySessionIdentifier() {
    return `${this.coreServer}/core/session/identity/library`;
  }

  static get extempSessionIdentifier() {
    return `${this.coreServer}/core/session/identity/extemp`;
  }

  static get congressSessionIdentifier() {
    return `${this.coreServer}/core/session/identity/congress`;
  }

  static get markEvent() {
    return `${this.coreServer}/mark/event`;
  }

  static get getCookie() {
    return `${this.coreServer}/core/cookie/get`;
  }

  static get setCookie() {
    return `${this.coreServer}/core/cookie/set`;
  }

  static get profile() {
    return `${this.coreServer}/core/profile`;
  }

  static get login() {
    return `${this.coreServer}/core/login`;
  }

  static get logout() {
    return `${this.coreServer}/core/logout`;
  }

  static get resetPassword() {
    return `${this.coreServer}/core/account/password/classroom-reset`;
  }

  static get userOptions() {
    return `${this.coreServer}/core/user/options/set`;
  }

  static get changeDefaultTeam() {
    return `${this.coreServer}/core/account/options/default_team/set`;
  }

  static get contributors() {
    return `${this.coreServer}/core/contributors/get`;
  }

  static get publications() {
    return `${this.coreServer}/core/publications/get`;
  }

  static get pic() {
    return `${this.coreServer}/core/account/pic`;
  }

  static get countPdfs() {
    return `${this.coreServer}/core/count/pdfs`;
  }

  static get editArticle() {
    return `${this.coreServer}/core/edit/article`;
  }

  static get deleteArticle() {
    return `${this.coreServer}/core/article/trash`;
  }

  static get flagArticle() {
    return `${this.coreServer}/core/article/flag`;
  }

  static get articlesIds() {
    return `${this.coreServer}/core/feed/ids`;
  }

  static get extempFolders() {
    return `${this.coreServer}/extemp/folders/list/simple`;
  }

  static get congressDockets() {
    return `${this.coreServer}/congress/docket/simple-list`;
  }

  static get uploadPdf() {
    return `${this.coreServer}/core/upload/pdf`;
  }

  static get roundStatistics() {
    return `${this.coreServer}/stats/round/upload`;
  }

  static get askCoachToUpgrade() {
    return `${this.coreServer}/core/team/ask-coach`;
  }

  static get postSubscriptionEndDate() {
    return `${this.coreServer}/core/team/subscription`;
  }

  // article view routes
  static get articleViewArticle() {
    return `${this.coreServer}/core/article/team/get`;
  }

  static get newsFeedArticleViewArticle() {
    return `${this.coreServer}/core/article/any/get`;
  }

  static get articleViewArticlePdf() {
    return `${this.coreServer}/core/article/pdf`;
  }

  static get articleViewCreateHighlight() {
    return `${this.coreServer}/core/highlight/create`;
  }

  static get articleViewDeleteHighlight() {
    return `${this.coreServer}/core/highlight/delete`;
  }

  static get markAsReadArgumentAnalysis() {
    return `${this.coreServer}/library/reading/argument`;
  }

  static get markAsReadPracticeQuestion() {
    return `${this.coreServer}/library/reading/practiceQ`;
  }

  static get markAsReadHowWhatWhereWhenWhy() {
    return `${this.coreServer}/library/reading/hw5`;
  }

  static get markAsReadSignificance() {
    return `${this.coreServer}/library/reading/significance`;
  }

  static get markAsReadSummary() {
    return `${this.coreServer}/library/reading/summary`;
  }

  static get markAsReadThreeFacts() {
    return `${this.coreServer}/library/reading/fact3`;
  }

  static get articleViewMarkAsRead() {
    return `${this.coreServer}/library/reading/get`;
  }

  static get articleViewWordDefinition() {
    return `${this.coreServer}/library/wordnik`;
  }

  static get createMemory() {
    return `${this.coreServer}/library/memory/create`;
  }

  static get editMemory() {
    return `${this.coreServer}/library/memory/edit`;
  }

  static get memoryUpdateMastered() {
    return `${this.coreServer}/library/memory/update-mastered`;
  }

  static get deleteMemory() {
    return `${this.coreServer}/library/memory/delete`;
  }

  static get saveArgument() {
    return `${this.coreServer}/core/argument/save`;
  }

  static get argumentShrinkedList() {
    return `${this.coreServer}/core/argument/list-claims`;
  }

  static get argumentDetails() {
    return `${this.coreServer}/core/argument/content`;
  }

  static get argumentDelete() {
    return `${this.coreServer}/core/argument/delete`;
  }

  static get coreTeamOptions() {
    return `${this.coreServer}/core/team/settings/get-options`;
  }

  static get saveToLibrary() {
    return `${this.coreServer}/news/save/library`;
  }

  static get saveToExtemp() {
    return `${this.coreServer}/core/feed/save/extemp`;
  }

  static get saveToCongress() {
    return `${this.coreServer}/core/feed/save/congress`;
  }

  static get createExtempFolders() {
    return `${this.coreServer}/extemp/folders/create`;
  }

  // smart search
  static get smartSearch() {
    return `${this.coreServer}/core/documents/search`;
  }

  // news feed
  static get newsFeed() {
    return `${this.coreServer}/news/feed/search`;
  }

  static get newsFeedArticlesDetails() {
    return `${this.coreServer}/news/feed/details`;
  }

  // offline routes
  static get fullSync() {
    return `${this.coreServer}/sync/first`;
  }

  static get partialSync() {
    return `${this.coreServer}/sync/partial`;
  }

  static get articlesSync() {
    return `${this.coreServer}/sync/articles`;
  }

  static get articleContentsSync() {
    return `${this.coreServer}/sync/contents`;
  }

  static get newsFeedSync() {
    return `${this.coreServer}/sync/news`;
  }

  static get speechesSync() {
    return `${this.coreServer}/sync/speech`;
  }

  static get argumentsSync() {
    return `${this.coreServer}/sync/argument`;
  }

  // library routes
  static get libraryArticleFeedArticlesIds() { // should be removed at some point
    return `${this.coreServer}/library/feed/ids`;
  }

  static get libraryArticleFeedArticlesDetails() {
    return `${this.coreServer}/library/feed/details`;
  }

  static get libraryArticleFeedArticleSummary() {
    return `${this.coreServer}/library/feed/summary`;
  }

  static get libraryTopicList() {
    return `${this.coreServer}/library/topic-list`;
  }

  static get listMemoriesIds() {
    return `${this.coreServer}/library/memory/ids`;
  }

  static get memoryDetails() {
    return `${this.coreServer}/library/memory/details`;
  }

  static get articleViewCreateWord() {
    return `${this.coreServer}/library/vocabulary/create`;
  }

  static get deleteVocabulary() {
    return `${this.coreServer}/library/vocabulary/delete`;
  }

  static get libraryVocabularyEntriesIds() {
    return `${this.coreServer}/library/vocabulary/ids`;
  }

  static get libraryVocabularyEntriesDetails() {
    return `${this.coreServer}/library/vocabulary/details`;
  }

  static get vocabularyUpdateMastered() {
    return `${this.coreServer}/library/vocabulary/update-mastered`;
  }

  static get vocabularyWordDefinition() {
    return `${this.coreServer}/library/vocabulary/word-definition`;
  }

  // extemp routes
  static get extempRoundStatistics() {
    return `${this.coreServer}/stats/round/upload`;
  }

  static get extempFoldersCategories() {
    return `${this.coreServer}/extemp/categories/list`;
  }

  static get extempFoldersFolders() {
    return `${this.coreServer}/extemp/folder-list/all`;
  }

  static get extempFoldersArticlesIds() {
    return `${this.coreServer}/core/feed/ids`;
  }

  static get extempFoldersArticlesDetails() {
    return `${this.coreServer}/library/feed/details`;
  }

  static get extempFoldersArticleSummary() {
    return `${this.coreServer}/library/feed/summary`;
  }

  static get extempFoldersChangeCategory() {
    return `${this.coreServer}/extemp/folders/change-category`;
  }

  static get extempCreateFoldersSubfolders() {
    return `${this.coreServer}/extemp/folders/multi-create`;
  }

  static get extempDeleteFoldersSubfolders() {
    return `${this.coreServer}/extemp/folders/delete`;
  }

  static get extempEditFoldersSubfolders() {
    return `${this.coreServer}/extemp/folders/edit`;
  }

  static get extempMergeFoldersSubfolders() {
    return `${this.coreServer}/extemp/folders/merge`;
  }

  static get extempCreateCategory() {
    return `${this.coreServer}/extemp/categories/create`;
  }

  static get extempEditCategory() {
    return `${this.coreServer}/extemp/categories/edit`;
  }

  static get extempDeleteCategory() {
    return `${this.coreServer}/extemp/categories/delete`;
  }

  static get extempDrawQuestions() {
    return `${this.coreServer}/extemp/questions/draw`;
  }

  static get extempPracticeQuestionsIds() {
    return `${this.coreServer}/extemp/questions/list/ids `;
  }

  static get extempPracticeQuestionsDetails() {
    return `${this.coreServer}/extemp/questions/list/details`;
  }

  static get extempCreatePracticeQuestion() {
    return `${this.coreServer}/extemp/questions/create`;
  }

  static get listPracticeTopics() {
    return `${this.coreServer}/extemp/question-topics/list`;
  }

  static get questionUsed() {
    return `${this.coreServer}/extemp/questions/selected`;
  }

  static get bulkActionArticles() {
    return `${this.coreServer}/extemp/articles/bulk/action`;
  }

  // congress routes
  static get congressRoundStatistics() {
    return `${this.coreServer}/stats/round/upload`;
  }

  static get congressDocketsArticlesDetails() {
    return `${this.coreServer}/library/feed/details`;
  }

  static get congressDocketsArticleSummary() {
    return `${this.coreServer}/library/feed/summary`;
  }

  static get congressDocketsDocketsIds() {
    return `${this.coreServer}/congress/docket/ids`;
  }

  static get congressDocketsDocketsSearchIds() {
    return `${this.coreServer}/congress/docket/search`;
  }

  static get congressDocketsDocketsDetails() {
    return `${this.coreServer}/congress/docket/details`;
  }

  static get congressDocketsDocketsBills() {
    return `${this.coreServer}/congress/docket/get`;
  }

  static get congressDocketsRequest() {
    return `${this.coreServer}/congress/docket/request`;
  }

  static get congressDocketBookmark() {
    return `${this.coreServer}/congress/docket/bookmark`;
  }

  static get congressDocketBookmarkRemove() {
    return `${this.coreServer}/congress/docket/remove-bookmark`;
  }

  static get congressDocketsDocket() {
    return `${this.coreServer}/congress/docket/get`;
  }

  static get congressDocketsBillArticleIds() {
    return `${this.coreServer}/congress/bill/articles`;
  }

  static get congressArticlesBill() {
    return `${this.coreServer}/congress/article/bills`;
  }

  static get congressSpeechSave() {
    return `${this.coreServer}/congress/speech/save`;
  }

  static get congressSpeechDelete() {
    return `${this.coreServer}/congress/speech/delete`;
  }

  static get congressSpeechIds() {
    return `${this.coreServer}/congress/speech/ids`;
  }

  static get congressSpeechDetails() {
    return `${this.coreServer}/congress/speech/details`;
  }

  static get congressSpeechContent() {
    return `${this.coreServer}/congress/speech/content`;
  }

  static get congressArticleBulkCopy() {
    return `${this.coreServer}/congress/document/bulk/copy`;
  }

  static get congressArticleBulkDelete() {
    return `${this.coreServer}/congress/document/bulk/delete`;
  }

  static get congressArticleBulkMove() {
    return `${this.coreServer}/congress/document/bulk/move`;
  }

  static get congressSpeechBulkCopy() {
    return `${this.coreServer}/congress/speech/bulk/copy`;
  }

  static get congressSpeechBulkDelete() {
    return `${this.coreServer}/congress/speech/bulk/delete`;
  }

  static get congressSpeechBulkMove() {
    return `${this.coreServer}/congress/speech/bulk/move`;
  }

  static get congressArgumentIds() {
    return `${this.coreServer}/congress/argument/ids`;
  }

  static get congressArgumentDetails() {
    return `${this.coreServer}/congress/argument/details`;
  }

  static get congressArgumentsSpeech() {
    return `${this.coreServer}/congress/argument/list-for-speech`;
  }

  // congress - temporary
  static get congressStates() {
    return `${this.coreServer}/congress/docket/states`;
  }

  static get congressDocketListIds() {
    return `${this.coreServer}/congress/docket/list/ids`;
  }

  static get congressDocketListDetails() {
    return `${this.coreServer}/congress/docket/list/details`;
  }

  static get congressBillList() {
    return `${this.coreServer}/congress/docket/bills`;
  }

  static get congressDocketTeamCheck() {
    return `${this.coreServer}/congress/docket/team/check`;
  }

  // signup
  static get baseSignupClient() {
    return this.signupClient;
  }
  
  static get signup() {
    return `${this.coreServer}/core/signup/create`;
  }

  static get signupEmailChange() {
    return `${this.coreServer}/core/signup/mail/change`;
  }

  static get signupEmailResend() {
    return `${this.coreServer}/core/signup/mail/resend`;
  }

  static get signupEmailVerify() {
    return `${this.coreServer}/core/signup/mail/verify`;
  }

  static get joinTeam() {
    return `${this.coreServer}/core/team/join`;
  }

  static get requestJoinTeam() {
    return `${this.coreServer}/core/request/join`;
  }

  static get searchTeamByContent() {
    return `${this.coreServer}/core/team/search`;
  }

  static get signupTeamByKey() {
    return `${this.coreServer}/core/signup/team/key/details`;
  }

  // billing
  static get extempAdvancedCategories() {
    return `${this.coreServer}/extemp/categories/set-advanced`;
  }

  static get discountList() {
    return `${this.coreServer}/core/discounts/list`;
  }

  static get billingTeamCreate() {
    return `${this.coreServer}/core/team/create`;
  }

  static get billingTeamRenew() {
    return `${this.coreServer}/core/team/renew`;
  }

  static get billingTeamUpgrade() {
    return `${this.coreServer}/core/team/upgrade`;
  }

  static get billingLastTransaction() {
    return `${this.coreServer}/billing/team/transaction/last`;
  }

  static get billingPaymentHistory() {
    return `${this.coreServer}/billing/team/transaction/history`;
  }

  static get billingPlanStatus() {
    return `${this.coreServer}/billing/team/plan/status`;
  }

  static get billingReferrals() {
    return `${this.coreServer}/billing/team/discounts/referrals`;
  }

  static get referralSend() {
    return `${this.coreServer}/core/billing/referral/send`;
  }

  static get welcomePackageRequest() {
    return `${this.coreServer}/core/team/welcome-package/request`;
  }

  static get welcomePackageRefuse() {
    return `${this.coreServer}/core/team/welcome-package/refuse`;
  }

  // static get billingWelcomePackage() {
  //   return `${this.coreServer}/billing/team/welcome-package/get`;
  // }

  static get billingWelcomePackage() {
    return `${this.coreServer}/core/school/get`;
  }

  //challenges

  static get createOrEditChallenge() {
    return `${this.coreServer}/challenge/team/go`;
  }
  
  static get cancelChallenge() {
    return `${this.coreServer}/challenge/team/cancel`;
  }
  
  static get listCurrentChallenges() {
    return `${this.coreServer}/challenge/team/list/current`;
  }

  static get listAllChallenges() {
    return `${this.coreServer}/challenge/team/list/all`;
  }

  static get challengeReport() {
    return `${this.coreServer}/challenge/team/list/report`;
  }
 
  static get challengeDetails() {
    return `${this.coreServer}/challenge/any/get/details`;
  }

  static get userChallengeIds(){
    return `${this.coreServer}/challenge/user/list/all/ids`;
  }

  static get userChallengeDetails(){
    return `${this.coreServer}/challenge/user/list/all/details`;
  }

  static get userTaskIds(){
    return `${this.coreServer}/challenge/task/list/ids`;
  }

  static get userTaskDetails(){
    return `${this.coreServer}/challenge/task/list/details`;
  }

  static get setTaskFailStatus(){
    return `${this.coreServer}/challenge/task/set/status/fail`;
  }

  static get userChallengesScore() {
    return `${this.coreServer}/challenge/user/current/score`;
  }

  static get userChallengesProgress() {
    return `${this.coreServer}/challenge/user/current/status `;
  }
}
