import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from "@angular/router";

import { Subscription } from "rxjs";
import { finalize, map } from 'rxjs/operators';

import { ISignupPrimaryData } from 'src/app/modules/signup/models/interfaces';

import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'pp-signup-continue',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './signup-continue.component.html',
  styleUrls: ['./signup-continue.component.scss']
})
export class SignupContinueComponent implements OnInit, OnDestroy {

  data: ISignupPrimaryData;
  for: string = 'extemp';
  isLoading: boolean = false;

  private _profileSubscription: Subscription;

  constructor(
    private _httpProvider: HttpService,
    private _router: Router,
  ) { }

  ngOnInit() {
    const profile = this._createProfile$();
    this._profileSubscription = this._createProfile$()
      .subscribe(
        (data: ISignupPrimaryData) => this._setPrimaryData(data),
        (error) => this._router.navigate(['/status'], { queryParams: {  type: '0', reason: '0', target: '1' } })
      );
  }

  ngOnDestroy() {
    if (this._profileSubscription)
      this._profileSubscription.unsubscribe();
  }

  private _createProfile$() {
    if (this._profileSubscription)
      this._profileSubscription.unsubscribe();

    this.isLoading = true;

    return this._httpProvider.getProfile()
      .pipe(
        finalize(() => this.isLoading = false),
        map(({result}) => {
          const teamIds: string[] = Object.keys(result.roles);
          const teamId: string = teamIds
            .find((id: string) => result.teams[id].type === 4);
          if (!teamId) throw new Error('Valid token not returned');
          const data: ISignupPrimaryData = {
            email: result.user.email,
            firstName: result.user.firstName,
            lastName: result.user.lastName,
            teamId: +teamId
          };
          return data;
        }),
      );
  }

  private _setPrimaryData(data: ISignupPrimaryData): void {
    this.data = data;
  }
}
