import {
  Component,
  ViewEncapsulation,
  Input,
  ElementRef,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'pp-notice',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
  @Input('noticeFor')
  for: string;

  @Input('noticeType')
  type: string;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2) { }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.type)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.type}`);
  }
}
