import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pp-card',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {}
