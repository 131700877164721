import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BillingService } from './services/billing.service';
import { StripeService } from './services/stripe.service';

import { BillingCongratsComponent } from './components/billing-congrats/billing-congrats.component';
import { BillingRenewComponent } from './components/billing-renew/billing-renew.component';
import { BillingExtempPreparationComponent } from './components/billing-extemp-preparation/billing-extemp-preparation.component';
import { BillingOneCreationComponent } from './components/billing-one-creation/billing-one-creation.component';
import { BillingOnePremiumComponent } from './components/billing-one-premium/billing-one-premium.component';
import { BillingOnePremiumCreationComponent } from './components/billing-one-premium-creation/billing-one-premium-creation.component';
import { BillingTeamCreationComponent } from './components/billing-team-creation/billing-team-creation.component';
import { BillingTeamFreeComponent } from './components/billing-team-free/billing-team-free.component';
import { BillingTeamJoinComponent } from './components/billing-team-join/billing-team-join.component';
import { BillingTeamPremiumComponent } from './components/billing-team-premium/billing-team-premium.component';
import { BillingUpgradeComponent } from './components/billing-upgrade/billing-upgrade.component';
import { BillingWelcomePackageRequestComponent } from './components/billing-welcome-package-request/billing-welcome-package-request.component';
import { BillingOnePremiumUpgradeComponent } from './components/billing-one-premium-upgrade/billing-one-premium-upgrade.component';
import { BillingTeamPremiumUpgradeComponent } from './components/billing-team-premium-upgrade/billing-team-premium-upgrade.component';

import { ButtonModule } from '../button/button.module';
import { CategoryModule } from '../category/category.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FormUiModule } from '../form-ui/form-ui.module';
import { InputModule } from '../input/input.module';
import { InputCheckboxModule } from '../input-checkbox/input-checkbox.module';
import { InputRadioModule } from '../input-radio/input-radio.module';
import { InputRangeModule } from '../input-range/input-range.module';
import { NoticeModule } from '../notice/notice.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TeamModule } from '../team/team.module';
import { UlModule } from '../ul/ul.module';

const components = [
  BillingCongratsComponent,
  BillingRenewComponent,
  BillingExtempPreparationComponent,
  BillingOneCreationComponent,
  BillingOnePremiumComponent,
  BillingOnePremiumCreationComponent,
  BillingOnePremiumUpgradeComponent,
  BillingTeamCreationComponent,
  BillingTeamFreeComponent,
  BillingTeamJoinComponent,
  BillingTeamPremiumComponent,
  BillingTeamPremiumUpgradeComponent,
  BillingUpgradeComponent,
  BillingWelcomePackageRequestComponent,
];

const modules = [
  FormsModule,
  ReactiveFormsModule,

  ButtonModule,
  CategoryModule,
  DropdownModule,
  FormUiModule,
  InputModule,
  InputCheckboxModule,
  InputRadioModule,
  InputRangeModule,
  NoticeModule,
  SpinnerModule,
  TeamModule,
  UlModule,
];

const services = [
  BillingService,
  StripeService,
];

@NgModule({
  imports: [
    CommonModule,

    ...modules,
  ],
  declarations: [
    ...components,
  ],
  providers: [
    ...services,
  ],
  exports: [
    ...components,
  ]
})
export class BillingModule { }
