import {
  Component,
  Directive,
  forwardRef,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const TEAM_LIST_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TeamListValueAccessor),
  multi: true
};

@Component({
  selector: 'team-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent { }

@Directive({
  selector: 'team-list[formControl],team-list[formControlName],team-list[ngModel]',
  providers: [TEAM_LIST_VALUE_ACCESSOR],
  host: { '(click)': 'onTouchedCb()' }
})
export class TeamListValueAccessor implements ControlValueAccessor {
  private _teamListValue: any;

  private _onChangeCb = (_: any) => {};
  onTouchedCb = () => {};

  writeValue(newTeamListValue: any): void {
    if (newTeamListValue !== this._teamListValue) {
      this._teamListValue = newTeamListValue;
      this._onChangeCb(newTeamListValue);
    }
  }

  registerOnChange(fn: (_: any) => any): void {
    this._onChangeCb = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouchedCb = fn;
  }
}
