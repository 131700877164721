import { IUserRole } from "../models/interfaces";

export enum EUserRole {
  Member = 1,       // MEMBER  (1L, "member");       // novice         0
  Coach = 2,        // COACH   (2L, "coach");        // coach          3
  Owner = 3,        // OWNER   (3L, "owner");        // owner          4
  Captain = 4,      // CAPTAIN (4L, "captain");      // captain        2
  Varsity = 5,      // VARSITY (5L, "varsity");      // varsity        1
  SecondCoach = 6,  // VICE    (6L, "second-coach"); // second coach   3
  NA = 1313         // UNDEFINED
}

export type TUserRole = 
  EUserRole.Member |
  EUserRole.Coach |
  EUserRole.Owner |
  EUserRole.Captain |
  EUserRole.Varsity |
  EUserRole.SecondCoach |
  EUserRole.NA;

export class UserRole implements IUserRole {
  readonly id: number;
  readonly hierarchyIndex: number;
  readonly label: string;
  
  constructor(id: number, label: string, index: number) {
    this.id = id;
    this.label = label;
    this.hierarchyIndex = index;
  }
}

export class UserRolesFactory {
  private _hierarchyIndex = {};

  constructor() {
    this._hierarchyIndex[EUserRole.Owner] = 4;
    this._hierarchyIndex[EUserRole.Coach] = 3;
    this._hierarchyIndex[EUserRole.Captain] = 2;
    this._hierarchyIndex[EUserRole.Varsity] = 1;
    this._hierarchyIndex[EUserRole.Member] = 0;

    this._hierarchyIndex[EUserRole.SecondCoach] = 0;
    this._hierarchyIndex[EUserRole.NA] = 0;
  }  
  
  getIndex(role: EUserRole): any {
    return this._hierarchyIndex[role] || 0;
  }
  inaccesibleIndex(){
    return 999;
  }

  public createRole(id: number): IUserRole {
    switch(id) {
      case EUserRole.Member:
        return new UserRole(EUserRole.Member, 'Novice', 0);
      case EUserRole.Coach:
        return new UserRole(EUserRole.Coach, 'Coach', 3);
      case EUserRole.Owner:
        return new UserRole(EUserRole.Owner, 'Head Coach', 4);
      case EUserRole.Captain:
        return new UserRole(EUserRole.Captain, 'Captain', 2);
      case EUserRole.Varsity:
        return new UserRole(EUserRole.Varsity, 'Varsity', 1);
      case EUserRole.SecondCoach:
        return new UserRole(EUserRole.SecondCoach, 'Vice Captain', 2);
      default:
        return new UserRole(EUserRole.NA, 'UNDEFINED', -1);
    }
  }

  public getRoleObject(): { [key: string]: IUserRole } {
    return {
      [EUserRole.Captain]: this.createRole(EUserRole.Captain),
      [EUserRole.Coach]: this.createRole(EUserRole.Coach),
      [EUserRole.Member]: this.createRole(EUserRole.Member),
      [EUserRole.Owner]: this.createRole(EUserRole.Owner),
      [EUserRole.Varsity]: this.createRole(EUserRole.Varsity),
      [EUserRole.SecondCoach]: this.createRole(EUserRole.SecondCoach),
      [EUserRole.NA]: this.createRole(EUserRole.NA)
    };
  }

  public getRoleArray(): IUserRole[] {
    let roles: any[] = [EUserRole.Captain, EUserRole.Coach, EUserRole.Member, EUserRole.Owner, EUserRole.Varsity, EUserRole.SecondCoach, EUserRole.NA];
    
    return roles.map((value) => this.createRole(value));
  }
}