import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pp-notifications-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {}
