import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  private _http: { [key: string]: string } = {
    'accBlocked': 'The account is blocked',
    'teamBlocked': 'Your team is currently blocked. Please contact hello@prepd.in for details',
    'mailConf': 'The email is not confirmed',
    'duplicateAccount': 'There are two or more accounts with this email',
    // 'invalidEmail': 'This email address does not exist. Please check the spelling and try again',
    'noAuth': 'Missing authorization header',
    'badAction': 'Error - Incorrect action! Please refresh the app and try again',
    'badAuth': 'Bad authorization header',
    'badCreds': 'The email or password is incorrect',
    'badData': 'Invalid data',
    'missingData': 'Missing data',
    'teamNotFound': 'Oops! Prepd couldn’t find your team! Please try again or contact hello@prepd.in for support',
    'alreadyCaught': 'Someone on your team has already caught this article',
    'noMembership': 'Oops! You are no longer part of this team. Please refresh the app, contact your coach or email hello@prepd.in for support',
    'notAllowed': 'Oops! You are not allowed to access this feature!',
    'noTeamConstraint': 'Error - Incorrect team constraint! Please refresh the app and try again',
    'noAppConstraint': 'Error - Incorrect app constraint! Please refresh the app and try again',
    'roleNotAllowed': 'Oops! Your user role is not allowing you to perform this action',
    'personNotFound': 'Oops! We were unable to find this user. Please check the information and try again',
    'memberLimitReached': 'Oops! Team\'s user limit has been reached. Remove current users, increase the team\'s limit from the Classroom or contact hello@prepd.in for details',
    'invalidEmail': 'Oops! The email format is invalid. Please use a real email address',

    'exerciseDone': 'This article has already been read.',

    // categories
    'categoryExists': 'A category with this name already exists!',
    'categoryLimitReached': 'Categories reached its limit!',

    'nullResult': 'No Data! Please contact us to fix it!',

    // create folders and subfolders
    'parentNotFound': 'Please select a parent and then create the subfolder!',

    // edit folder
    'noRootFolderParentChange': 'The edited subfolder is actually a root folder. Cannot complete edit!',
    'noSuchParent': 'The selected parent wasn\'t found!',

    // change folder's categories
    'catNotFound': 'The edited subfolder is actually a root folder. Cannot complete edit!',

    // folders - merge
    'badFolderList': 'We could not find the specified folders!Please contact us!',
    'badTargetFolder': 'The target folder to merge into is not found or doesn\'t belong to the team',
    'folderExists': 'The new folder name to merge into already exists!',
    'mismatchExistingSubfolder': 'Please, specify a parent, an existing subfolder and the parent value of the subfolder',
    'nothingToMerge': 'No folder was selected to be merged!',
    'parentPresentInMergeList': 'The selected parent folder is present in the list of folders to be merged!',
    'teamMismatch': 'One or more folders do not belong to the current team!',
    'tooManyFoldersToMerge': 'Please select no more than 10 folders to be merged!',

    'alreadyBookmarked': 'This docket is already in your team.',
    'docketLimitReached': 'Your docket limit has been reached. You can upgrade to a Premium Team from the Classroom app or contact hello@prepd.in for support.',

    'speech_argument_tooMany': 'The argument must have 5 arguments or fewer!',
    'speech_billNotFound': 'The bill your are trying to save this speech to cannot be found. Please contact hello@pepd.in for support.',
    'speech_contentTooLong': 'Speech content is too long',
    'speech_limitTouched': 'You have reached the speech limit for this team. Please delete older speeches or contact hello@pepd.in for support.',
    'speech_speechNotFound': 'The speech cannot be found. Please refresh the app or contact hello@pepd.in for support.',
    'speech_speechTrashed': 'Oops! The speech has already been deleted.',
    'speech_titleTooLong': 'Speech title is too long.',
    'speech_unauthorized': 'You are not authorized to modify this speech.',
    'speech_wrongDocket': 'The bill your are trying to save this speech to is part of a docket which is no longer part of your team\'s collection. Please bookmark the docket again, or contact hello@pepd.in for support.',

    'argument_argumentNotFound': 'The selected argument cannot be found!',
    'argument_argumentTrashed': 'The selected argument has been deleted!',
    'argument_billNotFound': 'This bill no longer existis or is in a docket not saved to your collection.',
    'argument_claimTooLong': 'Oops! The claim must be under 1,000 characters!',
    'argument_impactTooLong': 'Oops! The impact must be under 1,000 characters!',
    'argument_limitTouched': 'Oops! The arguments limit has been reached!',
    'argument_speechNotFound': 'This speech no longer exists',
    'argument_unauthorized': 'Oops! Only the argument\'s creator or coaches can edit it, change its privacy or delete it!',
    'argument_warrant_tooMany': 'The argument must have 5 warrants or fewer!',
    'argument_zeroWarrants': 'The argument must have at least one warrant!',
    'memory_publicDocument': 'Oops! Couldn\'t create Memory Bank entry because this is a public document. Please reload the article.',
    'publicDocument': 'Oops! This is a public document. Please reload the article or save it to your collection, and try again.',
    'vocabulary_WordsApiError': 'Prepd\'s dictionary service in currently unavailable. Please try again later.',

    'alreadyChallenged': 'Part of the selected students already had an ongoing challenge. The have been excluded from this one',
    'allChallenged' : 'The selected students are already part of another challenge during this time-frame',
    'notFound': 'The challenge you edited no longer exists',
    'cancelledChallenge': 'The challenge you are editing has been canceled. Please use the \'Copy Challenge\' option',
    'internalError': 'Unknown Error - Information: internal error',
    'badMembers': 'Some of selected users are not in the team anymore',
    'uniqueTasks': 'The selected tasks must be unique. Please review the challenge details and try again',
    'challengeStartsInPast': 'The challenge start date is in the past, please change the date.',
    'challengeEndsInPast': 'The challenge end date is in the past, please change the date.',
    'endsBeforeStarts': 'The challenge cannot end before the start date. Please select a later date',
    'challengeActivityFails': 'Cannot change the tast status. Please refresh the page and try again',
    'badInfo': 'Opps! Error: Incorrect information! Please try again or contact us for a resolution',
    'noUsers': 'Cannot retrieve students for the challenge. Please refresh the page and try again',
    'noEntry': 'Cannot retrieve challenge ID number. Please refresh the page and try again',
    'noUpdate': 'Warning: Task could not be updated. Please refresh the page and try again',
    'noSuchTask': ' Cannot retrieve task details. Please refresh the page and try again',
    'noRenewPendingCheck': 'You cannot pay with a check (or use the district option) while you have a pending check payment. Please choose card, or contact hello@prepd.in for details.',
  };

  private _form: { [key: string]: { [key: string]: string } } = {
    'email': {
      'required': 'Email is required.'
    },
    'password': {
      'required': 'Password is required.'
    }
  };

  getHttpError(error: string) {
    return this._http[error] || error;
  }

  getFormError(type: string, error: string) {
    return this._form[type][error] || null;
  }

  // static specialCharacter({value}) {
  //   if (!value.match(/[a-zA-Z0-9\.]/))
  //     return {
  //       specialCharacter: true
  //     };
  //   return null;
  // }

  static equals({ value }) {
    const [first, ...rest] = Object.keys(value || {});
    const valid = rest.every(v => value[v] === value[first]);
    if (!valid)
      return {
        equals: true
      };
    return null;
  }

  static notIn(elems: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } => {
      const value: string = control.value || '';
      const valid = elems.indexOf(value) > -1;
      if (valid) {
        return {
          notIn: true
        }
      }
      return null;
    };
  }
}
