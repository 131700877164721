import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SignupAccountCreationComponent } from './signup-account-creation/signup-account-creation.component';
import { SignupAccountDetailsComponent } from './signup-account-details/signup-account-details.component';

import { BillingModule } from '../billing/billing.module';
import { ButtonModule } from '../button/button.module';
import { FormUiModule } from '../form-ui/form-ui.module';
import { InputModule } from '../input/input.module';
import { SpinnerModule } from '../spinner/spinner.module';

const components = [
  SignupAccountCreationComponent,
  SignupAccountDetailsComponent
];

const modules = [
  FormsModule,
  ReactiveFormsModule,

  BillingModule,
  ButtonModule,
  FormUiModule,
  InputModule,
  SpinnerModule,
];

@NgModule({
  imports: [
    CommonModule,

    ...modules,
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class SignupModule { }
