import {
  Component,
  Directive,
  HostBinding,
  OnDestroy,
  OnInit,
  Provider,
  ViewEncapsulation,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownValueAccessor),
  multi: true
};

import { DropdownService } from "../../../services/dropdown.service";

@Component({
  selector: 'pp-dropdown',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnDestroy {
  @HostBinding('class.open')
  open = false;

  constructor(private _dropdownPDropdownProvider: DropdownService) {}

  ngOnInit() {
    this._dropdownPDropdownProvider.addDropdown(this);
  }

  ngOnDestroy() {
    this._dropdownPDropdownProvider.removeDropdown(this);
  }

  toggleDropdown() {
    const wasOpen = this.open;

    this._dropdownPDropdownProvider.closeDropdowns();

    this.open = (wasOpen) ? false : true;
  }

  openDropdown() {
    this.open = true;
  }

  closeDropdown() {
    this.open = false;
  }
}

@Directive({
  selector: 'pp-dropdown[formControl],pp-dropdown[formControlName],pp-dropdown[ngModel]',
  providers: [DROPDOWN_VALUE_ACCESSOR]
})
export class DropdownValueAccessor implements ControlValueAccessor {
  private _dropdownValue: any;

  private _onChangeCb = (_: any) => {};
  private _onTouchedCb = () => {};

  writeValue(newDropdownValue: any): void {
    if (newDropdownValue !== this._dropdownValue) {
      this._dropdownValue = newDropdownValue;
      this._onChangeCb(newDropdownValue);
    }
  }

  registerOnChange(fn: (_: any) => any): void {
    this._onChangeCb = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouchedCb = fn;
  }
}