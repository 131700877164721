import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ul[pp-ul]',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ul.component.html',
  styleUrls: ['./ul.component.scss']
})
export class UlComponent implements OnInit {
  @Input() for: string;
  @Input() size: string;   // -> unsuported yet

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) { }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.size)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.size}`);
  }
}
