import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { Subscription } from 'rxjs';

import { HttpService } from '../../../../services/http.service';
import { UtilService } from '../../../../services/util.service';

import { ETeamType, TTeamType } from '../../../../models/team-type';
import {
  IBillingPaymentData,
  IBillingPaymentMethod
} from '../../models/interfaces';
import { IState } from '../../../../models/interfaces';
import { EBillingPaymentType, BillingPaymentTypeFactory } from '../../models/payment';
import { map, finalize } from 'rxjs/operators';

@Component({
  selector: 'billing-upgrade',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-upgrade.component.html',
  styleUrls: ['./billing-upgrade.component.scss']
})
export class BillingUpgradeComponent implements OnDestroy, OnInit {
  @Input()
  for = 'extemp';

  @Input()
  teamId: number;

  @Input()
  currentTeamType: TTeamType | undefined;

  @Output()
  onFinish = new EventEmitter();

  isLoading: boolean = false;
  membersLimit: number;
  proApps: number;
  lastPayment: IBillingPaymentData;

  private _lastTransactionSubscription: Subscription;

  readonly TEAM_TYPE;

  constructor(
    private _httpProvider: HttpService,
    private _utilProvider: UtilService,
  ) {
    this.TEAM_TYPE = ETeamType;
  }

  ngOnDestroy() {
    if (this._lastTransactionSubscription)
      this._lastTransactionSubscription.unsubscribe();
  }

  ngOnInit() {
    const lastTransaction$ = this._createBillingRenewDetails$({teamId: this.teamId});
    this._lastTransactionSubscription = lastTransaction$.subscribe((payload) => this._setPaymentData(payload));
  }

  finish() {
    this.onFinish.emit();
  }

  private _createBillingRenewDetails$(dataToSend) {
    this.isLoading = true;

    return this._httpProvider.postBillingLastTransaction(dataToSend)
      .pipe(
        map((response: any) => response.result),
        finalize(() => this.isLoading = false),
      );
  }

  private _setPaymentData(payload) {
    const payment: IBillingPaymentMethod = new BillingPaymentTypeFactory().getPaymentObjectByValue()[payload.method];
    this.membersLimit = payload.membersLimit;
    this.proApps = payload.proApps;

    switch (payment.id) {
      case EBillingPaymentType.Card:
        let paymentDetails = {
          payment,
          address: payload.details.address,
          city: payload.details.city,
          firstName: payload.details.firstName,
          lastName: payload.details.lastName,
          state: this._utilProvider.toArray( this._utilProvider.getStates() ).find((state: IState) => state.abbreviation === payload.details.state),
          zip: payload.details.zip
        };
      case EBillingPaymentType.CheckDT:
      case EBillingPaymentType.CheckIn:
      case EBillingPaymentType.CheckPO:
      default:
        // upgrade Premium to Premium works only with card (the default one)
        this.lastPayment = {
          app: payload.proApps,
          cost: payload.amount,
          endDate: payload.endDate,
          membersLimit: payload.membersLimit,
          subscriptionLength: payload.covers,
          paid: payload.paid,
          payment: {}
        };
        if (paymentDetails)
          this.lastPayment.payment = paymentDetails;
        break;
    }
  }
}
