import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  ViewEncapsulation
} from '@angular/core';
import {
  Router,
  NavigationStart
} from "@angular/router";

import { Subscription } from "rxjs";

import { DropdownService } from './services/dropdown.service';

@Component({
  selector: 'signup-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _isChromeSubscription: Subscription;

  constructor(
    private _dropdownProvider: DropdownService,
    private _router: Router,
  ) {}

  @HostListener('click')
  onClick() {
    this._dropdownProvider.closeDropdowns();
  }

  ngOnInit() {
    // this._checkBrowser();

    this._isChromeSubscription = this._router.events
      .subscribe(
        (event: any) => {
          const url: string = event.url;
          const regexObj = /^\/status\?type=1&reason=800/;
          if(event instanceof NavigationStart && !regexObj.test(url)) {
            if (!this._isChromeBrowser() && event) {
              this._router.navigate(['/status'], { queryParams: { type: '1', reason: '800' } });
            }
          }
        },
        () => {}
      );
  }

  ngOnDestroy() {
    if (this._isChromeSubscription)
      this._isChromeSubscription.unsubscribe();
}

  // private _checkBrowser() {
  //   if (!this._isChromeBrowser())
  //     this._router.navigate(['/status'], { queryParams: { type: '1', reason: '800' } });
  // }

  private _isChromeBrowser(): boolean {
    return /chrome|crios/i.test(window.navigator.userAgent) && !/edge|opera|opr/i.test(window.navigator.userAgent);
  }
}
