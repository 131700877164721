import { IBillingDiscount } from './interfaces';

export enum EPlan {
  OneFreeCreation,
  OnePremiumCreation,
  TeamFreeCreation,
  TeamPremiumCreation,
  Renew,
  Upgrade,
}

export type TPlan =
  EPlan.OneFreeCreation |
  EPlan.OnePremiumCreation |
  EPlan.TeamFreeCreation |
  EPlan.TeamPremiumCreation |
  EPlan.Renew |
  EPlan.Upgrade;

export class Pricing {
  public appsNo: number;
  public articlesNo: number;
  public lastPayment;
  public membersLimit: number;
  public plan: TPlan;
  public price: number;
  public priceDiscounted: number;
  public discountValue: number;
  public discounts: IBillingDiscount[];
  public discountsSelectable: IBillingDiscount[];
  public selectedDiscount: IBillingDiscount;
  public subscriptionLength: number;

  private _unlistedDiscounts: {[key: string]: IBillingDiscount[]};
  private _listedDiscounts: {[key: string]: IBillingDiscount[]};

  constructor(plan: TPlan, membersLimit, subscriptionLength: number, appsNo?: number, discounts?: IBillingDiscount[], lastPayment?) {
    this._setPlan(plan);
    this._setAppsNo(appsNo);
    this._setSubscriptionLength(subscriptionLength);
    this._parseDiscounts(plan, discounts);
    this._setMembersLimit(membersLimit);
    this._setLastPayment(lastPayment);

    this._setArticleNo(this.plan);
    this._setPrice();
  }

  setAppsNo(appsNo: number) {
    this._setAppsNo(appsNo);
    this._setPrice();
  }

  setDiscount(discount: IBillingDiscount) {
    this._setSelectedDiscount(discount);
    this._setPrice();
  }

  setDiscounts(discounts: IBillingDiscount[]) {
    this._parseDiscounts(this.plan, discounts);
    this._setPrice();
  }

  setLastPayment(lastPayment) {
    this._setLastPayment(lastPayment);
    this._setPrice();
  }

  setPlan(plan: TPlan) {
    this._setPlan(plan);
    this._setArticleNo(plan);
    this._setDiscountsListed(plan);
    this._setPrice();
  }

  setSubscriptionLength(length: number) {
    this._setSubscriptionLength(length);
    this._setPrice();
  }

  setUsersNo(usersNo: number) {
    this._setMembersLimit(usersNo);
    this._setPrice();
  }

  private _calcPriceCreate() {
    let price = Pricing.getRawPrices(this.appsNo, this.plan)[this.membersLimit];
    let priceDiscounted = this._getPriceDiscounted(price, this._getDiscountsUnlisted(this.plan), this.selectedDiscount);

    price = Math.round( price * this.subscriptionLength / 12 );
    priceDiscounted = Math.round( priceDiscounted * this.subscriptionLength / 12 );
    let discountValue = price - priceDiscounted;

    return { price, priceDiscounted, discountValue };
  }

  private _calcPriceRenew() {
    let price = Pricing.getRawPrices(this.appsNo, this.plan)[this.membersLimit];
    price = Math.round( price * this.subscriptionLength / 12 );

    if (!this.lastPayment.paid)
      return { price: price, priceDiscounted: price, discountValue: 0 };

    const oldApp = Math.ceil(this.lastPayment.app / 2);
    const oldPrice = Pricing.getRawPrices(oldApp, this.plan)[this.lastPayment.membersLimit];
    const monthsDifference: number = this._getMonthsDifference(this.lastPayment.endDate, this.plan);
    // const oldSubscriptionLength: number = this._computeTimeDiff(this.lastPayment.endDate, this.lastPayment.created).totalMonths;

    let priceDiscounted;
    if (monthsDifference > 0) {
      const proRated = (oldPrice * monthsDifference) / this.lastPayment.subscriptionLength;
      price = Math.round(price - proRated);

      priceDiscounted = this._getPriceDiscounted(price, this._getDiscountsUnlisted(this.plan), this.selectedDiscount);
    }
    else 
      priceDiscounted = this._getPriceDiscounted(price, this._getDiscountsUnlisted(this.plan), this.selectedDiscount);

    priceDiscounted = Math.round( priceDiscounted * this.subscriptionLength / 12 );
    let discountValue = price - priceDiscounted;

    return { price, priceDiscounted, discountValue };
  }

  private _calcPriceUpgrade() {
    let price = Pricing.getRawPrices(this.appsNo, this.plan)[this.membersLimit];
    price = Math.round( price * this.subscriptionLength / 12);

    if (!this.lastPayment.paid)
      return { price: price, priceDiscounted: price, discountValue: 0 };

    const oldApp = Math.ceil(this.lastPayment.app / 2);
    const oldPrice = Pricing.getRawPrices(oldApp, this.plan)[this.lastPayment.membersLimit];
    const monthsDifference: number = this._getMonthsDifference(this.lastPayment.endDate, this.plan);

    if (monthsDifference > 0) {
      let costDifference = price - oldPrice;
      costDifference = (costDifference < 0) ? 0 : costDifference;
      price = Math.round((costDifference * monthsDifference) / this.lastPayment.subscriptionLength);
    }

    return { price, priceDiscounted: price, discountValue: 0 };
  }

  private _getMonthsDifference(stamp: number, plan: TPlan) {
    // if the plan is unlimited
    if (stamp === 0) {
      return 0;
    } else {
      var timeDiff = this._computeTimeDiff(Date.now(), stamp);
      var monthsDiff = timeDiff.totalMonths;

      if (plan === EPlan.Upgrade && timeDiff.days > 1) {
        monthsDiff += 1;
      }

      monthsDiff = (monthsDiff >= 12) ? 12 : monthsDiff;

      return monthsDiff;
    }
  }

  private _computeTimeDiff(fromDate: number, toDate: number) {
    let startDate = this._makeDate(fromDate);
    let endDate = this._makeDate(toDate + (24 * 60 * 60000));
    let dateDiff = {
        years: 0,
        months: 0,
        days: 0,
        totalMonths: 0
    };

    if (endDate < startDate) {
      return dateDiff;
    }

    while (startDate.getTime() < endDate.getTime()) {
        startDate.setFullYear(startDate.getFullYear() + 1);
        dateDiff.years += 1;
    }
    startDate.setFullYear(startDate.getFullYear() - 1);
    dateDiff.years -= 1;

    while (startDate.getTime() < endDate.getTime()) {
        startDate.setMonth(startDate.getMonth() + 1);
        dateDiff.months += 1;
    }
    startDate.setMonth(startDate.getMonth() - 1);
    dateDiff.months -= 1;

    while (startDate.getTime() < endDate.getTime()) {
        startDate.setDate(startDate.getDate() + 1);
        dateDiff.days += 1;
    }
    startDate.setDate(startDate.getDate() - 1);
    dateDiff.days -= 1;

    dateDiff.totalMonths = (dateDiff.years * 12) + dateDiff.months;

    return dateDiff;
  }

  private _makeDate(stamp: number): Date {
    return stamp ? new Date(stamp) : new Date();
  }

  private _getArticlesNo(plan: TPlan): number {
    const articlesNo = {
      // // temporary: until 1 Sept 2018
      // [EPlan.OneFreeCreation]: 250000,
      // [EPlan.TeamFreeCreation]: 250000,

      [EPlan.OneFreeCreation]: 50,
      [EPlan.TeamFreeCreation]: 100,
      [EPlan.OnePremiumCreation]: 250000,
      [EPlan.TeamPremiumCreation]: 250000
    };
    return articlesNo[plan];
  }

  private _getDiscountDefault(): IBillingDiscount {
    return this.discountsSelectable[0];
  }

  private _getDiscountsListed(plan: TPlan): IBillingDiscount[] {
    return this._listedDiscounts[plan] || [];
  }

  private _getDiscountsUnlisted(plan: TPlan): IBillingDiscount[] {
    return this._unlistedDiscounts[plan] || [];
  }

  private _getDiscountVirtual(): IBillingDiscount {
    return {
      id:-13,
      name:"No Discount",
      value:0,
      type:-13,
      valueType:'percent'
    };
  }

  static getRawUsers(plan: TPlan) {
    if (plan !== EPlan.OneFreeCreation && plan !== EPlan.OnePremiumCreation)
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 120, 140, 160, 180, 200];
    else
      return [1];
  }

  static getRawPrices(appNo: number, plan: TPlan): {[key: number]: number} {
    const usersNo: number[] = Pricing.getRawUsers(plan);
    let prices: number[];
    switch(appNo) {
      case 1:
        prices = [25, 45, 60, 74, 87, 99, 110, 120, 129, 137, 144, 150, 156, 162, 168, 174, 180, 186, 192, 198, 204, 210, 216, 222, 228, 234, 240, 246, 252, 258, 264, 270, 276, 282, 288, 294, 300, 306, 312, 318, 324, 330, 336, 342, 348, 354, 360, 366, 372, 380, 410, 440, 470, 500, 530, 560, 590, 620, 650, 680, 750, 810, 860, 900, 950];
        break;
      case 2:
        prices = [35, 65, 90, 110, 129, 147, 164, 180, 195, 209, 222, 234, 245, 255, 264, 272, 279, 286, 293, 300, 307, 314, 321, 328, 335, 342, 349, 356, 363, 370, 377, 384, 391, 398, 405, 412, 419, 426, 433, 440, 447, 454, 461, 468, 475, 482, 489, 496, 503, 510, 545, 580, 615, 650, 685, 720, 755, 790, 825, 860, 940, 1010, 1070, 1220, 1260];
        break;
    }

    return usersNo.reduce((value, userNo: number, index: number) => {
      value[userNo] = prices[index];
      return value;
    }, {});
  }

  private _parseDiscounts(plan: TPlan, discounts: IBillingDiscount[] = []): void {
    const discountVirtual: IBillingDiscount = this._getDiscountVirtual();

    this._unlistedDiscounts = {};
    this._unlistedDiscounts[EPlan.OnePremiumCreation] = [];
    this._unlistedDiscounts[EPlan.TeamPremiumCreation] = [];
    this._unlistedDiscounts[EPlan.Renew] = [];
    this._listedDiscounts = {};
    this._listedDiscounts[EPlan.OnePremiumCreation] = [discountVirtual];
    this._listedDiscounts[EPlan.TeamPremiumCreation] = [discountVirtual];
    this._listedDiscounts[EPlan.Renew] = [discountVirtual];

    if (discounts.length) {
      const dupes = {};
      discounts.forEach((discount: IBillingDiscount) => {
        if (discount.type === 0) {
          if (discount.actionType === 0)
            this._unlistedDiscounts[EPlan.TeamPremiumCreation].push(discount);
          else if (discount.actionType === 1)
            this._unlistedDiscounts[EPlan.OnePremiumCreation].push(discount);
          else if (discount.actionType === 2)
            this._unlistedDiscounts[EPlan.Renew].push(discount);
        } else {
          if (discount.actionType === 0)
            this._listedDiscounts[EPlan.TeamPremiumCreation].push(discount);
          else if (discount.actionType === 1)
            this._listedDiscounts[EPlan.OnePremiumCreation].push(discount);
          else if (!dupes[discount.id] && (discount.actionType === 2 || discount.actionType === 3)){
            this._listedDiscounts[EPlan.Renew].push(discount);
            dupes[discount.id] = true;
          }
        }
      });
    }

    this._setDiscountsListed(plan);
  }

  private _setAppsNo(appsNo: number = 1): void{
    this.appsNo = appsNo;
  }

  private _setArticleNo(plan: TPlan) {
    this.articlesNo = this._getArticlesNo(plan);
  }

  private _setDiscounts(unlistedDiscounts: IBillingDiscount[], selectedDiscount: IBillingDiscount) {
    this.discounts = Array.from(unlistedDiscounts);
    const discountVirtual = this._getDiscountVirtual();
  
    if (selectedDiscount && selectedDiscount.id !== discountVirtual.id)
      this.discounts.unshift(selectedDiscount);
  }

  private _setDiscountsListed(plan: TPlan) {
    const discountVirtual: IBillingDiscount = this._getDiscountVirtual();
    let discounts: IBillingDiscount[] = this._getDiscountsListed(plan)
      .filter((discount: IBillingDiscount) => discount.id != discountVirtual.id)
      .sort((discountA: IBillingDiscount, discountB: IBillingDiscount) => {
        const valueA = discountA.value;
        const valueB = discountB.value;

        if (valueA < valueB) return 1;
        else if (valueA > valueB) return -1;
        else return 0;
      });

    const groups: {[key: string]: IBillingDiscount} = discounts.reduce((groups, discount: IBillingDiscount) => {
      const val = discount.valueType;
      groups[val] = groups[val] || [];
      groups[val].push(discount);
      return groups;
    }, {});

    discounts = Object.keys(groups).sort((typeA: string, typeB: string) => {
      if (typeA < typeB) return -1;
      else if (typeA > typeB) return 1;
      else return 0;
    })
    .reduce((list, type: string) => list.concat(groups[type]), []);

    discounts.unshift(discountVirtual);
    this.discountsSelectable = discounts;
    this._setSelectedDiscount(this._getDiscountDefault());
  }

  private _setSelectedDiscount(discount: IBillingDiscount) {
    this.selectedDiscount = discount;
    this._setDiscounts(this._getDiscountsUnlisted(this.plan), this.selectedDiscount);
  }

  private _setPlan(plan: TPlan): void {
    this.plan = plan;
  }

  private _setSubscriptionLength(length: number = 12): void {
    this.subscriptionLength = length;
  }

  private _setMembersLimit(usersNo: number = 1): void {
    this.membersLimit = usersNo;
  }

  private _setLastPayment(lastPayment = {}) {
    this.lastPayment = lastPayment
  }

  private _setPrice() {
    let values;
    switch(this.plan) {
      case EPlan.OnePremiumCreation:
      case EPlan.TeamPremiumCreation:
        values = this._calcPriceCreate();
        break;

      case EPlan.Renew:
        values = this._calcPriceRenew();
        break;

      case EPlan.Upgrade:
        values = this._calcPriceUpgrade();
        break;

      case EPlan.OneFreeCreation:
      case EPlan.TeamFreeCreation:
      default:
        values = { price: 0, priceDiscounted: 0, discountValue: 0 };
        break;
    }
    this.price = values.price;
    this.priceDiscounted = values.priceDiscounted;
    this.discountValue = values.discountValue;
  }

  private _getPriceDiscounted(price: number, unlistedDiscounts: IBillingDiscount[], selectedDiscount: IBillingDiscount): number {
    let priceDiscounted: number = 0;
    const discountVirtual: IBillingDiscount = this._getDiscountVirtual();

    if (price <= 0) return priceDiscounted;

    let totalDiscountsValues = unlistedDiscounts.reduce((value, discount: IBillingDiscount) => {
      if (discount.valueType === 'flat')
        value.flat = discount.value;
      else if (discount.valueType === 'percent')
        value.percent = discount.value;
      return value;
    }, {flat: 0, percent: 0});

    if (selectedDiscount && selectedDiscount.id !== discountVirtual.id) {
      if (selectedDiscount.valueType === 'flat')
        totalDiscountsValues.flat = selectedDiscount.value;
      else if (selectedDiscount.valueType === 'percent')
        totalDiscountsValues.percent = selectedDiscount.value;
    }

    priceDiscounted = price - totalDiscountsValues.flat;
    priceDiscounted = priceDiscounted > 0 ? priceDiscounted : 0;

    priceDiscounted -= (priceDiscounted * totalDiscountsValues.percent) / 100;
    priceDiscounted = Math.ceil(priceDiscounted);

    return priceDiscounted;
  }
}