import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  get(key: string) {
    if (localStorage)
      return JSON.parse(localStorage.getItem(key));
    return null;
  }

  set(key: string, data: any) {
    if (localStorage)
      localStorage.setItem(key, JSON.stringify(data));
  }

  clear() {
    if (localStorage)
      localStorage.clear();
  }
}
