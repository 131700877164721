import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownComponent, DropdownValueAccessor } from './dropdown/dropdown.component';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { DropdownMenuWithHeaderComponent } from './dropdown-menu-with-header/dropdown-menu-with-header.component';

import { DropdownToggleDirective } from './dropdown-toggle.directive';

const components = [
  DropdownComponent,
  DropdownItemComponent,
  DropdownMenuComponent,
  DropdownValueAccessor,
  DropdownMenuWithHeaderComponent,
];

const directives = [
  DropdownToggleDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  exports: [
    ...components,
    ...directives,
  ]
})
export class DropdownModule { }
