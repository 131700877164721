import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { EBillingAction, TBillingAction } from '../../models/billing-action';
import { EBillingAppType } from '../../models/app';

@Component({
  selector: 'billing-one-premium-creation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-one-premium-creation.component.html',
  styleUrls: ['./billing-one-premium-creation.component.scss']
})
export class BillingOnePremiumCreationComponent {
  @Input()
  for: string;

  @Input()
  billingAction: TBillingAction | undefined;

  @Input()
  teamId: number;

  @Output()
  onFinish = new EventEmitter();

  currentStep: string;
  newTeamId: number;
  steps: {[key: string]: string};

  constructor() {
    this._initSteps();
  }

  finish() {
    this.onFinish.emit();
  }

  setCurrentStep(step: string) {
    this.currentStep = step;
  }

  goToExtempPreparation({app, newTeamId}) {
    this.newTeamId = newTeamId;

    if (this.billingAction !== EBillingAction.Create) {
      this.finish();
      return;
    }

    switch(app) {
      case EBillingAppType.Extemp:
      case EBillingAppType.ExtempCongress:
        this.setCurrentStep(this.steps.extempPreparation);
        break;
      case EBillingAppType.Congress:
      default:
        this.finish();
    }
  }

  private _getDefaultCurrentStep(): string {
    return this.steps.root;
  }

  private _initSteps(): void {
    this.steps = {
      root: 'root',
      extempPreparation: 'extempPreparation'
    };
    this.setCurrentStep( this._getDefaultCurrentStep() );
  }
}
