import { IBillingAppType } from "./interfaces";

export enum EBillingAppType {
  Extemp = 1,         // Extemp
  Congress = 2,       // Congress
  ExtempCongress = 3, // Extemp & Congress
}

export type TBillingAppType =
  EBillingAppType.Extemp |
  EBillingAppType.Congress |
  EBillingAppType.ExtempCongress;

export class BillingAppType implements IBillingAppType {
  readonly id: number;
  readonly label: string;
  readonly no: number;
  
  constructor(id: number, label: string, no: number) {
    this.id = id;
    this.label = label;
    this.no = no;
  }
}

export class BillingAppTypeFactory {
  constructor() {}

  public createAppType(id: TBillingAppType): IBillingAppType {
    switch(id) {
      case EBillingAppType.Extemp:
        return new BillingAppType(EBillingAppType.Extemp, 'Extemp', 1);
      case EBillingAppType.Congress:
        return new BillingAppType(EBillingAppType.Congress, 'Congress', 1);
      case EBillingAppType.ExtempCongress:
        return new BillingAppType(EBillingAppType.ExtempCongress, 'Extemp and Congress', 2);
      default:
        return new BillingAppType(EBillingAppType.ExtempCongress, 'Extemp and Congress', 2);
    }
  }
}