import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxButtonComponent } from './aux-button/aux-button.component';
import { AuxButtonIconComponent } from './aux-button-icon/aux-button-icon.component';
import { ButtonComponent } from './button/button.component';
import { ButtonIconComponent } from './button-icon/button-icon.component';

const components = [
  AuxButtonComponent,
  AuxButtonIconComponent,
  ButtonComponent,
  ButtonIconComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ]
})
export class ButtonModule { }
