import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

import { Subscription } from "rxjs";

import { HttpService } from '../../../services/http.service';
import { NotificationsService } from "../../../services/notifications.service";
import { UtilService } from "../../../services/util.service";

import { CREATE_N_NOTIFICATION } from "../../../models/constants";
import { finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const headers = {
  default: "Create your Prepd account",
  tournament: "To join the Virtual Debate Circuit, create a Prepd account"
}

@Component({
  selector: 'signup-account-creation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './signup-account-creation.component.html',
  styleUrls: ['./signup-account-creation.component.scss']
})
export class SignupAccountCreationComponent implements OnInit {
  @Input()
  for: string = 'extemp';

  @Input()
  invitationToken: string;

  @Input()
  set flow(val:string){
    this.header = headers[val || "default"] || headers.default;
    this._flow = val;
  };

  @Output()
  onFinished = new EventEmitter();

  private _flow:string;
  header: string = headers.default;
  currentInputPasswordType: string;
  email: string;
  expand = {
    current: '',
    none: 'none',
    changeEmail: 'changeEmail',
    info: 'info'
  }
  isLoading = {
    creatingAccount: false,
    emailChanging: false,
    emailResending: false,
    emailVerify: false
  };
  formChangeEmail: FormGroup;
  formConfirmation: FormGroup;
  formCore: FormGroup;
  formName: FormGroup;
  stepGroup: StepGroup;
  
  private _data: any = {};
  private _inputPasswordTypes = {
    password: 'password',
    text: 'text'
  };
  private _errors = {
    signup: {
      emailInUse: 'We already have this email! Please try another one!',
      shortPassword: 'Oops! Your password has to be at least 5 characters long!',
      shortMail: 'Oops! Invalid email!',
      shortFirstName: 'Oops! Your first name has to be at least 1 character long!',
      shortLastName: 'Oops! Your last name has to be at least 1 character long!'
    },
    emailChange: {
      emailConfirmation: 'Oops! The two email addresses must match!',
      emailInUse: 'We already have this email! Please try another one!',
      badToken: 'Invalid verification token! Please email hello@prepd.in for help!',
      noPersonTiedToAcc: 'Oops, an internal error occured! Please email hello@prepd.in for help!',
      duplicateAccount: 'Your account is duplicated! Please email hello@prepd.in for help!',
      shortMail: 'Ooops! The email addresses have to be at least 5 characters long!'
    },
    emailResend: {
      noAccFound: 'Oops, an internal error occured (no account)! Please email hello@prepd.in for help!',
      duplicateAccount: 'Your account is duplicated! Please email hello@prepd.in for help!',
      badToken: 'Invalid verification token! Please email hello@prepd.in for help!'
    },
    emailVerify: {
      badToken: 'Incorrect verification code! Please make sure you are using the correct one!'
    }
  };
  private _teamId: number;
  private _warnings = {
    signup: {
      badToken: 'Account created! Warning: Your invitation to join the team has been removed.',
    },
    emailChange: {},
    emailResend: {},
    emailVerify: {}
  };

  _createAccountSubscription: Subscription;
  _emailChangeSubscription: Subscription;
  _emailResendSubscription: Subscription;
  _emailVerifySubscription: Subscription;
  _formEmailVerifySubscription: Subscription;

  constructor(
    private _elementRef: ElementRef,
    private _httpProvider: HttpService,
    private _notificationsProvider: NotificationsService,
    private _renderer: Renderer2,
    private _util: UtilService,
  ) {
    this.currentInputPasswordType = this._getDefaultInputPasswordType();
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    this.formConfirmation = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    });

    this.formCore = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(this._util.getEmailRegex())]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)])
    });

    this.formName = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(1)])
    });

    this.stepGroup = new StepGroup({
      core: new Step('core','/core'),
      name: new Step('name','/name'),
      confirmation: new Step('confirmation','/confirmation')
    });

    this.stepGroup.setCurrentStep('core');

    const formEmailVerify$ = this._createEmailVerify$(300);
    this._formEmailVerifySubscription = formEmailVerify$.subscribe();
  }

  ngOnDestroy() {
    if ( this._createAccountSubscription )
      this._createAccountSubscription.unsubscribe();

    if ( this._emailChangeSubscription )
      this._emailChangeSubscription.unsubscribe();

    if ( this._emailResendSubscription )
      this._emailResendSubscription.unsubscribe();

    if ( this._emailVerifySubscription )
      this._emailVerifySubscription.unsubscribe();

    if ( this._formEmailVerifySubscription )
      this._formEmailVerifySubscription.unsubscribe();
  }

  doCore() {
    this.stepGroup.setCurrentStep('name');
  }
  
  doCreateAccount() {
    if ( !this.formCore.valid || !this.formName.valid )
      return;

    if ( this._createAccountSubscription )
      this._createAccountSubscription.unsubscribe();

    const dataToSend = {
      accountData: {
        email: this.formCore.get('email').value,
        password: this.formCore.get('password').value
      },
      personData: {
        firstName: this.formName.get('firstName').value,
        lastName: this.formName.get('lastName').value
      },
      invitationDetails:  {
        token: this.invitationToken
      },
      flow: this._flow
    }

    this.isLoading.creatingAccount = true;

    const createAccount$ = this._httpProvider.postSignup(dataToSend);
    this._createAccountSubscription = createAccount$
      .pipe(
        finalize(() => this.isLoading.creatingAccount = false),
      ).subscribe(
        (response: any) => {
          this._setEmail(dataToSend.accountData.email);
          this._setFirstName(dataToSend.personData.firstName);
          this._setLastName(dataToSend.personData.lastName);
          this._setTeamId(response.result.teamId);
          this._setToken(response.result.token);
          this.stepGroup.setCurrentStep('confirmation');

          // invitation email warning -> invitation no longer
          const warning = response.message;
          if ( this._warnings.signup[warning] )
            this._notificationInfo( this._warnings.signup[warning] );
        },
        (error) => {
          const message = error.message.toLowerCase();

          if ( message.indexOf('email') >-1 || message.indexOf('password') >-1 )
            this.stepGroup.setCurrentStep('core');

          if ( this._errors.signup[error.message] )
            this._notificationError( this._errors.signup[error.message] );
        }
      );
  }

  doEmailChange() {
    if ( this.isLoading.emailChanging )
      return;

    if ( this.formChangeEmail.get('email').value !== this.formChangeEmail.get('confirmEmail').value ) {
      this._notificationError( this._errors.emailChange.emailConfirmation );
      return;
    }

    if ( this._emailChangeSubscription )
      this._emailChangeSubscription.unsubscribe();

    const dataToSend = {
      newMail: this.formChangeEmail.get('email').value,
      oldMail: this._getEmail(),
      token: this._getToken(),
    };

    this.isLoading.emailChanging = true;

    const verify$ = this._httpProvider.postSignupEmailChange(dataToSend);
    this._emailChangeSubscription = verify$
      .pipe(
        finalize(() => this.isLoading.emailChanging = false)
      ).subscribe(
        (response) => {
          // show notification
          this._setEmail(dataToSend.newMail);
          this._notificationSuccess(`Email address updated and confirmation email sent to ${dataToSend.newMail}!`);
          this.doExpand(this.expand.none);
        },
        (error) => {
          if ( this._errors.emailChange[error.message] )
            this._notificationError( this._errors.emailChange[error.message] );
        }
      );
  }

  doEmailResend(): void {
    if ( this.isLoading.emailResending )
      return;

    if ( this._emailResendSubscription )
      this._emailResendSubscription.unsubscribe();

    const dataToSend = {
      email: this._getEmail(),
      token: this._getToken()
    };

    this.isLoading.emailResending = true;

    const resend$ = this._httpProvider.postSignupEmailResend(dataToSend);
    this._emailResendSubscription = resend$
      .pipe(
        finalize(() => this.isLoading.emailResending = false),
      ).subscribe(
        (response) => this._notificationSuccess(`Confirmation email sent to ${dataToSend.email}!`),
        (error) => {
          if ( this._errors.emailResend[error.message] )
            this._notificationError( this._errors.emailResend[error.message] );
        }
      );
  }

  doEmailVerify() {
    if ( this.isLoading.emailVerify )
      return;

    if ( this._emailVerifySubscription )
      this._emailVerifySubscription.unsubscribe();

    const dataToSend = {
      confirmationCode: this.formConfirmation.get('code').value,
      email: this._getEmail(),
      token: this._getToken(),
    };

    this.isLoading.emailVerify = true;

    const verify$ = this._httpProvider.postSignupEmailVerify(dataToSend);
    this._emailVerifySubscription = verify$
      .pipe(
        finalize(() => this.isLoading.emailVerify = false),
      ).subscribe(
        (response) => this.onFinished.emit(),
        (error) => {
          if ( this._errors.emailVerify[error.message] )
            this._notificationError( this._errors.emailVerify[error.message] );
        }
      );
  }

  doExpand(type) {
    this.formChangeEmail = undefined;

    if ( type === this.expand.current ) {
        this.expand.current = this.expand.none;
        return;
    }

    switch(type) {
      case this.expand.changeEmail:
        this.formChangeEmail = new FormGroup({
          email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(this._util.getEmailRegex())]),
          confirmEmail: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(this._util.getEmailRegex())])
        });
        this.expand.current = this.expand.changeEmail;
        break;
      case this.expand.info:
        this.expand.current = this.expand.info;
        break;
      default:
        this.expand.current = this.expand.none;
        break;
    }
  }

  toggleInputPasswordType() {
    if ( this.currentInputPasswordType === this._inputPasswordTypes.password )
      this.currentInputPasswordType = this._inputPasswordTypes.text;
    else
      this.currentInputPasswordType = this._inputPasswordTypes.password;
  }

  private _createEmailVerify$(dbcTime: number = 300) {
    return this.formConfirmation
      .valueChanges
      .pipe(
        debounceTime(dbcTime),
        distinctUntilChanged(),
        map((data) => {
          if ( this.formConfirmation.valid )
            this.doEmailVerify();
        }),
      );
  }

  private _getDefaultInputPasswordType(): string {
    return this._inputPasswordTypes.password;
  }

  private _getEmail(): string {
    return this.email;
  }

  private _getFirstName(): string {
    return this._data.firstName;
  }

  private _getLastName(): string {
    return this._data.lastName;
  }

  private _getTeamId(): number {
    return this._data.teamId;
  }

  private _getToken(): string {
    return this._data.token;
  }

  private _setEmail(email: string): void {
    this.email = email;
  }

  private _setFirstName(firstName: string): void {
    this._data.first = firstName;
  }

  private _setLastName(lastName: string): void {
    this._data.lastName = lastName;
  }

  private _setTeamId(id: number): void {
    this._data.teamId = id;
  }

  private _setToken(token: string): void {
    this._data.token = token;
  }

  private _notificationSuccess(message: string) {
    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: {
          for: 'success',
          timeout: 2000,
          label: message
        }
    });
  }

  private _notificationError(label: string) {
    const notification = {
      for: 'error',
      showClose: true,
      timeout: 5000,
      label
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }

  private _notificationInfo(label: string) {
    const notification = {
      for: 'info',
      showClose: true,
      timeout: 5000,
      label
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }
}

class Step {
  label: string;
  option: any;

  constructor(label: string, option?: any) {
    this.setLabel(label);
    this.setOption(option);
  }

  setLabel(label: string) {
    this.label = label;
  }

  setOption(option: any) {
    this.option = option;
  }
}

class StepGroup {
  currentStep: Step;
  steps: {[key: string]: Step};

  constructor(steps: {[key: string]: Step}) {
    this.steps = steps;
  }

  setCurrentStep(key: string) {
    if ( this.steps[ key ] )
      this.currentStep = this.steps[ key ];
  }
}