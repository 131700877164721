import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsItemComponent } from './notifications-item/notifications-item.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';

const components = [
  NotificationsComponent,
  NotificationsItemComponent,
  NotificationsListComponent,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ]
})
export class NotificationsModule { }
