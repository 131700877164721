import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import { DELETE_N_NOTIFICATION } from '../../../models/constants';

import { NotificationsService } from "../../../services/notifications.service";

@Component({
  selector: 'pp-notifications-item',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './notifications-item.component.html',
  styleUrls: ['./notifications-item.component.scss']
})
export class NotificationsItemComponent implements OnInit {
  @Input()
  notification: any;

  private _timeout: number;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2,
              private _notificationsPrNotificationsProvider: NotificationsService) {}

  ngOnInit() {
    if (this.notification.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.notification.for}`);

    if (this.notification.timeout)
      this._startTimeout(this.notification.timeout);
  }

  ngOnDestroy() {
    this._stopTimeout();
  }

  removeNotification() {
    this._notificationsPrNotificationsProvider.dispatch({
      type: DELETE_N_NOTIFICATION,
      payload: this.notification
    });
  }

  private _startTimeout(timeout: number) {
    this._timeout = window.setTimeout(() => {
      this.removeNotification();
    }, timeout);
  }

  private _stopTimeout() {
    if (this._timeout)
      clearTimeout(this._timeout);
  }
}
