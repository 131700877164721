import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Router } from "@angular/router";

import { ISignupPrimaryData } from '../models/interfaces';
import { EUserRole } from '../../../models/user-role';
import { Pricing, EPlan } from '../../billing/models/pricing';
import { EBillingAction } from '../../billing/models/billing-action';

@Component({
  selector: 'signup-account-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './signup-account-details.component.html',
  styleUrls: ['./signup-account-details.component.scss']
})
export class SignupAccountDetailsComponent implements OnInit {
  @Input()
  for = 'extemp';

  @Input()
  data: ISignupPrimaryData | undefined;

  BillingAction = EBillingAction;
  currentStep: any;
  pricingFreeTeam: Pricing;
  pricingPremiumTeam: Pricing;
  recipientName: string = null;
  UserRole = EUserRole;

  readonly images;
  readonly steps;

  constructor(private _elementRef: ElementRef,
              private _router: Router,
              private _renderer: Renderer2) {
    this.pricingFreeTeam = new Pricing(EPlan.TeamFreeCreation, 5, 12, 1);
    this.pricingPremiumTeam = new Pricing(EPlan.TeamPremiumCreation, 5, 12, 1);
    this.images = {
      teacherIllustration: {
        alt: 'Student Illustration',
        src: 'assets/prepd-shared/img/png/teacher-illustration.png'
      },
      studentIllustration: {
        alt: 'Teacher Illustration',
        src: 'assets/prepd-shared/img/png/student-illustration.png'
      } 
    }
    this.steps = {
      root: 'root',
      coach: 'coach',
      debater: 'debater',
      teamJoinDebater: 'teamJoinDebater',
      teamJoinCoach: 'teamJoinCoach',
      individualCreation: 'individualCreation',
      teamCreation: 'teamCreation'
    };
    this.setCurrentStep( this._getDefaultCurrentStep() );
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    this.recipientName = `${this.data.firstName} ${this.data.lastName}`;
  }

  setCurrentStep(step: any) {
    this.currentStep = step;
  }

  goToStatusDebater() {
    this._router.navigate(['/status'], { queryParams: {  type: '0', reason: '101', target: '1' } });
  }

  goToStatusCoach() {
    this._router.navigate(['/status'], { queryParams: {  type: '0', reason: '101', target: '2' } });
  }

  private _getDefaultCurrentStep(): any {
    return this.steps.root;
  }
}
