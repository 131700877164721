import {
  Component,
  ElementRef,
  OnDestroy,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from "@angular/router";

import { Subscription } from "rxjs";

import { Routes } from "../../models/routes";
import { EUserRole } from '../../models/user-role';
@Component({
  selector: 'pp-status',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnDestroy, OnInit {
  @Input()
  message: string;

  @Input()
  for = 'extemp';

  @Output()
  onRestartChecks = new EventEmitter();

  type;
  reason;
  signupRoute: string;
  target: EUserRole.Member | EUserRole.Coach;

  private _queryParamsSubscription: Subscription;

  readonly TYPES = {
    OK: '0',
    ERROR: '1'
  };

  readonly messages = {
    0: {
      0: 'Thanks for verifying your email!',
      1: 'You have successfully joined your team!',
      2: 'The user has been added to your team successfully',
      3: 'You have denied the user\'s request',

      101: 'Prepd is a collection of four different applications. Pick one to get started.',
    },
    1: {
      0: 'Unknown error.',

      100: 'The request to join could not be found.',
      101: 'The request to join could not be processed.',
      102: 'The request to join has already been accepted.',
      103: 'The request to join has already been rejected.',
      104: 'Your team has reached it\'s member limit. Please increase the member limit from the Classroom before adding more members to your team.',
      105: 'The person you want to allow to join is already a part of your team.',

      200: 'The invitation could not be found.',
      201: 'The invitation could not be processed.',
      202: 'The invitation has already been accepted.',
      203: 'The invitation has already been rejected.',
      204: 'The team you are trying to join has reached it\'s member limit. Please ask the coach to increase the member limit from the Classroom before adding more members to the team.',
      205: 'You are already part of this team.',

      900: 'It looks like your browser settings block your access to Prepd! To load Prepd, please enable browser cookies.'
    }
  };

  readonly mainMessage = {
    0: 'Welcome to Prepd!',
    1: 'Ooops!!!'
  }

  readonly successSignupMessage = {
    [EUserRole.Member]: [{
        buttonLabel: 'Launch Library',
        for: 'library',
        imgAlt: 'Library',
        imgSrc: 'assets/prepd-shared/img/png/library-logo.png',
        link: 'https://library.prepd.in/',
        label: 'The Library stores all of team\'s research. The Library also offers educational tools that help you learn more from your reading. You can compete in Extemp the Library if you don\'t need to use folders and subfolders.'
      }, {
        buttonLabel: 'Launch Extemp',
        for: 'extemp',
        imgAlt: 'Extemp',
        imgSrc: 'assets/prepd-shared/img/png/extemp-logo.png',
        link: 'https://extemp.prepd.in/',
        label: 'Extemp helps extemporaneous speakers practice and compete. Use Extempe to organize your files into folders and subfolders. Create a "Round" to stay productive while competing. Access hundreds of Practice Questions.'
      }, {
        buttonLabel: 'Launch Congress',
        for: 'congress',
        imgAlt: 'Congress',
        imgSrc: 'assets/prepd-shared/img/png/congress-logo.png',
        link: 'https://congress.prepd.in/',
        label: 'Congress helps congressional debaters practice and compete. Organize your research  into dockets and bills. Source arguments from your reading. Upload speeches. Maintain precedence with the Precedence Tracker.'
      }, {
        buttonLabel: 'Launch Classroom',
        for: 'classroom',
        imgAlt: 'Classroom',
        imgSrc: 'assets/prepd-shared/img/png/classroom-logo.png',
        link: 'https://classroom.prepd.in/',
        label: 'The Classroom helps you monitor team activity and manage your membership. Join a team and review filing, reading, and round activity for yourself and your teammates.'
    }],
    [EUserRole.Coach]: [{
        buttonLabel: 'Launch Library',
        for: 'library',
        imgAlt: 'Library',
        imgSrc: 'assets/prepd-shared/img/png/library-logo.png',
        link: 'https://library.prepd.in/',
        label: 'The Library stores all of team\'s research. The Library also offers educational tools that help your students learn more from their reading. Extempers can compete with the Library if they don\'t need to use folders and subfolders.'
      }, {
        buttonLabel: 'Launch Extemp',
        for: 'extemp',
        imgAlt: 'Extemp',
        imgSrc: 'assets/prepd-shared/img/png/extemp-logo.png',
        link: 'https://extemp.prepd.in/',
        label: 'Extemp helps extemporaneous speakers practice and compete. This app allows extempers to organize their files into folders and subfolders. Extemp provides hundreds of practice questions written by students and curated by Prepd.'
      }, {
        buttonLabel: 'Launch Congress',
        for: 'congress',
        imgAlt: 'Congress',
        imgSrc: 'assets/prepd-shared/img/png/congress-logo.png',
        link: 'https://congress.prepd.in/',
        label: 'Congress helps congressional debaters practice and compete. Your students can organize their research into dockets and bills, source arguments from their reading, upload speeches, and track precedence and recency.'
      }, {
        buttonLabel: 'Launch Classroom',
        for: 'classroom',
        imgAlt: 'Classroom',
        imgSrc: 'assets/prepd-shared/img/png/classroom-logo.png',
        link: 'https://classroom.prepd.in/',
        label: 'The Classroom helps you manage and monitor your team, review your students\' filing, reading, and round activity. From here, you can also adjust team settings and take care of billing.'
    }]
  };

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.signupRoute = `${Routes.signupClient}/signup`

    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    this._queryParamsSubscription = this._activatedRoute
      .queryParams
      .subscribe(params => {
        this.type = params['type'];
        this.reason = params['reason'];
        this.target = params['target'];
      });
  }

  ngOnDestroy() {
    if ( this._queryParamsSubscription )
      this._queryParamsSubscription.unsubscribe();
  }

  goToSignup() {
    window.location.href = `${Routes.signupClient}/signup`;
  }

  restartChecks() {
    this.onRestartChecks.emit();
  }
}
