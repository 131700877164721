import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
 } from '@angular/core';

@Component({
  selector: 'pp-input-icon',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './input-icon.component.html',
  styleUrls: ['./input-icon.component.scss']
})
export class InputIconComponent implements OnInit {
  @Input()
  iconFor: string;

  @Input()
  iconSize: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) {}

  ngOnInit() {
    if (this.iconFor)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.iconFor}`);

    if (this.iconSize)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.iconSize}`);
  }
}
