import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TeamListComponent, TeamListValueAccessor } from './team-list/team-list.component';
import { TeamListItemComponent } from './team-list-item/team-list-item.component';

const components = [
  TeamListComponent,
  TeamListItemComponent,
  TeamListValueAccessor,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ]
})
export class TeamModule { }
