import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'pp-dropdown-menu-with-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dropdown-menu-with-header.component.html',
  styleUrls: ['./dropdown-menu-with-header.component.scss']
})
export class DropdownMenuWithHeaderComponent implements OnInit {
  @Input('dropdownMenuWithHeaderPosition')
  position: string;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2) { }

  ngOnInit() {
    if (this.position) {
      const placement = this.position.split(' ');
      this._renderer.addClass(this._elementRef.nativeElement, `-${placement[0]}`);
      this._renderer.addClass(this._elementRef.nativeElement, `-${placement[1]}`);
    }
  }
}
