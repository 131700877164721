import { IBillingPaymentMethod } from '../models/interfaces';

export enum EBillingPaymentType {
  UpdateInfo,
  Card,
  Check,
  CheckDT,
  CheckIn,
  CheckPO
}

export type TBillingPaymentType =
  EBillingPaymentType.UpdateInfo |
  EBillingPaymentType.Card |
  EBillingPaymentType.Check |
  EBillingPaymentType.CheckDT |
  EBillingPaymentType.CheckIn |
  EBillingPaymentType.CheckPO;

export class BillingPaymentType implements IBillingPaymentMethod {
  readonly id: number;
  readonly isUsed: boolean;
  readonly label: string;
  readonly value: string;
  
  constructor(id: number, value, label: string, isUsed: boolean) {
    this.id = id;
    this.value = value;
    this.label = label;
    this.isUsed = isUsed;
  }
}

export class BillingPaymentTypeFactory {
  constructor() {}

  public createPaymentType(id): IBillingPaymentMethod {
    switch(id) {
      case EBillingPaymentType.UpdateInfo:
        return new BillingPaymentType(EBillingPaymentType.UpdateInfo, 'updateInfo', 'Payment Update', false);
      case EBillingPaymentType.Card:
        return new BillingPaymentType(EBillingPaymentType.Card, 'card', 'Credit or Debit Card', true);
      case EBillingPaymentType.Check:
        return new BillingPaymentType(EBillingPaymentType.Check, 'check', 'Check', false);
      case EBillingPaymentType.CheckDT:
        return new BillingPaymentType(EBillingPaymentType.CheckDT, 'check-dt', 'School District Purchase', true);
      case EBillingPaymentType.CheckIn:
        return new BillingPaymentType(EBillingPaymentType.CheckIn, 'check-in', 'Check (Invoice)', true);
      case EBillingPaymentType.CheckPO:
        return new BillingPaymentType(EBillingPaymentType.CheckPO, 'check-po', 'Check (Purchase Order)', true);
      default:
        return new BillingPaymentType(EBillingPaymentType.Check, 'check', 'Check', false);
    }
  }

  public createPaymentTypeValue(value: string): IBillingPaymentMethod {
    const object: { [key: string]: IBillingPaymentMethod } = this.getPaymentObjectByValue();
    switch(value) {
      case object[EBillingPaymentType.UpdateInfo].value:
        return new BillingPaymentType(EBillingPaymentType.UpdateInfo, 'updateInfo', 'Payment Update', false);
      case object[EBillingPaymentType.Card].value:
        return new BillingPaymentType(EBillingPaymentType.Card, 'card', 'Credit or Debit Card', true);
      case object[EBillingPaymentType.Check].value:
        return new BillingPaymentType(EBillingPaymentType.Check, 'check', 'Check', false);
      case object[EBillingPaymentType.CheckDT].value:
        return new BillingPaymentType(EBillingPaymentType.CheckDT, 'check-dt', 'School District Purchase', true);
      case object[EBillingPaymentType.CheckIn].value:
        return new BillingPaymentType(EBillingPaymentType.CheckIn, 'check-in', 'Check (Invoice)', true);
      case object[EBillingPaymentType.CheckPO].value:
        return new BillingPaymentType(EBillingPaymentType.CheckPO, 'check-po', 'Check (Purchase Order)', true);
      default:
        return new BillingPaymentType(EBillingPaymentType.Check, 'check', 'Check', false);
    }
  }

  public getPaymentArray(): IBillingPaymentMethod[] {
    let payments: any[] = [EBillingPaymentType.UpdateInfo, EBillingPaymentType.Card, EBillingPaymentType.Check, EBillingPaymentType.CheckIn, EBillingPaymentType.CheckPO, EBillingPaymentType.CheckDT];
    
    return payments.map((value) => this.createPaymentType(value));
  }

  public getPaymentArrayUsed(): IBillingPaymentMethod[] {
    return this.getPaymentArray().filter((payment: IBillingPaymentMethod) => payment.isUsed);
  }

  public getPaymentObjectById(): { [key: string]: IBillingPaymentMethod } {
    return {
      [EBillingPaymentType.UpdateInfo]: this.createPaymentType(EBillingPaymentType.UpdateInfo),
      [EBillingPaymentType.Card]: this.createPaymentType(EBillingPaymentType.Card),
      [EBillingPaymentType.Check]: this.createPaymentType(EBillingPaymentType.Check),
      [EBillingPaymentType.CheckDT]: this.createPaymentType(EBillingPaymentType.CheckDT),
      [EBillingPaymentType.CheckIn]: this.createPaymentType(EBillingPaymentType.CheckIn),
      [EBillingPaymentType.CheckPO]: this.createPaymentType(EBillingPaymentType.CheckPO)
    };
  }

  public getPaymentObjectByValue(): { [key: string]: IBillingPaymentMethod } {
    return {
      [this.createPaymentType(EBillingPaymentType.UpdateInfo).value]: this.createPaymentType(EBillingPaymentType.UpdateInfo),
      [this.createPaymentType(EBillingPaymentType.Card).value]: this.createPaymentType(EBillingPaymentType.Card),
      [this.createPaymentType(EBillingPaymentType.Check).value]: this.createPaymentType(EBillingPaymentType.Check),
      [this.createPaymentType(EBillingPaymentType.CheckDT).value]: this.createPaymentType(EBillingPaymentType.CheckDT),
      [this.createPaymentType(EBillingPaymentType.CheckIn).value]: this.createPaymentType(EBillingPaymentType.CheckIn),
      [this.createPaymentType(EBillingPaymentType.CheckPO).value]: this.createPaymentType(EBillingPaymentType.CheckPO)
    };
  }
}