import { Injectable, NgZone } from '@angular/core';

import { Observable, bindCallback } from "rxjs";

declare var Stripe: any;

@Injectable()
export class StripeService {

  constructor(private _zone: NgZone) {
    // Stripe.setPublishableKey('pk_live_q7jqG2IocnZrK11Hwqxk5wPr');
    Stripe.setPublishableKey('pk_test_M9alypI721aL8rNgjsNKBdFh');
  }


  getStripeToken(dataToSend): Observable<any> {
    const func =  bindCallback(
      (dataToSend, responseHandler: (status: any, response) => void ) => Stripe.card.createToken(dataToSend, responseHandler),
      (status: any, response) => ({ status, response })
    );

    return func(dataToSend);
  }
}
