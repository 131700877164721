import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  Router
} from "@angular/router";

import { NotificationsService } from "../../../../services/notifications.service";
import { UtilService } from "../../../../services/util.service";

import { CREATE_N_NOTIFICATION } from "../../../../models/constants";
import {
  IState,
  ITeamType
} from "../../../../models/interfaces";
import { ETeamType, TeamTypeFactory, TTeamType } from '../../../../models/team-type';
import { TBillingAppType, EBillingAppType } from '../../models/app';
import { TBillingAction, EBillingAction, BillingActionFactory } from '../../models/billing-action';

@Component({
  selector: 'billing-team-creation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-team-creation.component.html',
  styleUrls: ['./billing-team-creation.component.scss']
})
export class BillingTeamCreationComponent implements OnInit {
  @Input()
  for = 'extemp';

  @Input()
  billingAction: TBillingAction | undefined;

  @Input()
  teamTypeTarget: ETeamType | undefined;

  @Input()
  teamId: number;

  @Input()
  recipientName: string;

  @Output()
  onFinish = new EventEmitter();

  appSelected: TBillingAppType;
  currentStep: any;
  isLoading: boolean = false;
  newTeamId: number;
  steps: {[key: string]: string};
  TeamType = ETeamType;
  
  readonly TEAM_TYPES: ITeamType[];
  readonly STATES: IState[];

  teamForm: FormGroup;

  private readonly _errors;
  private _teamTypeTarget: ETeamType;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _notificationsProvider: NotificationsService,
    private _router: Router,
    private _utilProvider: UtilService,
  ) {
    this._initSteps();

    this.TEAM_TYPES = this._utilProvider.toArray( this._utilProvider.getSchoolTypes() );
    this.STATES = this._utilProvider.toArray( this._utilProvider.getStates() );
    this._errors = {
      required: {
        schoolName: 'School name is required',
        // district: 'District is required',
        schoolType: 'School type is required',
        address1: 'Address is required',
        city: 'City is required',
        state: 'State is required',
        zip: 'Zip is required',
        area: 'Area code is required',
        telNo: 'Phone Number is required'
      },
      minlength: {
        schoolName: 'School name is too short',
        district: 'District is too short',
        address1: 'Address is too short',
        city: 'City is too short',
        area: 'Area code is too short',
        telNo: 'Phone number is too short'
      },
      maxlength: {
        schoolName: 'School name is too long',
        area: 'Area code is too long',
        telNo: 'Phone number is too long'
      },
      pattern: {
        zip: 'Zip must be a number of 5 digits',
        area: 'Area code must be a number',
        telNo: 'Phone number must be a number',
        ext: 'Ext must be a number'
      }
    };
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    // this._createTeamForm(this.data.school);
    this._createTeamForm();
  }

  finish() {
    this.onFinish.emit(this._teamTypeTarget);
  }

  goToExtempPreparation({app, newTeamId}) {
    this.newTeamId = newTeamId;

    if (this.billingAction !== EBillingAction.Create) {
      this.finish();
      return;
    }

    switch (app) {
      case EBillingAppType.Extemp:
      case EBillingAppType.ExtempCongress:
        this.setCurrentStep(this.steps.extempPreparation);
        break;
      case EBillingAppType.Congress:
      default:
        this.finish();
    }
  }

  goToWelcomePackage({app, newTeamId}, teamType: TTeamType) {
    this.newTeamId = newTeamId;

    switch (teamType) {
      case ETeamType.PremiumTeam:
        this.appSelected = app;
        this.setCurrentStep(this.steps.welcomePackage);
        break;
      case ETeamType.PremiumOne:
      case ETeamType.FreeTeam:
      case ETeamType.FreeOne:
      default:
        this.goToExtempPreparation({app, newTeamId});
    }
  }

  goToStatus() {
    this._router.navigate(['/status'], { queryParams: {  type: '0', reason: '101', target: '2' } });
  }

  selectTeamType(form: FormGroup, teamType: ETeamType) {
    if (this._showFormErrors(form, this._errors))
      return;

    this._teamTypeTarget = teamType;

    switch (teamType) {
      case ETeamType.PremiumTeam:
        this.setCurrentStep(this.steps.premium);
        break;
      case ETeamType.FreeTeam:
        this.setCurrentStep(this.steps.free);
        break;
      case ETeamType.PremiumOne:
      case ETeamType.FreeOne:
      default:
        break;
    }
  }

  setCurrentStep(step: any) {
    this.currentStep = step;
  }

  private _createTeamForm(school?): void {
    this.teamForm = new FormGroup({
      address1: new FormControl(null,
        [Validators.required,
        Validators.minLength(5)
        ]),
      address2: new FormControl(null),
      city: new FormControl(null,
        [Validators.required,
        Validators.minLength(3)
        ]),
      district: new FormControl(null, Validators.minLength(3)),
      phone: new FormGroup({
        area: new FormControl(null, 
          [Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3),
          Validators.pattern(this._utilProvider.getNumberRegex())
          ]),
        telNo: new FormControl(null, 
          [Validators.required,
          Validators.minLength(7),
          Validators.maxLength(7),
          Validators.pattern(this._utilProvider.getNumberRegex())]),
        ext: new FormControl(null, Validators.pattern(this._utilProvider.getNumberRegex()))
      }),
      schoolName: new FormControl(null,
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)]),
      schoolType: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      zip: new FormControl(null,
        [Validators.required,
        Validators.pattern(this._utilProvider.getZipRegex())
        ])
    });
    if (school) this.teamForm.patchValue(school);
  }

  private _getDefaultCurrentStep(): any {
    return this.steps.root;
  }

  private _initSteps(): void {
    this.steps = {
      root: 'root',
      free: 'free',
      premium: 'premium',
      extempPreparation: 'extempPreparation',
      welcomePackage: 'welcomePackage'
    };
    this.setCurrentStep( this._getDefaultCurrentStep() );
  }

  private _notificationError(message: string) {
    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: {
        for: 'error',
        showClose: true,
        timeout: 5000,
        label: message
      }
    });
  }

  private _showFormErrors(formGroup: FormGroup, errorMessages: any) {
    const controls = Object.keys(formGroup.controls);
    let hasError: boolean = false;

    controls.forEach((controlName: string) => {
      const control = formGroup.controls[controlName];

      if (control instanceof FormGroup) {
        hasError = this._showFormErrors(control, errorMessages) ? true : hasError;
        return;
      }

      if (control.valid)
        return;

      hasError = true;
      Object.keys(control.errors)
        .forEach((error: string) => {
          this._notificationError(errorMessages[error][controlName]);      
        });
    }, this);

    return hasError;
  }
}
