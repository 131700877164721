import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { TTeamType, ETeamType, TeamType, TeamTypeFactory } from '../../../../models/team-type';
import { Pricing, EPlan } from '../../models/pricing';

@Component({
  selector: 'billing-congrats',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-congrats.component.html',
  styleUrls: ['./billing-congrats.component.scss']
})
export class BillingCongratsComponent implements OnInit {
  @Input()
  for: string;

  @Input()
  currentTeamType: TTeamType;

  message: string;
  team: TeamType;
  teamType = ETeamType;
  pricing: Pricing;

  constructor() { }

  ngOnInit() {
    this.team = new TeamTypeFactory().createTeamType(this.currentTeamType);

    switch(this.currentTeamType) {
      case ETeamType.PremiumOne:
        this.pricing = new Pricing(EPlan.OnePremiumCreation, 1, 12, 2);
        break;
      case ETeamType.PremiumTeam:
        this.pricing = new Pricing(EPlan.TeamPremiumCreation, 1, 12, 2);
      default:
        break;
    }
  }
}
