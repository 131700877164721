import {
  ETeamType,
  TeamType,
  TeamTypeFactory,
  TTeamType,
} from "../../../models/team-type";

import { IBillingTeamType } from "./interfaces";
import { ITeamType } from "../../../models/interfaces";

export class BillingTeamType extends TeamType implements IBillingTeamType {
  readonly value: string;
  
  constructor(id: number, label: string, value: string) {
    super(id, label);
    this.value = value;
  }
}

export class BillingTeamTypeFactory {
  constructor() {}

  public createBillingTeamType(id: TTeamType): IBillingTeamType {
    let team: ITeamType;
    switch(id) {
      case ETeamType.PremiumTeam:
        team = new TeamTypeFactory().createTeamType(ETeamType.PremiumTeam);
        return new BillingTeamType(team.id, team.label, 'proTeam');
      case ETeamType.PremiumOne:
        team = new TeamTypeFactory().createTeamType(ETeamType.PremiumOne);
        return new BillingTeamType(team.id, team.label, 'proOne');
      case ETeamType.FreeTeam:
        team = new TeamTypeFactory().createTeamType(ETeamType.FreeTeam);
        return new BillingTeamType(team.id, team.label, 'freeTeam');
      case ETeamType.FreeOne:
        team = new TeamTypeFactory().createTeamType(ETeamType.FreeOne);
        return new BillingTeamType(team.id, team.label, 'freeOne');
      default:
        team = new TeamTypeFactory().createTeamType(ETeamType.NA);
        return new BillingTeamType(team.id, team.label, 'UNDEFINED');
    }
  }
}