import { Injectable } from '@angular/core';

import { BehaviorSubject, of, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { IAction } from '../models/interfaces';

import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';

import {
  OFFLINE,

  INCREMENT_REQUESTS,
  DECREMENT_REQUESTS,

  UPDATE_CONNECTION,
  UPDATE_AUTHENTICATED,
  UPDATE_PROFILE,
  UPDATE_CURRENT_TEAM,
  TOGGLE_NAV_MENU,
  UPDATE_HAS_FILTERS_MENU,
  TOGGLE_FILTERS_MENU,
  TOGGLE_USER_MENU,
  UPDATE_SMART_SEARCH_OPEN,
  UPDATE_NEWS_FEED_OPEN,
  UPDATE_CONTRIBUTORS,
  UPDATE_PUBLICATIONS,
  UPDATE_FTU_OPTIONS,
  UPDATE_BANNER_STATE,
  RESET_BANNERS,

  // offline
  // main
  INIT_OL,
  UPDATE_OL_SYNC_STAMPS,
  UPDATE_OL_SYNC_STATUS,
  UPDATE_OL_SYNC_OPTION_PDFS,
  UPDATE_OL_SYNC_OPTION_NEWS_FEED,
  UPDATE_OL_SYNC_OPTION_LIBRARY,
  UPDATE_OL_SYNC_OPTION_EXTEMP,
  UPDATE_OL_SYNC_OPTION_CONGRESS,
  UPDATE_OL_SYNC_COUNT_PDFS,

  // library
  // fast-catch
  UPDATE_LFC_ARTICLE,
  // smart search
  TOGGLE_LSS_EDIT,
  RESET_LSS_QUERY,
  UPDATE_LSS_QUERY,
  UPDATE_LSS_CURRENT_TIMEFRAME,
  UPDATE_LSS_ARTICLES_CURRENT_PAGE,
  UPDATE_LSS_ARTICLES_ARTICLES_IDS,
  UPDATE_LSS_ARTICLES_ARTICLES,
  UPDATE_LSS_ARTICLES_ARTICLE_SUMMARY,
  // news feed
  RESET_LNF,
  TOGGLE_LNF_EDIT,
  RESET_LNF_QUERY,
  UPDATE_LNF_QUERY,
  UPDATE_LNF_CURRENT_TIMEFRAME,
  UPDATE_LNF_ARTICLES_CURRENT_PAGE,
  UPDATE_LNF_ARTICLES_ARTICLES_IDS,
  UPDATE_LNF_ARTICLES_ARTICLES,
  UPDATE_LNF_ARTICLES_ARTICLE_SUMMARY,
  // main
  // article feed
  UPDATE_LAF_ARTICLES_CONTRIBUTOR_FILTER,
  UPDATE_LAF_ARTICLES_PUBLICATION_FILTER,  
  RESET_LAF_ARTICLES_FILTERS,
  UPDATE_LAF_ARTICLES_CURRENT_ORDER_SORTER,
  UPDATE_LAF_ARTICLES_CURRENT_READ_FILTER,
  UPDATE_LAF_ARTICLES_CURRENT_SHOW_FILTER,
  UPDATE_LAF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER,
  UPDATE_LAF_ARTICLES_CURRENT_PUBLICATION_FILTER,  
  UPDATE_LAF_ARTICLES_CURRENT_APP_FILTER,
  UPDATE_LAF_ARTICLES_CURRENT_PAGE,
  UPDATE_LAF_ARTICLES_ARTICLES_IDS,
  UPDATE_LAF_ARTICLES_ARTICLES,
  UPDATE_LAF_ARTICLES_ARTICLE_SUMMARY,
  // vocabulary
  RESET_LV_FILTERS,
  UPDATE_LV_CURRENT_ORDER_SORTER,
  UPDATE_LV_CURRENT_REVIEW_FILTER,
  UPDATE_LV_CURRENT_STATUS_FILTER,
  UPDATE_LV_CURRENT_PAGE,
  UPDATE_LV_ENTRIES_IDS,
  UPDATE_LV_ENTRIES,
  UPDATE_LV_CURRENT_SEARCH_KEYWORD,
  UPDATE_LV_CURRENT_DATE_FILTER,
  // memory bank
  RESET_LMB_MEMORIES_FILTERS,
  UPDATE_LMB_MEMORIES_CURRENT_ORDER_SORTER,
  UPDATE_LMB_MEMORIES_CURRENT_MASTERED_FILTER,
  UPDATE_LMB_MEMORIES_CURRENT_CONTRIBUTOR_FILTER,
  UPDATE_LMB_MEMORIES_CURRENT_DATE_FILTER,
  UPDATE_LMB_MEMORIES_TOPICS_FILTER,
  UPDATE_LMB_MEMORIES_SEARCH_KEYWORD,
  UPDATE_LMB_PAGE_VIEW,
  UPDATE_LMB_MEMORIES_IDS,
  UPDATE_LMB_MEMORIES_DETAILS,
  UPDATE_LMB_MEMORIES_CURRENT_PAGE,

  // extemp
  // smart search
  TOGGLE_ESS_EDIT,
  RESET_ESS_QUERY,
  UPDATE_ESS_QUERY,
  UPDATE_ESS_CURRENT_TIMEFRAME,
  UPDATE_ESS_ARTICLES_CURRENT_PAGE,
  UPDATE_ESS_ARTICLES_ARTICLES_IDS,
  UPDATE_ESS_ARTICLES_ARTICLES,
  UPDATE_ESS_ARTICLES_ARTICLE_SUMMARY,
  // news feed
  RESET_ENF,
  TOGGLE_ENF_EDIT,
  RESET_ENF_QUERY,
  UPDATE_ENF_QUERY,
  UPDATE_ENF_CURRENT_TIMEFRAME,
  UPDATE_ENF_ARTICLES_CURRENT_PAGE,
  UPDATE_ENF_ARTICLES_ARTICLES_IDS,
  UPDATE_ENF_ARTICLES_ARTICLES,
  UPDATE_ENF_ARTICLES_ARTICLE_SUMMARY,

  UPDATE_E_FOLDERS_CATEGORIES,
  UPDATE_EF_ARTICLES_CONTRIBUTOR_FILTER,
  UPDATE_EF_ARTICLES_PUBLICATION_FILTER,
  UPDATE_EF_ARTICLES_CURRENT_SEARCH_ORDER,
  UPDATE_EF_FOLDERS_CURRENT_ORDER_SORTER,
  RESET_EF_ARTICLES_FILTERS,
  UPDATE_EF_ARTICLES_CURRENT_ORDER_SORTER,
  UPDATE_EF_ARTICLES_CURRENT_READ_FILTER,
  UPDATE_EF_ARTICLES_CURRENT_SHOW_FILTER,
  UPDATE_EF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER,
  UPDATE_EF_ARTICLES_CURRENT_PUBLICATION_FILTER,
  UPDATE_EF_ARTICLES_CURRENT_PAGE,
  UPDATE_EF_ARTICLES_ORDERED_IDS,
  UPDATE_EF_ARTICLES_ARTICLES_IDS,
  UPDATE_EF_ARTICLES_ARTICLES_IDS_CURRENT_FOLDER,
  UPDATE_EF_ARTICLES_ARTICLES,
  UPDATE_EF_ARTICLES_ARTICLE_SUMMARY,
  UPDATE_EF_FOLDERS,
  UPDATE_EF_INPUT_FOLDERS,
  UPDATE_EF_FILTERED_FOLDERS,
  UPDATE_EF_CATEGORIES_FILTER,
  UPDATE_EF_SELECTION,
  UPDATE_EF_FOLDERS_MULTISELECT,
  UPDATE_EE_SELECTED_ARTICLES,
  UPDATE_EE_IS_ARTICLES_MULTISELECT,
  UPDATE_EE_SELECT_ALL_ARTICLES,
  // practice question
  UPDATE_EPQ_DRAW_QUESTIONS,
  UPDATE_EPQ_SELECTED,
  RESET_EPQ,
  UPDATE_EPQ_QUESTIONS_IDS,
  UPDATE_EPQ_QUESTIONS_QUESTIONS,
  UPDATE_EPQ_CURRENT_PAGE,
  UPDATE_EPQ_QUESTIONS_STATE,
  UPDATE_EPQ_FILTERS,

  // congress
  // smart search
  TOGGLE_CSS_EDIT,
  RESET_CSS_QUERY,
  UPDATE_CSS_QUERY,
  UPDATE_CSS_CURRENT_TIMEFRAME,
  UPDATE_CSS_ARTICLES_CURRENT_PAGE,
  UPDATE_CSS_ARTICLES_ARTICLES_IDS,
  UPDATE_CSS_ARTICLES_ARTICLES,
  UPDATE_CSS_ARTICLES_ARTICLE_SUMMARY,
  // news feed
  RESET_CNF,
  TOGGLE_CNF_EDIT,
  RESET_CNF_QUERY,
  UPDATE_CNF_QUERY,
  UPDATE_CNF_CURRENT_TIMEFRAME,
  UPDATE_CNF_ARTICLES_CURRENT_PAGE,
  UPDATE_CNF_ARTICLES_ARTICLES_IDS,
  UPDATE_CNF_ARTICLES_ARTICLES,
  UPDATE_CNF_ARTICLES_ARTICLE_SUMMARY,

  UPDATE_C_ARGUMENT_CONTENT,
  UPDATE_C_ARGUMENTS_IDS,
  UPDATE_C_ARGUMENTS,
  UPDATE_C_ARGUMENTS_CURRENT_SIDE_FILTER,
  UPDATE_C_ARGUMENTS_CURRENT_SORT,
  UPDATE_C_ARGUMENTS_CURRENT_PAGE,

  UPDATE_CD_DOCKETS,
  UPDATE_CD_DOCKETS_DOCKET,
  UPDATE_CD_DOCKETS_DOCKETS_IDS_ORDER,
  UPDATE_CD_DOCKETS_DOCKETS_IDS,
  UPDATE_CD_DOCKETS_CURRENT_PAGE,
  UPDATE_CD_DOCKETS_CURRENT_ORDER_SORTER,
  UPDATE_CD_DOCKETS_CURRENT_SAVED_FILTER,
  UPDATE_CD_DOCKETS_CURRENT_STATE_FILTER,
  UPDATE_CD_DOCKETS_CURRENT_DATE_FILTER,
  UPDATE_CD_DOCKETS_STATES_FILTER,
  UPDATE_CD_ARTICLES_ARTICLE_SUMMARY,
  UPDATE_CDB_ARTICLES,
  UPDATE_CDB_ARTICLE_IDS,
  UPDATE_CDB_ARTICLES_CURRENT_PAGE,
  UPDATE_CDBB_SPEECH,
  UPDATE_CDBB_SPEECHES,
  UPDATE_CDBB_SPEECHES_IDS,
  UPDATE_CDBB_SPEECH_CONTENT,
  UPDATE_CDBB_SPEECHES_CURRENT_PAGE,
  UPDATE_CDBB_SPEECHES_CURRENT_SIDE_FILTER,
  UPDATE_CDBB_SPEECHES_CURRENT_AUTHOR_FILTER,
  RESET_CD_DOCKETS_FILTERS,
  UPDATE_CD_BILLS_CURRENT_ORDER_SORTER,
  RESET_CD_BILLS_FILTERS,

  UPDATE_CD_CURRENT_PAGE_BILL_LIST,
  UPDATE_CD_CURRENT_PAGE_BILL_DETAILS,
  UPDATE_CD_CURRENT_PAGE_DOCKET_DETAILS,
  UPDATE_CD_CURRENT_PAGE_DOCKET_LIST,
  UPDATE_CD_HAS_DOCKETS,

  RESET_CDBL_FILTERS,
  UPDATE_CDBL_BILLS,
  UPDATE_CDBL_BILLS_FILTERED,
  UPDATE_CDBL_CURRENT_DOCKET,
  UPDATE_CDBL_DOCKETS,
  UPDATE_CDBL_CURRENT_ORDER_SORTER,
  UPDATE_CDBL_INPUT,

  RESET_CDDD_FILTERS,
  UPDATE_CDDD_BILLS,
  UPDATE_CDDD_BILLS_FILTERED,
  UPDATE_CDDD_CURRENT_ORDER_SORTER,
  UPDATE_CDDD_INPUT,

  UPDATE_CDDL_INPUT,

  // save to
  INIT_ST_E_CATEGORIES,

  INIT_ST_E_FOLDERS,
  UPDATE_ST_E_FOLDERS,
  CHANGE_ST_E_SORT,
  CHANGE_ST_E_FILTER,
  TOGGLE_ST_E_TOGGLED_FOLDER,
  TOGGLE_ST_E_SELECTED_FOLDER,
  CREATE_ST_E_FOLDER,
  DELETE_ST_E_FOLDER,
  TOGGLE_ST_E_SELECTED_SUBFOLDER,
  CREATE_ST_E_SUBFOLDER,
  DELETE_ST_E_SUBFOLDER,

  INIT_ST_C_DOCKETS,
  CHANGE_ST_C_FILTER,
  TOGGLE_ST_C_TOGGLED_DOCKET,
  TOGGLE_ST_C_SELECTED_BILL,
  UPDATE_CHALLENGE_PROGRESS
} from '../models/constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _store: any;

  private _store$: BehaviorSubject<any>;
  get store$() {
    return this._store$;
  }

  private _id = 0;

  private _collator: Intl.Collator = Intl.Collator('en', { numeric: true });

  constructor(
    private _localStorageProvider: LocalStorageService,
    private _utilService: UtilService
  ) {
    this.init();

    this._store$ = new BehaviorSubject(this._store);
  }

  get user() {
    return this._store.user;
  }

  get currentTeam() {
    return this._store.currentTeam;
  }

  newsFeedPublications() {
    return {
      '21': { id: 21, name: 'HuffPost' },
      '41': { id: 41, name: 'CNN' },
      '90': { id: 90, name: 'Al Jazeera America' },
      '108': { id: 108, name: 'Toronto Star' },
      '181': { id: 181, name: 'Los Angeles Times' },
      '363': { id: 363, name: 'The Moscow Times' },
      '905': { id: 905, name: 'Peterson Institute for International Economics' },
      '987': { id: 987, name: 'China Daily' },
      '3386': { id: 3386, name: 'Press TV' },
      '3443': { id: 3443, name: 'The Guardian' },
      '3444': { id: 3444, name: 'Christian Science Monitor' },
      '3451': { id: 3451, name: 'The Cato Institute' },
      '3454': { id: 3454, name: 'Der Spiegel' },
      '3462': { id: 3462, name: 'Reuters' },
      '3533': { id: 3533, name: 'Politico' },
      '3545': { id: 3545, name: 'Business Insider' },
      '3547': { id: 3547, name: 'Houston Chronicle' },
      '3691': { id: 3691, name: 'All Africa' },
      '3697': { id: 3697, name: 'IOL Cape Times' },
      '3701': { id: 3701, name: 'Brookings Institution' },
      '3707': { id: 3707, name: 'NPR' },
      '3714': { id: 3714, name: 'NBC News' },
      '3905': { id: 3905, name: 'Indian Express' },
      '4122': { id: 4122, name: 'The Jerusalem Post' },
      '4262': { id: 4262, name: 'Wired' },
      '4341': { id: 4341, name: 'CNBC' },
      '4395': { id: 4395, name: 'Sydney Morning Herald' },
      '4562': { id: 4562, name: 'Council on Foreign Relations' },
      '4572': { id: 4572, name: 'National Review' },
      '4573': { id: 4573, name: 'New Republic' },
      '4610': { id: 4610, name: 'The National Interest' },
      '4615': { id: 4615, name: 'Fox News' },
      '4623': { id: 4623, name: 'The Atlantic' },
      '4682': { id: 4682, name: 'The Hill' },
      '5126': { id: 5126, name: 'American Progress' },
      '5494': { id: 5494, name: 'ABC News' },
      '5702': { id: 5702, name: 'South China Morning Post' },
      '6611': { id: 6611, name: 'MercoPress' },
      '7408': { id: 7408, name: 'The Korea Herald' },
      '7427': { id: 7427, name: 'BBC' },
      '7598': { id: 7598, name: 'Pew Research Center' },
      '8222': { id: 8222, name: 'International Crisis Group' },
      '9142': { id: 9142, name: 'Long War Journal by FDD' },
      '9497': { id: 9497, name: 'Foreign Policy in Focus' },
      '9666': { id: 9666, name: 'New York Magazine' },
      '10372': { id: 10372, name: 'The Global Observatory' },
      '12325': { id: 12325, name: 'The Conversation' },
      '13202': { id: 13202, name: 'The Atlantic Council' },
      '14101': { id: 14101, name: 'TIME' },
      '16169': { id: 16169, name: 'Texas Public Policy Foundation' },
      '16637': { id: 16637, name: 'Vox' },
      '17707': { id: 17707, name: 'FiveThirtyEight' },
      '18163': { id: 18163, name: 'Chatham House' },
      '18283': { id: 18283, name: 'Wilson Center' }
    };
  };

  private _newsFeedPublicationsPicUrls() {
    return {
      '21': 'assets/prepd-shared/img/png/huffpost.png',
      '41': 'assets/prepd-shared/img/png/cnn.png',
      '90': 'assets/prepd-shared/img/png/al-jazeera.png',
      '108': 'assets/prepd-shared/img/png/toronto-star.png',
      '181': 'assets/prepd-shared/img/png/la-times.png',
      '363': 'assets/prepd-shared/img/png/the-moscow-times.png',
      '905': 'assets/prepd-shared/img/png/peterson-institute-for-international-economics.png',
      '987': 'assets/prepd-shared/img/png/china-daily.png',
      '3386': 'assets/prepd-shared/img/png/press-tv.png',
      '3443': 'assets/prepd-shared/img/png/the-guardian.png',
      '3444': 'assets/prepd-shared/img/png/christian-science-monitor.png',
      '3451': 'assets/prepd-shared/img/png/the-cato-institute.png',
      '3454': 'assets/prepd-shared/img/png/der-spiegel.png',
      '3462': 'assets/prepd-shared/img/png/reuters.png',
      '3533': 'assets/prepd-shared/img/png/politico.png',
      '3545': 'assets/prepd-shared/img/png/business-insider.png',
      '3547': 'assets/prepd-shared/img/png/houston-chronicle.png',
      '3691': 'assets/prepd-shared/img/png/all-africa.png',
      '3697': 'assets/prepd-shared/img/png/iol-cape-times.png',
      '3701': 'assets/prepd-shared/img/png/brookings-institution.png',
      '3707': 'assets/prepd-shared/img/png/npr.png',
      '3714': 'assets/prepd-shared/img/png/nbc-news.png',
      '3905': 'assets/prepd-shared/img/png/indian-express.png',
      '4122': 'assets/prepd-shared/img/png/the-jerusalem-post.png',
      '4262': 'assets/prepd-shared/img/png/wired.png',
      '4341': 'assets/prepd-shared/img/png/cnbc.png',
      '4395': 'assets/prepd-shared/img/png/sydney-morning-herald.png',
      '4562': 'assets/prepd-shared/img/png/council-on-foreign-relations.png',
      '4572': 'assets/prepd-shared/img/png/national-review.png',
      '4573': 'assets/prepd-shared/img/png/new-republic.png',
      '4610': 'assets/prepd-shared/img/png/the-national-interest.png',
      '4615': 'assets/prepd-shared/img/png/fox-news.png',
      '4623': 'assets/prepd-shared/img/png/the-atlantic.png',
      '4682': 'assets/prepd-shared/img/png/the-hill.png',
      '5126': 'assets/prepd-shared/img/png/american-progress.png',
      '5494': 'assets/prepd-shared/img/png/abc-news.png',
      '5702': 'assets/prepd-shared/img/png/south-china-morning-post.png',
      '6611': 'assets/prepd-shared/img/png/mercopress.png',
      '7408': 'assets/prepd-shared/img/png/the-korea-herald.png',
      '7427': 'assets/prepd-shared/img/png/bbc.png',
      '7598': 'assets/prepd-shared/img/png/pew-research-center.png',
      '8222': 'assets/prepd-shared/img/png/international-crisis-group.png',
      '9142': 'assets/prepd-shared/img/png/long-war-journal.png',
      '9497': 'assets/prepd-shared/img/png/foreign-policy-in-focus.png',
      '9666': 'assets/prepd-shared/img/png/new-york-magazine.png',
      '10372': 'assets/prepd-shared/img/png/the-global-observatory.png',
      '12325': 'assets/prepd-shared/img/png/the-conversation.png',
      '13202': 'assets/prepd-shared/img/png/the-atlantic-council.png',
      '14101': 'assets/prepd-shared/img/png/time.png',
      '16169': 'assets/prepd-shared/img/png/texas-public-policy-foundation.png',
      '16637': 'assets/prepd-shared/img/png/vox.png',
      '17707': 'assets/prepd-shared/img/png/fivethirtyeight.png',
      '18163': 'assets/prepd-shared/img/png/chatham-house.png',
      '18283': 'assets/prepd-shared/img/png/wilson-center.png'
    };
  };

  init() {
    this._store = {};

    this._store.connection = OFFLINE;
    this._store.isOffline = true;

    this._store.requests = 0;

    this._store.authenticated = false;

    this._store.user = {};

    this._store.teams = [];
    this._store.currentTeam = {};

    this._store.roles = {};

    this._store.firstTimeUse = {
      articleView: true,
      library: true,
      extemp: true,
      congress: true,
    };

    this._store.bannersState = {};
    this._store.challengeProgress = {};

    this._store.navMenuOpen = false;
    this._store.hasFiltersMenu = false;
    this._store.filtersMenuOpen = false;
    this._store.userMenuOpen = false;

    this._store.smartSearchOpen = false;
    this._store.newsFeedOpen = false;

    this._store.contributors = [];
    this._store.publications = [];

    // offline
    this._store.offline = {};

    this._store.offline.launcher = {};
    this._store.offline.syncStamps = {};
    this._store.offline.launcher.currentSyncStamp = {};
    this._store.offline.newsFeed = {};
    this._store.offline.launcher.options = {
      core: true,
      pdfs: true,
      newsFeed: true,
      library: true,
      extemp: true,
      congress: true
    };
    this._store.offline.launcher.counts = {
      pdfs: 0
    };

    // library
    this._store.library = {};

    this._store.library.fastCatch = {
      id: null
    };

    this._store.library.smartSearch = {};
    this._store.library.smartSearch.open = false;
    this._store.library.smartSearch.query = this._getLibrarySmartSearchDefaultQuery();
    this._store.library.smartSearch.queryTimeframe = null;
    this._store.library.smartSearch.timeframes = [{
      title: 'Before a certain date',
      type: 'before'
    }, {
      title: 'After a certain date',
      type: 'after'
    }];
    this._store.library.smartSearch.currentTimeframe = this._getLibrarySmartSearchDefaultTimeframe();
    this._store.library.smartSearch.articlesCurrentPage = 1;
    this._store.library.smartSearch.articlesTotalItems = 0;
    this._store.library.smartSearch.articlesMaxPages = 5;
    this._store.library.smartSearch.articlesItemsPerPage = 50;
    this._store.library.smartSearch.articlesIds = [];
    this._store.library.smartSearch.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.library.smartSearch.currentArticlesIds = [];
    this._store.library.smartSearch.articles = [];

    this._store.library.newsFeed = {};
    this._store.library.newsFeed.publications = this._getNewsFeedPublications();
    this._store.library.newsFeed.open = false;
    this._store.library.newsFeed.query = this._getLibraryNewsFeedDefaultQuery();
    this._store.library.newsFeed.queryTimeframe = null;
    this._store.library.newsFeed.timeframes = [{
      title: 'Before a certain date',
      type: 'before'
    }, {
      title: 'After a certain date',
      type: 'after'
    }];
    this._store.library.newsFeed.currentTimeframe = this._getLibraryNewsFeedDefaultTimeframe();
    this._store.library.newsFeed.articlesCurrentPage = 1;
    this._store.library.newsFeed.articlesTotalItems = 0;
    this._store.library.newsFeed.articlesMaxPages = 5;
    this._store.library.newsFeed.articlesItemsPerPage = 50;
    this._store.library.newsFeed.articlesIds = [];
    this._store.library.newsFeed.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.library.newsFeed.currentArticlesIds = [];
    this._store.library.newsFeed.articles = [];

    this._store.library.articleFeed = {};
    this._store.library.articleFeed.articlesOrderSorter = [{
      title: 'Most Recently Caught',
      type: 'recent-created'
    }, {
      title: 'Oldest Caught',
      type: 'oldest-created'
    }, {
      title: 'Most Recently Published',
      type: 'recent-published'
    }, {
      title: 'Oldest Published',
      type: 'oldest-published'
    }, {
      title: 'Alphabetically A-Z',
      type: 'atoz'
    }, {
      title: 'Alphabetically Z-A',
      type: 'ztoa'
    }];
    this._store.library.articleFeed.articlesCurrentOrderSorter = this._getLibraryArticleFeedArticlesDefaultOrderSorter();
    this._store.library.articleFeed.articlesReadFilter = [{
      title: 'Read & Unread',
      type: 'any'
    }, {
      title: 'Read',
      type: 'read'
    }, {
      title: 'Unread',
      type: 'unread'
    }];
    this._store.library.articleFeed.articlesCurrentReadFilter = this._getLibraryArticleFeedArticlesDefaultReadFilter();
    this._store.library.articleFeed.articlesShowFilter = [{
      title: 'All Articles',
      type: 'all'
    }, {
      title: 'Web Articles',
      type: 'text'
    }, {
      title: 'PDF Files',
      type: 'pdf'
    }];
    this._store.library.articleFeed.articlesCurrentShowFilter = this._getLibraryArticleFeedArticlesDefaultShowFilter();
    this._store.library.articleFeed.articlesContributorFilter = [{
      title: 'All contributors',
      type: 0
    }];
    this._store.library.articleFeed.articlesCurrentContributorFilter = this._getLibraryArticleFeedArticlesDefaultContributorFilter();
    this._store.library.articleFeed.articlesPublicationFilter = [{
      title: 'All publications',
      type: [0]
    }];
    this._store.library.articleFeed.articlesCurrentPublicationFilter = this._getLibraryArticleFeedArticlesDefaultPublicationFilter();
    this._store.library.articleFeed.articlesAppFilter = [{
      title: 'Any Prepd App',
      type: 'all'
    }, {
      title: 'Library Only',
      type: 'library-only'
    }, {
      title: 'Extemp',
      type: 'extemp'
    }, {
      title: 'Congress',
      type: 'congress'
    }];
    this._store.library.articleFeed.articlesCurrentAppFilter = this._getLibraryArticleFeedArticlesDefaultAppFilter();
    this._store.library.articleFeed.articlesCurrentPage = 1;
    this._store.library.articleFeed.articlesTotalItems = 0;
    this._store.library.articleFeed.articlesMaxPages = 3;
    this._store.library.articleFeed.articlesItemsPerPage = 50;
    this._store.library.articleFeed.articlesIds = [];
    this._store.library.articleFeed.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.library.articleFeed.currentArticlesIds = [];
    this._store.library.articleFeed.articles = [];

    this._store.library.memoryBank = {};
    this._store.library.memoryBank.pageView = 'list'; //  list/flashcard
    this._store.library.memoryBank.memoriesOrderSorter = [{
      title: 'Most Recent',
      type: 'recent'
    }, {
      title: 'Oldest First',
      type: 'oldest'
    }, {
      title: 'Random',
      type: 'random'
    }];
    this._store.library.memoryBank.memoriesCurrentOrderSorter = this._getLibraryMemoryBankDefaultOrderSorter();
    this._store.library.memoryBank.memoriesMasteredFilter = [{
      title: 'Mastered and studying',
      type: 0
    }, {
      title: 'Mastered',
      type: 1
    }, {
      title: 'Still studying',
      type: 2
    }];
    this._store.library.memoryBank.memoriesCurrentMasteredFilter = this._getLibraryMemoryBankDefaultMasteredFilter();
    this._store.library.memoryBank.memoriesContributorFilter = [{
      title: 'All memories',
      type: 0
    }, {
      title: 'My memories',
      type: 1
    }, {
      title: 'Team memories',
      type: 2
    }];
    this._store.library.memoryBank.memoriesCurrentContributorFilter = this._getLibraryMemoryBankDefaultContributorFilter();
    this._store.library.memoryBank.memoriesCurrentDateRangeFilter = {
      start: 1467284400000,
      end: new Date().setHours(23, 59, 59, 999)
    };
    this._store.library.memoryBank.memoriesSearchKeyword = '';
    this._store.library.memoryBank.memoriesCurrentTopicIdsFilter = [];

    this._store.library.memoryBank.memoriesCurrentPage = 1;
    this._store.library.memoryBank.memoriesTotalItems = 0;
    this._store.library.memoryBank.memoriesMaxPages = 3;
    this._store.library.memoryBank.memoriesItemsPerPage = 30;
    this._store.library.memoryBank.memoriesIds = [];
    this._store.library.memoryBank.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.library.memoryBank.currentMemoriesIds = [];
    this._store.library.memoryBank.memories = [];
    this._store.library.memoryBank.topicList = this._getLibraryMemoryBankTopicList();

    this._store.library.vocabulary = {};
    this._store.library.vocabulary.entriesOrderSorter = [{
      title: 'Most Recent',
      type: 'recent'
    }, {
      title: 'Oldest',
      type: 'oldest'
    }, {
      title: 'Alphabetically A-Z',
      type: 'atoz'
    }, {
      title: 'Alphabetically Z-A',
      type: 'ztoa'
    }];
    this._store.library.vocabulary.entriesCurrentOrderSorter = this._getLibraryVocabularyEntriesDefaultOrderSorter();
    this._store.library.vocabulary.entriesReviewFilter = [{
      title: 'All Vocabulary',
      type: 0
    },
    {
      title: 'My Vocabulary',
      type: 1
    },
    {
      title: 'Team Vocabulary',
      type: 2
    }];
    this._store.library.vocabulary.entriesCurrentReviewFilter = this._getLibraryVocabularyEntriesDefaultReviewFilter();
    this._store.library.vocabulary.entriesStatusFilter = [{
      title: 'Mastered and studying',
      type: 0
    }, {
      title: 'Mastered',
      type: 1
    }, {
      title: 'Studying',
      type: 2
    }];
    this._store.library.vocabulary.currentDateRangeFilter = {
      start: 0,
      end: new Date().setHours(23, 59, 59, 999)
    };
    this._store.library.vocabulary.entriesCurrentStatusFilter = this._getLibraryVocabularyEntriesDefaultStatusFilter();
    this._store.library.vocabulary.searchKeyword = '';
    this._store.library.vocabulary.entriesCurrentPage = 1;
    this._store.library.vocabulary.entriesTotalItems = 0;
    this._store.library.vocabulary.entriesMaxPages = 3;
    this._store.library.vocabulary.entriesItemsPerPage = 50;
    this._store.library.vocabulary.entriesIds = [];
    this._store.library.vocabulary.entriesCurrentSlice = {
      from: 0,
      to: 0
    };
    this._store.library.vocabulary.entriesCurrentIds = [];
    this._store.library.vocabulary.entries = [];

    // extemp
    this._store.extemp = {};

    this._store.extemp.smartSearch = {};
    this._store.extemp.smartSearch.open = false;
    this._store.extemp.smartSearch.query = this._getExtempSmartSearchDefaultQuery();
    this._store.extemp.smartSearch.queryTimeframe = null;
    this._store.extemp.smartSearch.timeframes = [{
      title: 'Before a certain date',
      type: 'before'
    }, {
      title: 'After a certain date',
      type: 'after'
    }];
    this._store.extemp.smartSearch.currentTimeframe = this._getExtempSmartSearchDefaultTimeframe();
    this._store.extemp.smartSearch.articlesCurrentPage = 1;
    this._store.extemp.smartSearch.articlesTotalItems = 0;
    this._store.extemp.smartSearch.articlesMaxPages = 5;
    this._store.extemp.smartSearch.articlesItemsPerPage = 50;
    this._store.extemp.smartSearch.articlesIds = [];
    this._store.extemp.smartSearch.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.extemp.smartSearch.currentArticlesIds = [];
    this._store.extemp.smartSearch.articles = [];

    this._store.extemp.newsFeed = {};
    this._store.extemp.newsFeed.publications = this._getNewsFeedPublications();
    this._store.extemp.newsFeed.open = false;
    this._store.extemp.newsFeed.query = this._getExtempNewsFeedDefaultQuery();
    this._store.extemp.newsFeed.queryTimeframe = null;
    this._store.extemp.newsFeed.timeframes = [{
      title: 'Before a certain date',
      type: 'before'
    }, {
      title: 'After a certain date',
      type: 'after'
    }];
    this._store.extemp.newsFeed.currentTimeframe = this._getExtempNewsFeedDefaultTimeframe();
    this._store.extemp.newsFeed.articlesCurrentPage = 1;
    this._store.extemp.newsFeed.articlesTotalItems = 0;
    this._store.extemp.newsFeed.articlesMaxPages = 5;
    this._store.extemp.newsFeed.articlesItemsPerPage = 50;
    this._store.extemp.newsFeed.articlesIds = [];
    this._store.extemp.newsFeed.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.extemp.newsFeed.currentArticlesIds = [];
    this._store.extemp.newsFeed.articles = [];

    this._store.extemp.folders = {};
    this._store.extemp.folders.folders = [];
    this._store.extemp.folders.lastFolderUpdate = 0;
    this._store.extemp.folders.categories = {};
    this._store.extemp.folders.filteredFolders = [];
    this._store.extemp.folders.folderCategoriesFilter = {
      filters: [],
      checked: {}
    };
    this._store.extemp.folders.articlesOrderSorter = [{
      title: 'Most Recently Caught',
      type: 'recent-created'
    }, {
      title: 'Oldest Caught',
      type: 'oldest-created'
    }, {
      title: 'Most Recently Published',
      type: 'recent-published'
    }, {
      title: 'Oldest Published',
      type: 'oldest-published'
    }, {
      title: 'Alphabetically A-Z',
      type: 'atoz'
    }, {
      title: 'Alphabetically Z-A',
      type: 'ztoa'
    }];
    this._store.extemp.folders.articlesCurrentOrderSorter = this._getExtempFoldersArticlesDefaultOrderSorter();
    this._store.extemp.folders.articlesReadFilter = [{
      title: 'Read & Unread',
      type: 'any'
    }, {
      title: 'Read',
      type: 'read'
    }, {
      title: 'Unread',
      type: 'unread'
    }];
    this._store.extemp.folders.articlesCurrentReadFilter = this._getExtempFoldersArticlesDefaultReadFilter();
    this._store.extemp.folders.articlesShowFilter = [{
      title: 'All Articles',
      type: 'all'
    }, {
      title: 'Web Articles',
      type: 'text'
    }, {
      title: 'PDF Files',
      type: 'pdf'
    }];
    this._store.extemp.folders.articlesCurrentShowFilter = this._getExtempFoldersArticlesDefaultShowFilter();
    this._store.extemp.folders.articlesContributorFilter = [{
      title: 'All contributors',
      type: 0
    }];
    this._store.extemp.folders.articlesCurrentContributorFilter = this._getExtempFoldersArticlesDefaultContributorFilter();
    this._store.extemp.folders.articlesPublicationFilter = [{
      title: 'All publications',
      type: [0]
    }];
    this._store.extemp.folders.articlesCurrentPublicationFilter = this._getExtempArticleFeedArticlesDefaultPublicationFilter();
    this._store.extemp.folders.foldersOrderSorter = [{
      title: 'Most Recent',
      type: 'recent'
    }, {
      title: 'Oldest',
      type: 'oldest'
    }, {
      title: 'Alphabetically A-Z',
      type: 'atoz'
    }, {
      title: 'Alphabetically Z-A',
      type: 'ztoa'
    }, {
      title: 'Smallest folders on top',
      type: 'sizeasc'
    }, {
      title: 'Largest folders on top',
      type: 'sizedesc'
    }];
    this._store.extemp.folders.inputFolders = '';

    this._store.extemp.folders.foldersCurrentOrderSorter = this._getExtempFoldersDefaultOrderSorter();
    this._store.extemp.folders.articlesCurrentPage = 1;
    this._store.extemp.folders.articlesTotalItems = 0;
    this._store.extemp.folders.articlesMaxPages = 3;
    this._store.extemp.folders.articlesItemsPerPage = 50;
    this._store.extemp.folders.articlesSearchOptions = [{
      title: 'Most Relevant',
      type: 'relevancy'
    }, {
      title: 'Most Recent',
      type: 'recency'
    }];
    this._store.extemp.folders.articlesCurrentSearchOptions = this._getExtempFoldersDefaultArticleSearchOption();
    this._store.extemp.folders.articles = [];
    this._store.extemp.folders.isArticlesMultiselect = false;
    this._store.extemp.folders.currentSelectedArticles = [];
    this._store.extemp.folders.currentSlice = {
      from: 0,
      to: 0
    };
    // this._store.extemp.folders.isMultiselect = false;
    this._store.extemp.folders.selectedFolders = {};

    // EXTEMP - Practice Questions
    this._store.extemp.practiceQ = {};
    this._store.extemp.practiceQ.drawQuestions = [];
    this._store.extemp.practiceQ.selectedQuestion = 0;
    this._store.extemp.practiceQ.difficulties = [];
    this._store.extemp.practiceQ.questionsCurrentPage = 1;
    this._store.extemp.practiceQ.questionsTotalItems = 0;
    this._store.extemp.practiceQ.questionsItemsPerPage = 9;
    this._store.extemp.practiceQ.questionsMaxPages = 3;
    this._store.extemp.practiceQ.questionsIds = [];
    this._store.extemp.practiceQ.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.extemp.practiceQ.currentQuestionsIds = [];
    this._store.extemp.practiceQ.questions = [];
    this._store.extemp.practiceQ.cardsState = {};
    this._store.extemp.practiceQ.cardsSelectionState = true;
    this._store.extemp.practiceQ.difficulties = [{
      title: 'Novice',
      value: 1
    },
    {
      title: 'Varsity',
      value: 2
    }];
    this._store.extemp.practiceQ.defaultDifficulties = [{
      title: 'All Difficulties',
      value: 0
    }];
    this._store.extemp.practiceQ.defaultTopics = [{
      title: 'All Topics',
      id: 0
    }];
    this._store.extemp.practiceQ.defaultSubtopics = [{
      title: 'All Subtopics',
      id: 0
    }];
    this._store.extemp.practiceQ.sortOptions = [{
      title: 'Most Recent',
      value: 'recent'
    },
    {
      title: 'Oldest',
      value: 'oldest'
    },
    {
      title: 'Most Popular',
      value: 'mostPopular'
    },
    {
      title: 'Least Popular',
      value: 'leastPopular'
    }];

    this._store.extemp.practiceQ.statusOptions = [
      {
        title: 'Any Status',
        value: 'NONE'
      },
      {
        title: 'Accepted - Active',
        value: 'ACTIVE'
      },
      {
        title: 'Accepted - Retired',
        value: 'RETIRED'
      },
      {
        title: 'Pending Review',
        value: 'REVIEW'
      },
      {
        title: 'Rejected',
        value: 'REJECTED'
      }];
    this._store.extemp.practiceQ.filters = {
      draw: {
        difficulty: this._store.extemp.practiceQ.defaultDifficulties[0],
        topic: this._store.extemp.practiceQ.defaultTopics[0],
        subtopic: this._store.extemp.practiceQ.defaultSubtopics[0]
      },
      submit: {
        difficulty: this._store.extemp.practiceQ.difficulties[0],
        topic: this._store.extemp.practiceQ.defaultTopics[0],
        subtopic: this._store.extemp.practiceQ.defaultSubtopics[0],
        anonymous: false
      },
      submitted: {
        difficulty: this._store.extemp.practiceQ.defaultDifficulties[0],
        topic: this._store.extemp.practiceQ.defaultTopics[0],
        subtopic: this._store.extemp.practiceQ.defaultSubtopics[0],
        sort: this._store.extemp.practiceQ.sortOptions[0],
        status: this._store.extemp.practiceQ.statusOptions[0]
      }
    };

    // congress
    this._store.congress = {};

    this._store.congress.smartSearch = {};
    this._store.congress.smartSearch.open = false;
    this._store.congress.smartSearch.query = this._getCongressSmartSearchDefaultQuery();
    this._store.congress.smartSearch.queryTimeframe = null;
    this._store.congress.smartSearch.timeframes = [{
      title: 'Before a certain date',
      type: 'before'
    }, {
      title: 'After a certain date',
      type: 'after'
    }];
    this._store.congress.smartSearch.currentTimeframe = this._getCongressSmartSearchDefaultTimeframe();
    this._store.congress.smartSearch.articlesCurrentPage = 1;
    this._store.congress.smartSearch.articlesTotalItems = 0;
    this._store.congress.smartSearch.articlesMaxPages = 5;
    this._store.congress.smartSearch.articlesItemsPerPage = 50;
    this._store.congress.smartSearch.articlesIds = [];
    this._store.congress.smartSearch.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.congress.smartSearch.currentArticlesIds = [];
    this._store.congress.smartSearch.articles = [];

    this._store.congress.newsFeed = {};
    this._store.congress.newsFeed.publications = this._getNewsFeedPublications();
    this._store.congress.newsFeed.open = false;
    this._store.congress.newsFeed.query = this._getCongressNewsFeedDefaultQuery();
    this._store.congress.newsFeed.queryTimeframe = null;
    this._store.congress.newsFeed.timeframes = [{
      title: 'Before a certain date',
      type: 'before'
    }, {
      title: 'After a certain date',
      type: 'after'
    }];
    this._store.congress.newsFeed.currentTimeframe = this._getCongressNewsFeedDefaultTimeframe();
    this._store.congress.newsFeed.articlesCurrentPage = 1;
    this._store.congress.newsFeed.articlesTotalItems = 0;
    this._store.congress.newsFeed.articlesMaxPages = 5;
    this._store.congress.newsFeed.articlesItemsPerPage = 50;
    this._store.congress.newsFeed.articlesIds = [];
    this._store.congress.newsFeed.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.congress.newsFeed.currentArticlesIds = [];
    this._store.congress.newsFeed.articles = [];

    this._store.congress.argumentsContainer = {};
    this._store.congress.argumentsContainer.arguments = [];
    this._store.congress.argumentsContainer.argumentsIds = [];
    this._store.congress.argumentsContainer.argumentsCurrentPage = 1;
    this._store.congress.argumentsContainer.argumentsTotalItems = 0;
    this._store.congress.argumentsContainer.argumentsMaxPages = 3;
    this._store.congress.argumentsContainer.argumentsItemsPerPage = 50;
    // this._store.congress.argumentsContainer.argumentsArgumentsIds = [];
    this._store.congress.argumentsContainer.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.congress.argumentsContainer.sideFilter = [{
      title: 'Show all',
      type: -1
    }, {
      title: 'Show affirmative',
      type: 1
    }, {
      title: 'Show neutral',
      type: 2
    }, {
      title: 'Show negative',
      type: 0
    }];
    this._store.congress.argumentsContainer.sort = [{
      title: 'Most recent',
      type: 'recent'
    }, {
      title: 'Oldest',
      type: 'oldest'
    }, {
      //   title: 'Most warrants',
      //   type: 'most-warrants'
      // }, {
      title: 'Alphabetically A-Z',
      type: 'atoz'
    }, {
      title: 'Alphabetically Z-A',
      type: 'ztoa'
    }];
    this._store.congress.dockets = {};
    this._store.congress.dockets.docket = {};
    this._store.congress.dockets.articles = {};
    this._store.congress.dockets.docketsIdsOrder = [];
    this._store.congress.dockets.docketsIds = [];
    this._store.congress.dockets.docketsOrderSorter = [{
      title: 'Most Recent',
      type: 'recent'
    }, {
      title: 'Oldest',
      type: 'oldest'
    }, {
      title: 'State',
      type: 'state'
    }, {
      title: 'Name',
      type: 'name'
      // }, {
      //   title: 'Popular',
      //   type: 'popular'
    }];
    this._store.congress.dockets.docketsCurrentOrderSorter = this._getCongressDocketsDefaultOrderSorter();
    this._store.congress.dockets.docketsSavedFilter = [{
      title: 'All Dockets',
      type: 'all'
    }, {
      title: 'Saved Dockets',
      type: 'saved'
    }, {
      title: 'Available Dockets',
      type: 'available'
    }];
    this._store.congress.dockets.docketsCurrentSavedFilter = this._getCongressDocketsDefaultSavedFilter();
    this._store.congress.dockets.docketsDateFilter = this._getCongressDocktesDateFilter();
    this._store.congress.dockets.docketsCurrentDateFilter = this._getCongressDocketsDefaultDateFilter();
    this._store.congress.dockets.docketsStatesFilter = [{
      title: 'All States',
      type: ''
    }, {
      title: 'Nationals',
      type: '-'
    }];
    this._store.congress.dockets.docketsCurrentStateFilter = this._getCongressDocketsDefaultStateFilter();
    this._store.congress.dockets.docketsCurrentPage = 1;
    this._store.congress.dockets.docketsTotalItems = 0;
    this._store.congress.dockets.docketsMaxPages = 3;
    this._store.congress.dockets.docketsItemsPerPage = 50;
    this._store.congress.dockets.docketsIds = [];
    this._store.congress.dockets.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.congress.dockets.currentDocketsIds = [];
    this._store.congress.dockets.dockets = [];

    this._store.congress.dockets.arguments = {};
    this._store.congress.dockets.arguments.currentSideFilter = this._getCongressArgumentsDefaultSideFilter();
    this._store.congress.dockets.arguments.currentSort = this._getCongressArgumentsDefaultSort();

    this._store.congress.dockets.articles.articlesCurrentPage = 1;
    this._store.congress.dockets.articles.articlesTotalItems = 0;
    this._store.congress.dockets.articles.articlesMaxPages = 3;
    this._store.congress.dockets.articles.articlesItemsPerPage = 50;
    this._store.congress.dockets.articles.articlesIds = [];
    this._store.congress.dockets.articles.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.congress.dockets.articles.currentArticlesIds = [];
    this._store.congress.dockets.articles.articles = [];
    this._store.congress.dockets.billsOrderSorter = [{
      title: 'Bill Number',
      type: 'bill'
    }, {
      title: 'Most Articles Saved',
      type: 'articles_saved'
    }, {
      title: 'Most Arguments Saved',
      type: 'arguments_saved'
    }, {
      title: 'Most Speeches Uploaded',
      type: 'speeches_uploaded'
    }, {
      title: 'Alphabetical A-Z',
      type: 'atoz'
    }, {
      title: 'Alphabetical Z-A',
      type: 'ztoa'
    }];
    this._store.congress.dockets.billsCurrentOrderSorter = this._getCongressBillsDefaultOrderSorter();
    this._store.congress.speeches = {};
    this._store.congress.speeches.sideFilter = [{
      title: 'Show all',
      type: -1
    }, {
      title: 'Show affirmative',
      type: 1
    }, {
      title: 'Show neutral',
      type: 2
    }, {
      title: 'Show negative',
      type: 0
    }];
    this._store.congress.speeches.authorFilter = [{
      title: 'All speeches',
      type: 'all'
    }, {
      title: 'By me',
      type: 'me'
    }, {
      title: 'By my team',
      type: 'team'
    }];
    this._store.congress.dockets.speeches = {};
    this._store.congress.dockets.speeches.currentSideFilter = this._getCongressDocketsDefaultSideFilter();
    this._store.congress.dockets.speeches.currentAuthorFilter = this._getCongressDocketsDefaultAuthorFilter();

    this._store.congress.dockets.speeches.speechesCurrentPage = 1;
    this._store.congress.dockets.speeches.speechesTotalItems = 0;
    this._store.congress.dockets.speeches.speechesMaxPages = 3;
    this._store.congress.dockets.speeches.speechesItemsPerPage = 50;
    this._store.congress.dockets.speeches.speechesIds = [];
    this._store.congress.dockets.speeches.currentSlice = {
      from: 0,
      to: 0
    };
    this._store.congress.dockets.speeches.currentSpeechesIds = [];
    this._store.congress.dockets.speeches.speeches = [];
    this._store.congress.dockets.speeches.speech = {};

    this._store.congress.dockets.pages = {
      billList: 'billList',
      billDetails: 'billDetails',
      default: 'default',
      docketDetails: 'docketDetails',
      docketList: 'docketList',
    }
    this._store.congress.dockets.currentPage = this._getCongressDocketsCurrentPage();
    this._store.congress.dockets.hasDockets = false;
    this._store.congress.dockets.billList = {};
    this._store.congress.dockets.billList.bills = [];
    this._store.congress.dockets.billList.currentOrderSorter = this._getCongressBillsDefaultOrderSorter();
    this._store.congress.dockets.billList.currentDocket = {};
    this._store.congress.dockets.billList.docket = {};
    this._store.congress.dockets.billList.dockets = [];
    this._store.congress.dockets.billList.filteredBills = [];
    this._store.congress.dockets.billList.inputText = '';
    this._store.congress.dockets.billDetails = {};
    this._store.congress.dockets.billDetails.bill = {};
    this._store.congress.dockets.billDetails.bills = [];
    this._store.congress.dockets.billDetails.docket = {};
    this._store.congress.dockets.billDetails.previousPage = '';
    this._store.congress.dockets.docketDetails = {};
    this._store.congress.dockets.docketDetails.currentOrderSorter = this._getCongressBillsDefaultOrderSorter();
    this._store.congress.dockets.docketDetails.bills = [];
    this._store.congress.dockets.docketDetails.docket = {};
    this._store.congress.dockets.docketDetails.filteredBills = [];
    this._store.congress.dockets.docketDetails.inputText = '';
    this._store.congress.dockets.docketList = {};
    this._store.congress.dockets.docketList.inputText = '';

    // article view
    this._store.articleView = {};

    // save to
    this._store.saveTo = {};

    this._store.saveTo.extemp = {};
    this._store.saveTo.extemp.sorts = [
      { type: 'recent', label: 'Recent' },
      { type: 'a-z', label: 'A-Z' }
    ];
    this._store.saveTo.extemp.currentSort = null;
    this._store.saveTo.extemp.categories = [];
    this._store.saveTo.extemp.folders = [];
    this._store.saveTo.extemp.currentFilter = null;

    this._store.saveTo.congress = {};
    this._store.saveTo.congress.dockets = [];
    this._store.saveTo.congress.currentFilter = null;
  }

  isAuthenticated(): Observable<any> {
    return of(this._store.authenticated);
  }

  select(path: string): Observable<any> {
    return this._store$
      .pipe(
        map((store) => {
          const slices = path.split('.');
          let i, slice, output = store;
  
          for (i = 0; i < slices.length; i += 1) {
            slice = slices[i];
            output = output[slice];
          }
  
          return output;
        }),
        distinctUntilChanged()
      );
  }

  dispatch(action: IAction): void {
    let output;

    switch (action.type) {
      case UPDATE_CONNECTION:
        this._store.connection = action.payload;
        this._store.isOffline = (action.payload === OFFLINE);
        break;
      case INCREMENT_REQUESTS:
        this._store.requests += 1;
        break;
      case DECREMENT_REQUESTS:
        this._store.requests -= 1;
        break;
      case UPDATE_AUTHENTICATED:
        this._store.authenticated = action.payload;
        break;
      case UPDATE_PROFILE:
        this._store.authenticated = true;
        this._store.user = this._getUser(action.payload);
        this._store.teams = this._getTeams(action.payload);
        this._store.currentTeam = this._getCurrentTeam(action.payload);
        this._store.roles = this._getRoles(action.payload);
        this._store.firstTimeUse = this._getFirstTimeUse(action.payload);
        this._store.bannersState = this._getBannersState();
        break;
      case UPDATE_CURRENT_TEAM:
        this._store.currentTeam = action.payload;
        // this._localStorageProvider.set('currentTeamId', action.payload.id);
        this._store.bannersState = this._getBannersState();
        break;
      case TOGGLE_NAV_MENU:
        this._store.navMenuOpen = !this._store.navMenuOpen;
        break;
      case UPDATE_HAS_FILTERS_MENU:
        this._store.hasFiltersMenu = action.payload;
        break;
      case TOGGLE_FILTERS_MENU:
        this._store.filtersMenuOpen = !this._store.filtersMenuOpen;
        break;
      case TOGGLE_USER_MENU:
        this._store.userMenuOpen = !this._store.userMenuOpen;
        break;
      case UPDATE_SMART_SEARCH_OPEN:
        this._store.smartSearchOpen = action.payload;
        break;
      case UPDATE_NEWS_FEED_OPEN:
        this._store.newsFeedOpen = action.payload;
        break;
      case UPDATE_CONTRIBUTORS:
        this._store.contributors = action.payload;
        break;
      case UPDATE_PUBLICATIONS:
        this._store.publications = action.payload;
        break;
      case UPDATE_FTU_OPTIONS:
        this._store.firstTimeUse = Object.assign(this._store.firstTimeUse, action.payload);
        break;
      case UPDATE_BANNER_STATE:
        this._setBannerState(action.payload.name, action.payload.state);
        break;
      case RESET_BANNERS:
        this._store.bannersState = this._resetBannersState();
        break;
      case UPDATE_CHALLENGE_PROGRESS:
        this._store.challengeProgress = action.payload;
        break;

      // offline
      // main
      case INIT_OL:
        this._store.offline.launcher.currentSyncStamp = this._getOfflineLauncherCurrentSyncStamp();
        break;
      case UPDATE_OL_SYNC_STAMPS:
        this._store.offline.syncStamps = action.payload;
        this._store.offline.launcher.currentSyncStamp = this._getOfflineLauncherCurrentSyncStamp();
        break;
      case UPDATE_OL_SYNC_STATUS:
        this._store.offline.launcher.currentSyncStamp = Object.assign(this._store.offline.launcher.currentSyncStamp, action.payload.stamps);
        break;
      case UPDATE_OL_SYNC_OPTION_PDFS:
        this._store.offline.launcher.options.pdfs = action.payload;
        break;
      case UPDATE_OL_SYNC_OPTION_NEWS_FEED:
        this._store.offline.launcher.options.newsFeed = action.payload;
        break;
      case UPDATE_OL_SYNC_OPTION_LIBRARY:
        this._store.offline.launcher.options.library = action.payload;
        break;
      case UPDATE_OL_SYNC_OPTION_EXTEMP:
        this._store.offline.launcher.options.extemp = action.payload;
        break;
      case UPDATE_OL_SYNC_OPTION_CONGRESS:
        this._store.offline.launcher.options.congress = action.payload;
        break;
      case UPDATE_OL_SYNC_COUNT_PDFS:
        this._store.offline.launcher.counts.pdfs = action.payload;
        break;

      // library
      // fast-catch
      case UPDATE_LFC_ARTICLE:
        this._store.library.fastCatch.id = action.payload;
        break;
      // smart search
      case TOGGLE_LSS_EDIT:
        this._store.library.smartSearch.open = !this._store.library.smartSearch.open;
        break;
      case RESET_LSS_QUERY:
        this._store.library.smartSearch.query = this._getLibrarySmartSearchDefaultQuery();
        this._store.library.smartSearch.currentTimeframe = this._getLibrarySmartSearchDefaultTimeframe();
        break;
      case UPDATE_LSS_QUERY:
        this._store.library.smartSearch.open = action.payload.open;

        if (this._needUpdateSmartSearch(action.payload.query, this._store.library.smartSearch)) {
          this._store.library.smartSearch.articlesCurrentPage = 1;
          this._store.library.smartSearch.articlesTotalItems = 0;
          this._store.library.smartSearch.articlesIds = [];
          this._store.library.smartSearch.currentSlice = {
            from: 0,
            to: 0
          };
          this._store.library.smartSearch.currentArticlesIds = [];
          this._store.library.smartSearch.articles = [];

          this._store.library.smartSearch.query = Object.assign({}, this._store.library.smartSearch.query, action.payload.query);
          this._store.library.smartSearch.queryTimeframe = this._store.library.smartSearch.currentTimeframe;

          this._updateNewsFeed(this._store.library.newsFeed, this._store.library.smartSearch);
        }
        break;
      case UPDATE_LSS_CURRENT_TIMEFRAME:
        this._store.library.smartSearch.currentTimeframe = action.payload;
        break;
      case UPDATE_LSS_ARTICLES_CURRENT_PAGE:
        this._store.library.smartSearch.articlesCurrentPage = action.payload;
        output = this._getLibrarySmartSearchCurrentArticlesIds();
        this._store.library.smartSearch.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.smartSearch.currentArticlesIds = output.ids;
        break;
      case UPDATE_LSS_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.library.smartSearch.articlesTotalItems = output.length;
        this._store.library.smartSearch.articlesIds = output;
        output = this._getLibrarySmartSearchCurrentArticlesIds();
        this._store.library.smartSearch.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.smartSearch.currentArticlesIds = output.ids;
        break;
      case UPDATE_LSS_ARTICLES_ARTICLES:
        this._store.library.smartSearch.articles = action.payload;
        break;
      case UPDATE_LSS_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.library.smartSearch.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.library.smartSearch.articles[output].summary = action.payload.summary;
        break;

      // news feed
      case RESET_LNF:
        this._store.library.newsFeed.articlesCurrentPage = 1;
        this._store.library.newsFeed.articlesTotalItems = 0;
        this._store.library.newsFeed.articlesIds = [];
        this._store.library.newsFeed.currentSlice = {
          from: 0,
          to: 0
        };
        this._store.library.newsFeed.currentArticlesIds = [];
        this._store.library.newsFeed.articles = [];
        break;
      case TOGGLE_LNF_EDIT:
        this._store.library.newsFeed.open = !this._store.library.newsFeed.open;
        break;
      case RESET_LNF_QUERY:
        this._store.library.newsFeed.query = this._getLibraryNewsFeedDefaultQuery();
        this._store.library.newsFeed.currentTimeframe = this._getLibraryNewsFeedDefaultTimeframe();
        break;
      case UPDATE_LNF_QUERY:
        this._store.library.newsFeed.open = action.payload.open;

        if (this._needUpdateNewsFeed(action.payload.query, this._store.library.newsFeed)) {
          this._store.library.newsFeed.articlesCurrentPage = 1;
          this._store.library.newsFeed.articlesTotalItems = 0;
          this._store.library.newsFeed.articlesIds = [];
          this._store.library.newsFeed.currentSlice = {
            from: 0,
            to: 0
          };
          this._store.library.newsFeed.currentArticlesIds = [];
          this._store.library.newsFeed.articles = [];

          this._store.library.newsFeed.query = Object.assign({}, this._store.library.newsFeed.query, action.payload.query);
          this._store.library.newsFeed.queryTimeframe = this._store.library.newsFeed.currentTimeframe;

          this._updateSmartSearch(this._store.library.smartSearch, this._store.library.newsFeed);
        }
        break;
      case UPDATE_LNF_CURRENT_TIMEFRAME:
        this._store.library.newsFeed.currentTimeframe = action.payload;
        break;
      case UPDATE_LNF_ARTICLES_CURRENT_PAGE:
        this._store.library.newsFeed.articlesCurrentPage = action.payload;
        output = this._getLibraryNewsFeedCurrentArticlesIds();
        this._store.library.newsFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.newsFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_LNF_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.library.newsFeed.articlesTotalItems = output.length;
        this._store.library.newsFeed.articlesIds = output;
        output = this._getLibraryNewsFeedCurrentArticlesIds();
        this._store.library.newsFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.newsFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_LNF_ARTICLES_ARTICLES:
        this._store.library.newsFeed.articles = action.payload;
        break;
      case UPDATE_LNF_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.library.newsFeed.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.library.newsFeed.articles[output].summary = action.payload.summary;
        break;

      case UPDATE_LAF_ARTICLES_CONTRIBUTOR_FILTER:
        this._store.library.articleFeed.articlesContributorFilter = this._createContributorFilter(this._store.contributors);
        break;
      case UPDATE_LAF_ARTICLES_PUBLICATION_FILTER:
        this._store.library.articleFeed.articlesPublicationFilter = this._createPublicationFilter(this._store.publications);
        break;
      case RESET_LAF_ARTICLES_FILTERS:
        this._store.library.articleFeed.articlesCurrentOrderSorter = this._getLibraryArticleFeedArticlesDefaultOrderSorter();
        this._store.library.articleFeed.articlesCurrentReadFilter = this._getLibraryArticleFeedArticlesDefaultReadFilter();
        this._store.library.articleFeed.articlesCurrentShowFilter = this._getLibraryArticleFeedArticlesDefaultShowFilter();
        this._store.library.articleFeed.articlesCurrentContributorFilter = this._getLibraryArticleFeedArticlesDefaultContributorFilter();
        this._store.library.articleFeed.articlesCurrentPublicationFilter = this._getLibraryArticleFeedArticlesDefaultPublicationFilter();
        this._store.library.articleFeed.articlesCurrentAppFilter = this._getLibraryArticleFeedArticlesDefaultAppFilter();
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_ORDER_SORTER:
        this._store.library.articleFeed.articlesCurrentOrderSorter = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_READ_FILTER:
        this._store.library.articleFeed.articlesCurrentReadFilter = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_SHOW_FILTER:
        this._store.library.articleFeed.articlesCurrentShowFilter = action.payload;
        break;
      case RESET_LMB_MEMORIES_FILTERS:
        this._store.library.memoryBank.memoriesCurrentOrderSorter = this._getLibraryMemoryBankDefaultOrderSorter();
        this._store.library.memoryBank.memoriesCurrentMasteredFilter = this._getLibraryMemoryBankDefaultMasteredFilter();
        this._store.library.memoryBank.memoriesCurrentContributorFilter = this._getLibraryMemoryBankDefaultContributorFilter();
        this._store.library.memoryBank.memoriesCurrentDateRangeFilter = { start: 1467284400000, end: new Date().setHours(23, 59, 59, 999) };
        this._store.library.memoryBank.memoriesCurrentTopicIdsFilter = [];
        break;
      case UPDATE_LMB_MEMORIES_CURRENT_ORDER_SORTER:
        this._store.library.memoryBank.memoriesCurrentOrderSorter = action.payload;
        break;
      case UPDATE_LMB_MEMORIES_CURRENT_MASTERED_FILTER:
        this._store.library.memoryBank.memoriesCurrentMasteredFilter = action.payload;
        break;
      case UPDATE_LMB_MEMORIES_CURRENT_CONTRIBUTOR_FILTER:
        this._store.library.memoryBank.memoriesCurrentContributorFilter = action.payload;
        break;
      case UPDATE_LMB_MEMORIES_CURRENT_DATE_FILTER:
        this._store.library.memoryBank.memoriesCurrentDateRangeFilter = action.payload;
        break;
      case UPDATE_LMB_MEMORIES_TOPICS_FILTER:
        this._store.library.memoryBank.memoriesCurrentTopicIdsFilter = action.payload;
        break;
      case UPDATE_LMB_MEMORIES_SEARCH_KEYWORD:
        this._store.library.memoryBank.memoriesSearchKeyword = action.payload;
        break;
      case UPDATE_LMB_PAGE_VIEW:
        this._store.library.memoryBank.pageView = action.payload;
        break;
      case UPDATE_LMB_MEMORIES_CURRENT_PAGE:
        this._store.library.memoryBank.memoriesCurrentPage = action.payload;
        output = this._getLibraryMemoryBankCurrentMemoriesIds();
        this._store.library.memoryBank.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.memoryBank.currentMemoriesIds = output.ids;
        break;
      case UPDATE_LMB_MEMORIES_IDS:
        output = action.payload;
        this._store.library.memoryBank.memoriesTotalItems = output.length;
        this._store.library.memoryBank.memoriesIds = output;
        output = this._getLibraryMemoryBankCurrentMemoriesIds();
        this._store.library.memoryBank.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.memoryBank.currentMemoriesIds = output.ids;
        break;
      case UPDATE_LMB_MEMORIES_DETAILS:
        this._store.library.memoryBank.memories = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER:
        this._store.library.articleFeed.articlesCurrentContributorFilter = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_PUBLICATION_FILTER:
        this._store.library.articleFeed.articlesCurrentPublicationFilter = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_APP_FILTER:
        this._store.library.articleFeed.articlesCurrentAppFilter = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_CURRENT_PAGE:
        this._store.library.articleFeed.articlesCurrentPage = action.payload;
        output = this._getLibraryArticleFeedCurrentArticlesIds();
        this._store.library.articleFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.articleFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_LAF_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.library.articleFeed.articlesTotalItems = output.length;
        this._store.library.articleFeed.articlesIds = output;
        output = this._getLibraryArticleFeedCurrentArticlesIds();
        this._store.library.articleFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.articleFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_LAF_ARTICLES_ARTICLES:
        this._store.library.articleFeed.articles = action.payload;
        break;
      case UPDATE_LAF_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.library.articleFeed.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.library.articleFeed.articles[output].summary = action.payload.summary;
        break;

      case RESET_LV_FILTERS:
        this._store.library.vocabulary.entriesCurrentOrderSorter = this._getLibraryVocabularyEntriesDefaultOrderSorter();
        this._store.library.vocabulary.entriesCurrentReviewFilter = this._getLibraryVocabularyEntriesDefaultReviewFilter();
        this._store.library.vocabulary.entriesCurrentStatusFilter = this._getLibraryVocabularyEntriesDefaultStatusFilter();
        break;
      case UPDATE_LV_CURRENT_ORDER_SORTER:
        this._store.library.vocabulary.entriesCurrentOrderSorter = action.payload;
        break;
      case UPDATE_LV_CURRENT_REVIEW_FILTER:
        this._store.library.vocabulary.entriesCurrentReviewFilter = action.payload;
        break;
      case UPDATE_LV_CURRENT_STATUS_FILTER:
        this._store.library.vocabulary.entriesCurrentStatusFilter = action.payload;
        break;
      case UPDATE_LV_CURRENT_PAGE:
        this._store.library.vocabulary.entriesCurrentPage = action.payload;
        output = this._getLibraryVocabularyEntriesCurrentIds();
        this._store.library.vocabulary.entriesCurrentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.vocabulary.entriesCurrentIds = output.ids;
        break;
      case UPDATE_LV_ENTRIES_IDS:
        output = action.payload;
        this._store.library.vocabulary.entriesTotalItems = output.length;
        this._store.library.vocabulary.entriesIds = output;
        output = this._getLibraryVocabularyEntriesCurrentIds();
        this._store.library.vocabulary.entriesCurrentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.library.vocabulary.entriesCurrentIds = output.ids;
        break;
      case UPDATE_LV_ENTRIES:
        this._store.library.vocabulary.entries = action.payload;
        break;
      case UPDATE_LV_CURRENT_SEARCH_KEYWORD:
        this._store.library.vocabulary.searchKeyword = action.payload;
        break;
      case UPDATE_LV_CURRENT_DATE_FILTER:
        this._store.library.vocabulary.currentDateRangeFilter = action.payload;
        break;
      // extemp
      // smart search
      case TOGGLE_ESS_EDIT:
        this._store.extemp.smartSearch.open = !this._store.extemp.smartSearch.open;
        break;
      case RESET_ESS_QUERY:
        this._store.extemp.smartSearch.query = this._getExtempSmartSearchDefaultQuery();
        this._store.extemp.smartSearch.currentTimeframe = this._getExtempSmartSearchDefaultTimeframe();
        break;
      case UPDATE_ESS_QUERY:
        this._store.extemp.smartSearch.open = action.payload.open;

        if (this._needUpdateSmartSearch(action.payload.query, this._store.extemp.smartSearch)) {
          this._store.extemp.smartSearch.articlesCurrentPage = 1;
          this._store.extemp.smartSearch.articlesTotalItems = 0;
          this._store.extemp.smartSearch.articlesIds = [];
          this._store.extemp.smartSearch.currentSlice = {
            from: 0,
            to: 0
          };
          this._store.extemp.smartSearch.currentArticlesIds = [];
          this._store.extemp.smartSearch.articles = [];

          this._store.extemp.smartSearch.query = Object.assign({}, this._store.extemp.smartSearch.query, action.payload.query);
          this._store.extemp.smartSearch.queryTimeframe = this._store.extemp.smartSearch.currentTimeframe;

          this._updateNewsFeed(this._store.extemp.newsFeed, this._store.extemp.smartSearch);
        }
        break;
      case UPDATE_ESS_CURRENT_TIMEFRAME:
        this._store.extemp.smartSearch.currentTimeframe = action.payload;
        break;
      case UPDATE_ESS_ARTICLES_CURRENT_PAGE:
        this._store.extemp.smartSearch.articlesCurrentPage = action.payload;
        output = this._getExtempSmartSearchCurrentArticlesIds();
        this._store.extemp.smartSearch.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.smartSearch.currentArticlesIds = output.ids;
        break;
      case UPDATE_ESS_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.extemp.smartSearch.articlesTotalItems = output.length;
        this._store.extemp.smartSearch.articlesIds = output;
        output = this._getExtempSmartSearchCurrentArticlesIds();
        this._store.extemp.smartSearch.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.smartSearch.currentArticlesIds = output.ids;
        break;
      case UPDATE_ESS_ARTICLES_ARTICLES:
        this._store.extemp.smartSearch.articles = action.payload;
        break;
      case UPDATE_ESS_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.extemp.smartSearch.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.extemp.smartSearch.articles[output].summary = action.payload.summary;
        break;

      // news feed
      case RESET_ENF:
        this._store.extemp.newsFeed.articlesCurrentPage = 1;
        this._store.extemp.newsFeed.articlesTotalItems = 0;
        this._store.extemp.newsFeed.articlesIds = [];
        this._store.extemp.newsFeed.currentSlice = {
          from: 0,
          to: 0
        };
        this._store.extemp.newsFeed.currentArticlesIds = [];
        this._store.extemp.newsFeed.articles = [];
        break;
      case TOGGLE_ENF_EDIT:
        this._store.extemp.newsFeed.open = !this._store.extemp.newsFeed.open;
        break;
      case RESET_ENF_QUERY:
        this._store.extemp.newsFeed.query = this._getExtempNewsFeedDefaultQuery();
        this._store.extemp.newsFeed.currentTimeframe = this._getExtempNewsFeedDefaultTimeframe();
        break;
      case UPDATE_ENF_QUERY:
        this._store.extemp.newsFeed.open = action.payload.open;

        if (this._needUpdateNewsFeed(action.payload.query, this._store.extemp.newsFeed)) {
          this._store.extemp.newsFeed.articlesCurrentPage = 1;
          this._store.extemp.newsFeed.articlesTotalItems = 0;
          this._store.extemp.newsFeed.articlesIds = [];
          this._store.extemp.newsFeed.currentSlice = {
            from: 0,
            to: 0
          };
          this._store.extemp.newsFeed.currentArticlesIds = [];
          this._store.extemp.newsFeed.articles = [];

          this._store.extemp.newsFeed.query = Object.assign({}, this._store.extemp.newsFeed.query, action.payload.query);
          this._store.extemp.newsFeed.queryTimeframe = this._store.extemp.newsFeed.currentTimeframe;

          this._updateSmartSearch(this._store.extemp.smartSearch, this._store.extemp.newsFeed);
        }
        break;
      case UPDATE_ENF_CURRENT_TIMEFRAME:
        this._store.extemp.newsFeed.currentTimeframe = action.payload;
        break;
      case UPDATE_ENF_ARTICLES_CURRENT_PAGE:
        this._store.extemp.newsFeed.articlesCurrentPage = action.payload;
        output = this._getExtempNewsFeedCurrentArticlesIds();
        this._store.extemp.newsFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.newsFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_ENF_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.extemp.newsFeed.articlesTotalItems = output.length;
        this._store.extemp.newsFeed.articlesIds = output;
        output = this._getExtempNewsFeedCurrentArticlesIds();
        this._store.extemp.newsFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.newsFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_ENF_ARTICLES_ARTICLES:
        this._store.extemp.newsFeed.articles = action.payload;
        break;
      case UPDATE_ENF_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.extemp.newsFeed.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.extemp.newsFeed.articles[output].summary = action.payload.summary;
        break;

      case UPDATE_E_FOLDERS_CATEGORIES:
        this._store.extemp.folders.categories = action.payload;
        break;
      case UPDATE_EF_ARTICLES_CONTRIBUTOR_FILTER:
        this._store.extemp.folders.articlesContributorFilter = this._createContributorFilter(this._store.contributors);
        break;
      case UPDATE_EF_ARTICLES_PUBLICATION_FILTER:
        this._store.extemp.folders.articlesPublicationFilter = this._createPublicationFilter(this._store.publications);
        break;
      case UPDATE_EF_ARTICLES_CURRENT_SEARCH_ORDER:
        this._store.extemp.folders.articlesCurrentSearchOptions = action.payload;
        break;
      case UPDATE_EF_FOLDERS_CURRENT_ORDER_SORTER:
        this._store.extemp.folders.foldersCurrentOrderSorter = action.payload;
        break;
      case RESET_EF_ARTICLES_FILTERS:
        this._store.extemp.folders.articlesCurrentOrderSorter = this._getExtempFoldersArticlesDefaultOrderSorter();
        this._store.extemp.folders.articlesCurrentReadFilter = this._getExtempFoldersArticlesDefaultReadFilter();
        this._store.extemp.folders.articlesCurrentShowFilter = this._getExtempFoldersArticlesDefaultShowFilter();
        this._store.extemp.folders.articlesCurrentContributorFilter = this._getExtempFoldersArticlesDefaultContributorFilter();
        break;
      case UPDATE_EF_ARTICLES_CURRENT_ORDER_SORTER:
        this._store.extemp.folders.articlesCurrentOrderSorter = action.payload;
        break;
      case UPDATE_EF_ARTICLES_CURRENT_READ_FILTER:
        this._store.extemp.folders.articlesCurrentReadFilter = action.payload;
        break;
      case UPDATE_EF_ARTICLES_CURRENT_SHOW_FILTER:
        this._store.extemp.folders.articlesCurrentShowFilter = action.payload;
        break;
      case UPDATE_EF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER:
        this._store.extemp.folders.articlesCurrentContributorFilter = action.payload;
        break;
      case UPDATE_EF_ARTICLES_CURRENT_PUBLICATION_FILTER:
        this._store.extemp.folders.articlesCurrentPublicationFilter = action.payload;
        break;
      case UPDATE_EF_ARTICLES_CURRENT_PAGE:
        this._store.extemp.folders.articlesCurrentPage = action.payload;
        output = this._getExtempFoldersCurrentArticlesIds();
        this._store.extemp.folders.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.folders.currentArticlesIds = output.ids;
        break;
      case UPDATE_EF_ARTICLES_ORDERED_IDS:
        this._store.extemp.folders.articlesOrderedIds = action.payload;
        break;
      case UPDATE_EF_ARTICLES_ARTICLES_IDS_CURRENT_FOLDER:
        this._store.extemp.folders.articleIdsCurrentFolder = action.payload;
        break;
      case UPDATE_EF_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.extemp.folders.articlesTotalItems = output.length;
        this._store.extemp.folders.articlesIds = output;
        output = this._getExtempFoldersCurrentArticlesIds();
        this._store.extemp.folders.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.folders.currentArticlesIds = output.ids;
        break;
      case UPDATE_EE_IS_ARTICLES_MULTISELECT:
        this._store.extemp.folders.isArticlesMultiselect = action.payload;
        break;
      case UPDATE_EF_ARTICLES_ARTICLES:
        this._store.extemp.folders.articles = action.payload;
        break;
      case UPDATE_EF_FOLDERS:
        this._store.extemp.folders.folders = action.payload.folders;
        this._store.extemp.folders.lastFolderUpdate = action.payload.newFolderUpdate;
        break;
      case UPDATE_EF_INPUT_FOLDERS:
        this._store.extemp.folders.inputFolders = action.payload;
        break;
      case UPDATE_EF_FILTERED_FOLDERS:
        this._store.extemp.folders.filteredFolders = action.payload;
        break;
      case UPDATE_EF_CATEGORIES_FILTER:
        this._store.extemp.folders.folderCategoriesFilter = action.payload;
        break;
      case UPDATE_EF_SELECTION:
        this._store.extemp.folders.selectedFolders = action.payload.selectedFolders;
        break;
      case UPDATE_EF_FOLDERS_MULTISELECT:
        this._store.extemp.folders.isMultiselect = action.payload.isMultiselect;
        break;
      case UPDATE_EE_SELECTED_ARTICLES:
        this._store.extemp.folders.currentSelectedArticles = action.payload;
        break;
      case UPDATE_EE_SELECT_ALL_ARTICLES:
        if (action.payload === 1) {
          let selectedArticles = [];
          for (let i = 0; i < this._store.extemp.folders.articles.length; i++) {
            selectedArticles.push(this._store.extemp.folders.articles[i].id);
          }
          this._store.extemp.folders.currentSelectedArticles = selectedArticles;
        } else {
          this._store.extemp.folders.currentSelectedArticles = [];
        }
        break;
      case UPDATE_EF_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.extemp.folders.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.extemp.folders.articles[output].summary = action.payload.summary;
        break;
      case UPDATE_EPQ_DRAW_QUESTIONS:
        this._store.extemp.practiceQ.drawQuestions = action.payload;
        break;
      case UPDATE_EPQ_SELECTED:
        this._store.extemp.practiceQ.selectedQuestion = action.payload;
        break;
      case UPDATE_EPQ_QUESTIONS_STATE:
        this._store.extemp.practiceQ.cardsState = action.payload.drawQuestionsState;
        this._store.extemp.practiceQ.cardsSelectionState = action.payload.canSelect;
        break;
      case RESET_EPQ:
        this._store.extemp.practiceQ.questionsCurrentPage = 1;
        this._store.extemp.practiceQ.questionsTotalItems = 0;
        this._store.extemp.practiceQ.questionsIds = [];
        this._store.extemp.practiceQ.currentSlice = {
          from: 0,
          to: 0
        };
        this._store.extemp.practiceQ.currentQuestionsIds = [];
        this._store.extemp.practiceQ.questions = [];
        break;
      case UPDATE_EPQ_QUESTIONS_IDS:
        output = action.payload;
        this._store.extemp.practiceQ.questionsTotalItems = output.length;
        this._store.extemp.practiceQ.questionsIds = output;
        output = this._getExtempPracticeQuestionsCurrentQuestionsIds();
        this._store.extemp.practiceQ.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.practiceQ.currentQuestionsIds = output.ids;
        break;
      case UPDATE_EPQ_QUESTIONS_QUESTIONS:
        this._store.extemp.practiceQ.questions = action.payload;
        break;
      case UPDATE_EPQ_CURRENT_PAGE:
        this._store.extemp.practiceQ.questionsCurrentPage = action.payload;
        output = this._getExtempPracticeQuestionsCurrentQuestionsIds();
        this._store.extemp.practiceQ.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.extemp.practiceQ.currentQuestionsIds = output.ids;
        break;
      case UPDATE_EPQ_QUESTIONS_STATE:
        this._store.extemp.practiceQ.cardsState = action.payload;
        break;
      case UPDATE_EPQ_FILTERS:
        this._store.extemp.practiceQ.filters = action.payload;
        break;

      // congress
      // smart search
      case TOGGLE_CSS_EDIT:
        this._store.congress.smartSearch.open = !this._store.congress.smartSearch.open;
        break;
      case RESET_CSS_QUERY:
        this._store.congress.smartSearch.query = this._getCongressSmartSearchDefaultQuery();
        this._store.congress.smartSearch.currentTimeframe = this._getCongressSmartSearchDefaultTimeframe();
        break;
      case UPDATE_CSS_QUERY:
        this._store.congress.smartSearch.open = action.payload.open;

        if (this._needUpdateSmartSearch(action.payload.query, this._store.congress.smartSearch)) {
          this._store.congress.smartSearch.articlesCurrentPage = 1;
          this._store.congress.smartSearch.articlesTotalItems = 0;
          this._store.congress.smartSearch.articlesIds = [];
          this._store.congress.smartSearch.currentSlice = {
            from: 0,
            to: 0
          };
          this._store.congress.smartSearch.currentArticlesIds = [];
          this._store.congress.smartSearch.articles = [];

          this._store.congress.smartSearch.query = Object.assign({}, this._store.congress.smartSearch.query, action.payload.query);
          this._store.congress.smartSearch.queryTimeframe = this._store.congress.smartSearch.currentTimeframe;

          this._updateNewsFeed(this._store.congress.newsFeed, this._store.congress.smartSearch);
        }
        break;
      case UPDATE_CSS_CURRENT_TIMEFRAME:
        this._store.congress.smartSearch.currentTimeframe = action.payload;
        break;
      case UPDATE_CSS_ARTICLES_CURRENT_PAGE:
        this._store.congress.smartSearch.articlesCurrentPage = action.payload;
        output = this._getCongressSmartSearchCurrentArticlesIds();
        this._store.congress.smartSearch.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.smartSearch.currentArticlesIds = output.ids;
        break;
      case UPDATE_CSS_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.congress.smartSearch.articlesTotalItems = output.length;
        this._store.congress.smartSearch.articlesIds = output;
        output = this._getCongressSmartSearchCurrentArticlesIds();
        this._store.congress.smartSearch.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.smartSearch.currentArticlesIds = output.ids;
        break;
      case UPDATE_CSS_ARTICLES_ARTICLES:
        this._store.congress.smartSearch.articles = action.payload;
        break;
      case UPDATE_CSS_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.congress.smartSearch.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.congress.smartSearch.articles[output].summary = action.payload.summary;
        break;

      // news feed
      case RESET_CNF:
        this._store.congress.newsFeed.articlesCurrentPage = 1;
        this._store.congress.newsFeed.articlesTotalItems = 0;
        this._store.congress.newsFeed.articlesIds = [];
        this._store.congress.newsFeed.currentSlice = {
          from: 0,
          to: 0
        };
        this._store.congress.newsFeed.currentArticlesIds = [];
        this._store.congress.newsFeed.articles = [];
        break;
      case TOGGLE_CNF_EDIT:
        this._store.congress.newsFeed.open = !this._store.congress.newsFeed.open;
        break;
      case RESET_CNF_QUERY:
        this._store.congress.newsFeed.query = this._getCongressNewsFeedDefaultQuery();
        this._store.congress.newsFeed.currentTimeframe = this._getCongressNewsFeedDefaultTimeframe();
        break;
      case UPDATE_CNF_QUERY:
        this._store.congress.newsFeed.open = action.payload.open;

        if (this._needUpdateNewsFeed(action.payload.query, this._store.congress.newsFeed)) {
          this._store.congress.newsFeed.articlesCurrentPage = 1;
          this._store.congress.newsFeed.articlesTotalItems = 0;
          this._store.congress.newsFeed.articlesIds = [];
          this._store.congress.newsFeed.currentSlice = {
            from: 0,
            to: 0
          };
          this._store.congress.newsFeed.currentArticlesIds = [];
          this._store.congress.newsFeed.articles = [];

          this._store.congress.newsFeed.query = Object.assign({}, this._store.congress.newsFeed.query, action.payload.query);
          this._store.congress.newsFeed.queryTimeframe = this._store.congress.newsFeed.currentTimeframe;
          this._store.congress.newsFeed.queryTimeframe = this._store.congress.newsFeed.currentTimeframe;

          this._updateSmartSearch(this._store.congress.smartSearch, this._store.congress.newsFeed);
        }
        break;
      case UPDATE_CNF_CURRENT_TIMEFRAME:
        this._store.congress.newsFeed.currentTimeframe = action.payload;
        break;
      case UPDATE_CNF_ARTICLES_CURRENT_PAGE:
        this._store.congress.newsFeed.articlesCurrentPage = action.payload;
        output = this._getCongressNewsFeedCurrentArticlesIds();
        this._store.congress.newsFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.newsFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_CNF_ARTICLES_ARTICLES_IDS:
        output = action.payload;
        this._store.congress.newsFeed.articlesTotalItems = output.length;
        this._store.congress.newsFeed.articlesIds = output;
        output = this._getCongressNewsFeedCurrentArticlesIds();
        this._store.congress.newsFeed.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.newsFeed.currentArticlesIds = output.ids;
        break;
      case UPDATE_CNF_ARTICLES_ARTICLES:
        this._store.congress.newsFeed.articles = action.payload;
        break;
      case UPDATE_CNF_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.congress.newsFeed.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.congress.newsFeed.articles[output].summary = action.payload.summary;
        break;

      case UPDATE_CD_CURRENT_PAGE_BILL_DETAILS:
        this._store.congress.dockets.currentPage = this._store.congress.dockets.pages.billDetails;
        this._store.congress.dockets.billDetails.bill = action.payload.bill;
        this._store.congress.dockets.billDetails.bills = action.payload.bills;
        this._store.congress.dockets.billDetails.docket = action.payload.docket;
        this._store.congress.dockets.billDetails.previousPage = action.payload.previousPage;
        break;
      case UPDATE_CD_CURRENT_PAGE_BILL_LIST:
        this._store.congress.dockets.currentPage = this._store.congress.dockets.pages.billList;
        break;
      case UPDATE_CD_CURRENT_PAGE_DOCKET_DETAILS:
        this._store.congress.dockets.currentPage = this._store.congress.dockets.pages.docketDetails;
        if (action.payload)
          this._store.congress.dockets.docketDetails.docket = action.payload.docket;
        break;
      case UPDATE_CD_CURRENT_PAGE_DOCKET_LIST:
        this._store.congress.dockets.currentPage = this._store.congress.dockets.pages.docketList;
        break;
      case UPDATE_CD_HAS_DOCKETS:
        this._store.congress.dockets.hasDockets = action.payload.hasDockets;
        break;

      case RESET_CDBL_FILTERS:
        this._store.congress.dockets.billList.currentOrderSorter = this._getCongressBillsDefaultOrderSorter();
      case UPDATE_CDBL_BILLS:
        this._store.congress.dockets.billList.bills = action.payload.bills;
        this._store.congress.dockets.billList.docket = action.payload.docket;
        break;
      case UPDATE_CDBL_BILLS_FILTERED:
        this._store.congress.dockets.billList.filteredBills = action.payload.filteredBills;
        break;
      case UPDATE_CDBL_CURRENT_DOCKET:
        this._store.congress.dockets.billList.currentDocket = action.payload.currentDocket;
        break;
      case UPDATE_CDBL_DOCKETS:
        this._store.congress.dockets.billList.dockets = action.payload.dockets;
        break;
      case UPDATE_CDBL_CURRENT_ORDER_SORTER:
        this._store.congress.dockets.billList.currentOrderSorter = action.payload.option;
        break;
      case UPDATE_CDBL_INPUT:
        this._store.congress.dockets.billList.inputText = action.payload.inputText;
        break;

      // case UPDATE_CDBD_BILL:
      //   this._store.congress.dockets.billDetails = action.payload.bill;
      //   break;

      case RESET_CDDD_FILTERS:
        this._store.congress.dockets.docketDetails.currentOrderSorter = this._getCongressBillsDefaultOrderSorter();
        break;
      case UPDATE_CDDD_BILLS:
        this._store.congress.dockets.docketDetails.bills = action.payload.bills;
        this._store.congress.dockets.docketDetails.docket = action.payload.docket;
        break;
      case UPDATE_CDDD_BILLS_FILTERED:
        this._store.congress.dockets.docketDetails.filteredBills = action.payload.filteredBills;
        break;
      case UPDATE_CDDD_CURRENT_ORDER_SORTER:
        this._store.congress.dockets.docketDetails.currentOrderSorter = action.payload.option;
        break;
      case UPDATE_CDDD_INPUT:
        this._store.congress.dockets.docketDetails.inputText = action.payload.inputText;
        break;

      case UPDATE_CDDL_INPUT:
        this._store.congress.dockets.docketList.inputText = action.payload.inputText;
        break;

      case UPDATE_CD_DOCKETS:
        this._store.congress.dockets.dockets = action.payload;
        break;
      case UPDATE_CD_DOCKETS_DOCKET:
        this._store.congress.dockets.docket[action.payload.docketId] = action.payload.docket;
        break;
      case UPDATE_CD_DOCKETS_DOCKETS_IDS_ORDER:
        this._store.congress.dockets.docketsIdsOrder = action.payload;
        break;
      case UPDATE_CD_DOCKETS_DOCKETS_IDS:
        this._store.congress.dockets.docketsIds = action.payload;
        output = action.payload;
        this._store.congress.dockets.docketsTotalItems = output.length;
        this._store.congress.dockets.docketsIds = output;
        output = this._getCongressDocketsCurrentDocketsIds();
        this._store.congress.dockets.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.dockets.currentDocketsIds = output.ids;
        break;
      case UPDATE_CD_DOCKETS_CURRENT_PAGE:
        this._store.congress.dockets.docketsCurrentPage = action.payload;
        output = this._getCongressDocketsCurrentDocketsIds();
        this._store.congress.dockets.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.dockets.currentDocketsIds = output.ids;
        break;
      case UPDATE_CD_ARTICLES_ARTICLE_SUMMARY:
        output = this._store.congress.dockets.articles.articles.indexOf(action.payload.article);
        if (output > -1)
          this._store.congress.dockets.articles.articles[output].summary = action.payload.summary;
        break;
      case UPDATE_CD_DOCKETS_CURRENT_ORDER_SORTER:
        this._store.congress.dockets.docketsCurrentOrderSorter = action.payload;
        break;
      case UPDATE_CD_DOCKETS_CURRENT_SAVED_FILTER:
        this._store.congress.dockets.docketsCurrentSavedFilter = action.payload;
        break;
      case UPDATE_CD_DOCKETS_CURRENT_STATE_FILTER:
        this._store.congress.dockets.docketsCurrentStateFilter = action.payload;
        break;
      case UPDATE_CD_DOCKETS_CURRENT_DATE_FILTER:
        this._store.congress.dockets.docketsCurrentDateFilter = action.payload;
        break;
      case UPDATE_CD_DOCKETS_STATES_FILTER:
        this._store.congress.dockets.docketsStatesFilter = this._createStatesFilter(action.payload);
        break;
      case RESET_CD_DOCKETS_FILTERS:
        this._store.congress.dockets.docketsCurrentOrderSorter = this._getCongressDocketsDefaultOrderSorter();
        this._store.congress.dockets.docketsCurrentSavedFilter = this._getCongressDocketsDefaultSavedFilter();
        this._store.congress.dockets.docketsCurrentStateFilter = this._getCongressDocketsDefaultStateFilter();
        this._store.congress.dockets.docketsCurrentDateFilter = this._getCongressDocketsDefaultDateFilter();
        break;

      case UPDATE_CDB_ARTICLE_IDS:
        output = action.payload;
        this._store.congress.dockets.articles.articlesTotalItems = output.length;
        this._store.congress.dockets.articles.articlesIds = output;
        output = this._getCongressBillsCurrentArticlesIds();
        this._store.congress.dockets.articles.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.dockets.articles.currentArticlesIds = output.ids;
        break;
      case UPDATE_CDB_ARTICLES_CURRENT_PAGE:
        this._store.congress.dockets.articles.articlesCurrentPage = action.payload;
        output = this._getCongressBillsCurrentArticlesIds();
        this._store.congress.dockets.articles.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.dockets.articles.currentArticlesIds = output.ids;
        break;
      case UPDATE_CDB_ARTICLES:
        this._store.congress.dockets.articles.articles = action.payload;
        break;
      case UPDATE_CDBB_SPEECHES_CURRENT_SIDE_FILTER:
        this._store.congress.dockets.speeches.currentSideFilter = action.payload;
        break;
      case UPDATE_CDBB_SPEECHES_CURRENT_AUTHOR_FILTER:
        this._store.congress.dockets.speeches.currentAuthorFilter = action.payload;
        break;
      case UPDATE_CDBB_SPEECHES:
        this._store.congress.dockets.speeches.speeches = action.payload;
        break;
      case UPDATE_CDBB_SPEECH_CONTENT:
        output = this._store.congress.dockets.speeches.speeches.indexOf(action.payload.speech);
        if (output > -1) {
          this._store.congress.dockets.speeches.speeches[output].content = action.payload.content;
          // if(this._store.congress.dockets.speeches.speeches[output].id === this._store.congress.dockets.speeches.speech.id)
          //   this._store.congress.dockets.speeches.speech.content = action.payload.content;
        }
        break;
      case UPDATE_CDBB_SPEECH:
        this._store.congress.dockets.speeches.speech = action.payload;
        break;
      case UPDATE_CDBB_SPEECHES_IDS:
        this._store.congress.dockets.speeches.speechesIds = action.payload;
        output = action.payload;
        this._store.congress.dockets.speeches.speechesTotalItems = output.length;
        this._store.congress.dockets.speeches.speechesIds = output;
        output = this._getCongressDocketsCurrentSpeechesIds();
        this._store.congress.dockets.speeches.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.dockets.speeches.currentSpeechesIds = output.ids;
        break;
      case UPDATE_CDBB_SPEECHES_CURRENT_PAGE:
        this._store.congress.dockets.speeches.speechesCurrentPage = action.payload;
        output = this._getCongressDocketsCurrentSpeechesIds();
        this._store.congress.dockets.speeches.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.dockets.speeches.currentSpeechesIds = output.ids;
        break;
      case UPDATE_CD_BILLS_CURRENT_ORDER_SORTER:
        this._store.congress.dockets.billsCurrentOrderSorter = action.payload;
        break;
      case RESET_CD_BILLS_FILTERS:
        this._store.congress.dockets.billsCurrentOrderSorter = this._getCongressBillsDefaultOrderSorter();
        break;

      case UPDATE_C_ARGUMENTS_CURRENT_PAGE:
        this._store.congress.argumentsContainer.argumentsCurrentPage = action.payload;
        output = this._getCongressArgumentsCurrentArgumentsIds();
        this._store.congress.argumentsContainer.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.argumentsContainer.currentArgumentsIds = output.ids;
        break;
      case UPDATE_C_ARGUMENTS_IDS:
        output = action.payload;
        this._store.congress.argumentsContainer.argumentsTotalItems = output.length;
        this._store.congress.argumentsContainer.argumentsIds = output;
        output = this._getCongressArgumentsCurrentArgumentsIds();
        this._store.congress.argumentsContainer.currentSlice = {
          from: output.begin,
          to: output.end
        };
        this._store.congress.argumentsContainer.currentArgumentsIds = output.ids;
        break;
      case UPDATE_C_ARGUMENTS:
        this._store.congress.argumentsContainer.arguments = action.payload;
        break;
      case UPDATE_C_ARGUMENT_CONTENT:
        output = this._store.congress.argumentsContainer.arguments.indexOf(action.payload.argument);
        if (output > -1) {
          this._store.congress.argumentsContainer.arguments[output].impact = action.payload.impact;
          this._store.congress.argumentsContainer.arguments[output].warrants = action.payload.warrants;
        }
        break;
      case UPDATE_C_ARGUMENTS_CURRENT_SIDE_FILTER:
        this._store.congress.dockets.arguments.currentSideFilter = action.payload;
        break;
      case UPDATE_C_ARGUMENTS_CURRENT_SORT:
        this._store.congress.dockets.arguments.currentSort = action.payload;
        break;



      // save to
      case INIT_ST_E_CATEGORIES:
        this._store.saveTo.extemp.categories = this._initCategories(action.payload);
        break;



      case INIT_ST_E_FOLDERS:
        this._store.saveTo.extemp = this._resetExtemp();
        this._store.saveTo.extemp.currentSort = this._getExtempCurrentSort();
        this._store.saveTo.extemp.folders = this._initFolders(action.payload);
        break;
      case UPDATE_ST_E_FOLDERS:
        this._store.saveTo.extemp.folders = this._updateFolders(action.payload);
        break;
      case CHANGE_ST_E_SORT:
        this._store.saveTo.extemp.currentSort = this._getExtempCurrentSort(action.payload);
        this._store.saveTo.extemp.folders = this._sortFolders();
        break;
      case CHANGE_ST_E_FILTER:
        this._store.saveTo.extemp.currentFilter = action.payload;
        break;
      case TOGGLE_ST_E_TOGGLED_FOLDER:
        this._store.saveTo.extemp.folders = this._toggleFolder(action.payload);
        break;
      case TOGGLE_ST_E_SELECTED_FOLDER:
        this._store.saveTo.extemp.folders = this._selectFolder(action.payload);
        break;
      case CREATE_ST_E_FOLDER:
        this._store.saveTo.extemp.folders = this._createFolder(action.payload);
        break;
      case DELETE_ST_E_FOLDER:
        this._store.saveTo.extemp.folders = this._deleteFolder(action.payload);
        break;
      case TOGGLE_ST_E_SELECTED_SUBFOLDER:
        this._store.saveTo.extemp.folders = this._selectSubfolder(action.payload);
        break;
      case CREATE_ST_E_SUBFOLDER:
        this._store.saveTo.extemp.folders = this._createSubfolder(action.payload);
        break;
      case DELETE_ST_E_SUBFOLDER:
        this._store.saveTo.extemp.folders = this._deleteSubfolder(action.payload);
        break;



      case INIT_ST_C_DOCKETS:
        this._store.saveTo.congress = this._resetCongress();
        this._store.saveTo.congress.dockets = this._initDockets(action.payload);
        break;
      case CHANGE_ST_C_FILTER:
        this._store.saveTo.congress.currentFilter = action.payload;
        break;
      case TOGGLE_ST_C_TOGGLED_DOCKET:
        this._store.saveTo.congress.dockets = this._toggleDocket(action.payload);
        break;
      case TOGGLE_ST_C_SELECTED_BILL:
        this._store.saveTo.congress.dockets = this._selectBill(action.payload);
        break;
    }

    // console.log(action.type, this._store$.observers.length);

    this._store$.next(this._store);
  }

  private _needUpdateSmartSearch(query, app) {
    const fields = ['any', 'all', 'exact', 'none', 'in', 'published', 'app', 'by'];

    let needsUpdate = false;
    fields.forEach((field) => {
      if ( (app.query[field] || query[field]) && query[field] !== app.query[field]) {
        needsUpdate = needsUpdate || true;
      }
    });

    needsUpdate = needsUpdate || app.currentTimeframe !== app.queryTimeframe;

    return needsUpdate;
  }

  private _updateSmartSearch(smartSearch, newsFeed) {
    const fields = ['all', 'any', 'exact', 'none', 'published', 'in'];
    fields.forEach((field) => {
      smartSearch.query[field] = newsFeed.query[field];
    });

    switch (newsFeed.currentTimeframe.type) {
      case 'before':
        smartSearch.currentTimeframe = smartSearch.timeframes[0];
        break;
      case 'after':
        smartSearch.currentTimeframe = smartSearch.timeframes[1];
        break;
    }
  }

  private _needUpdateNewsFeed(query, app) {
    const fields = ['any', 'all', 'exact', 'none', 'in', 'published', 'by'];

    let needsUpdate = false;
    fields.forEach((field) => {
      if ( (app.query[field] || query[field]) && query[field] !== app.query[field]) {
        needsUpdate = needsUpdate || true;
      }
    });

    needsUpdate = needsUpdate || app.currentTimeframe !== app.queryTimeframe;

    if (query.publications) {
      const length = query.publications.length;

      for (let i = 0; i < length; i += 1)
        if (query.publications[i].checked !== app.query.publications[i].checked)
          needsUpdate = needsUpdate || true;
    }

    return needsUpdate;
  }

  private _updateNewsFeed(newsFeed, smartSearch) {
    const fields = ['any', 'all', 'exact', 'none', 'in', 'published'];
    fields.forEach((field) => {
      newsFeed.query[field] = smartSearch.query[field];
    });

    switch (smartSearch.currentTimeframe.type) {
      case 'before':
        newsFeed.currentTimeframe = newsFeed.timeframes[0];
        break;
      case 'after':
        newsFeed.currentTimeframe = newsFeed.timeframes[1];
        break;
    }
  }

  private _getUser(profile) {
    const user = profile.user;

    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      pic: user.picId,
      picUrl: this._utilService.makePicUrl(+user.picId)
    };
  }

  private _getTeams(profile) {
    const teams = profile.teams;
    const teamsList = Object.keys(teams)
      .map((teamId) => {
        return {
          id: teams[teamId].id,
          name: teams[teamId].name,
          type: teams[teamId].type,
          readingExercise: teams[teamId].readingExercise
        };
      })
      .sort((a, b) => b.id - a.id)
      .sort((a, b) => a.type - b.type);

    return teamsList;
  }

  private _getBannersState() {
    const bannersState = this._localStorageProvider.get('bannersState') || {};
    if (bannersState[this._store.currentTeam.id] === undefined) {
      bannersState[this._store.currentTeam.id] = this._newBannersState();
      this._localStorageProvider.set('bannersState', bannersState);
    }

    return bannersState;
  }

  private _resetBannersState() {
    let state = {};
    state[this._store.currentTeam.id] = this._newBannersState();
    this._localStorageProvider.set('bannersState', state);
    return state;
  }

  private _setBannerState(bannerName: string, state: boolean) {
    let allBannersState = this._localStorageProvider.get('bannersState');
    allBannersState[this._store.currentTeam.id][bannerName] = state;

    this._localStorageProvider.set('bannersState', allBannersState);
    this._store.bannersState = allBannersState;
  }

  private _newBannersState() {
    return {
      upgradeFreeTeam: true,
      askCoachBanner: true,
      upgradeFreeOne: true,
      // renewProTeamCoach: true,
      // renewProTeamStudent: true,
      // renewProOne: true
      renewPremium: true,
      refer: true
    };
  }

  private _getCurrentTeam(profile) {
    // const lastCurrentTeamId = this._localStorageProvider.get('currentTeamId');
    const lastCurrentTeamId = profile.options.teamId;
    let lastCurrentTeam;

    if (lastCurrentTeamId) {
      let i, found = false;
      for (i = 0; i < this._store.teams.length; i += 1)
        if (lastCurrentTeamId === this._store.teams[i].id) {
          found = true;
          lastCurrentTeam = this._store.teams[i];
          break;
        }

      if (found)
        return lastCurrentTeam;
    }

    return this._store.teams[0];
  }

  private _getRoles(profile) {
    const roles = profile.roles;

    return roles;
  }

  private _getFirstTimeUse(profile) {
    const firstTimeUse = profile.firstTimeUse;

    if (!firstTimeUse)
      return {
        articleView: true,
        library: true,
        extemp: true,
        congress: true,
      };

    return firstTimeUse;
  }

  private _createContributorFilter(contributors) {
    const contributorFilter = [{
      title: 'All contributors',
      type: 0
    }];

    const sortedContributors = Object.keys(contributors)
      .map((key) => {
        const contributor = contributors[key];

        return {
          title: `${contributor.firstName} ${contributor.lastName}`,
          type: contributor.id
        };
      })
      .sort((firstContributor, secondContributor) => this._collator.compare(firstContributor.title, secondContributor.title));

    return contributorFilter.concat(sortedContributors);
  }

  private _createPublicationFilter(publications) {
    const publicationFilter = [{
      title: 'All publications',
      type: [0]
    }];

    const publicationsGrouped = Object.keys(publications)
      .reduce((filter, key) => {
        const publication = publications[key];

        if (!filter[ publication.name ])
          filter[ publication.name ] = {
            title: publication.name,
            type: [publications[key].id]
          };
        else
          filter[ publication.name ].type.push(publications[key].id);

        return filter;
      }, {})

    const sortedPublications = Object.keys(publicationsGrouped)
      .map((key) => publicationsGrouped[key])
      .sort((firstPublication, secondPublication) => this._collator.compare(firstPublication.title, secondPublication.title));

    return publicationFilter.concat(sortedPublications);
  }

  private _getSlice(currentPage: number, itemsPerPage: number, ids: number[]) {
    const start = ((currentPage - 1) * itemsPerPage);
    const end = start + itemsPerPage;
    const slice = ids.slice(start, end);
    return {
      begin: (slice.length) ? start + 1 : 0,
      end: (slice.length) ? start + slice.length : 0,
      ids: slice
    };
  }

  private _getMemoriesIdsSlice(currentPage: number, itemsPerPage: number, ids: number[]) {
    const start = ((currentPage - 1) * itemsPerPage);
    const end = start + itemsPerPage;
    const slice = ids.slice(start, end);
    return {
      begin: (slice.length) ? start + 1 : 0,
      end: (slice.length) ? start + slice.length : 0,
      ids: slice
    };
  }

  private _getNewsFeedPublications() {
    const publications = this.newsFeedPublications();
    const publicationsPicUrls = this._newsFeedPublicationsPicUrls();

    Object.keys(publications)
      .forEach((publicationId) => publications[publicationId].picUrl = publicationsPicUrls[publicationId]);

    return publications;
  }

  private _getNewsFeedPublicationsQuery() {
    const publications = this.newsFeedPublications();

    const sortedPublications = Object.keys(publications)
      .map((publicationId) => {
        publications[publicationId].checked = true;

        return publications[publicationId];
      })
      .sort((firstPublication, secondPublication) => this._collator.compare(firstPublication.name, secondPublication.name));

    return sortedPublications;
  }

  // offline
  private _getOfflineLauncherCurrentSyncStamp() {
    const newSyncStamp = {
      core: 0,
      pdfs: 0,
      newsFeed: 0,
      library: 0,
      extemp: 0,
      congress: 0
    };

    if (!this._store.offline.syncStamps[this._store.currentTeam.id])
      this._store.offline.syncStamps[this._store.currentTeam.id] = newSyncStamp;

    return this._store.offline.syncStamps[this._store.currentTeam.id];
  }

  // library
  // smart search
  private _getLibrarySmartSearchDefaultQuery() {
    return {
      any: '',
      all: '',
      exact: '',
      none: '',
      in: 'title-and-content',
      published: new Date('2013-01-01').getTime(),
      app: 'library',
      by: 'relevancy'
    };
  }

  private _getLibrarySmartSearchDefaultTimeframe() {
    return this._store.library.smartSearch.timeframes[1];
  }

  // news feed
  private _getLibraryNewsFeedDefaultQuery() {
    const publications = this._getNewsFeedPublicationsQuery();

    return {
      any: '',
      all: '',
      exact: '',
      none: '',
      in: 'title-and-content',
      published: new Date('2013-01-01').getTime(),
      publications,
      by: 'relevancy'
    };
  }

  private _getLibraryNewsFeedDefaultTimeframe() {
    return this._store.library.newsFeed.timeframes[1];
  }

  private _getLibraryArticleFeedArticlesDefaultOrderSorter() {
    return this._store.library.articleFeed.articlesOrderSorter[0];
  }
  private _getLibraryArticleFeedArticlesDefaultReadFilter() {
    return this._store.library.articleFeed.articlesReadFilter[0];
  }
  private _getLibraryArticleFeedArticlesDefaultShowFilter() {
    return this._store.library.articleFeed.articlesShowFilter[0];
  }
  private _getLibraryArticleFeedArticlesDefaultContributorFilter() {
    return this._store.library.articleFeed.articlesContributorFilter[0];
  }
  private _getLibraryArticleFeedArticlesDefaultPublicationFilter() {
    return this._store.library.articleFeed.articlesPublicationFilter[0];
  }
  private _getLibraryArticleFeedArticlesDefaultAppFilter() {
    return this._store.library.articleFeed.articlesAppFilter[0];
  }

  private _getLibraryMemoryBankDefaultOrderSorter() {
    return this._store.library.memoryBank.memoriesOrderSorter[0];
  }
  private _getLibraryMemoryBankDefaultMasteredFilter() {
    return this._store.library.memoryBank.memoriesMasteredFilter[0];
  }
  private _getLibraryMemoryBankDefaultContributorFilter() {
    return this._store.library.memoryBank.memoriesContributorFilter[0];
  }
  private _getLibraryMemoryBankDefaultTopicsFilter() {
    let topicIdsList = [];
    this._getLibraryMemoryBankTopicList().forEach(topic => {
      topic.subtopics.forEach(subtopic => {
        topicIdsList.push(subtopic.id);
      });
    });
    return topicIdsList;
  }

  private _getLibrarySmartSearchCurrentArticlesIds() {
    const currentPage = this._store.library.smartSearch.articlesCurrentPage;
    const itemsPerPage = this._store.library.smartSearch.articlesItemsPerPage;
    const articlesIds = this._store.library.smartSearch.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getLibraryNewsFeedCurrentArticlesIds() {
    const currentPage = this._store.library.newsFeed.articlesCurrentPage;
    const itemsPerPage = this._store.library.newsFeed.articlesItemsPerPage;
    const articlesIds = this._store.library.newsFeed.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getLibraryVocabularyEntriesCurrentIds() {
    const currentPage = this._store.library.vocabulary.entriesCurrentPage;
    const itemsPerPage = this._store.library.vocabulary.entriesItemsPerPage;
    const articlesIds = this._store.library.vocabulary.entriesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getLibraryVocabularyEntriesDefaultOrderSorter() {
    return this._store.library.vocabulary.entriesOrderSorter[0];
  }
  private _getLibraryVocabularyEntriesDefaultReviewFilter() {
    return this._store.library.vocabulary.entriesReviewFilter[0];
  }
  private _getLibraryVocabularyEntriesDefaultStatusFilter() {
    return this._store.library.vocabulary.entriesStatusFilter[0];
  }

  private _getLibraryArticleFeedCurrentArticlesIds() {
    const currentPage = this._store.library.articleFeed.articlesCurrentPage;
    const itemsPerPage = this._store.library.articleFeed.articlesItemsPerPage;
    const articlesIds = this._store.library.articleFeed.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getLibraryMemoryBankCurrentMemoriesIds() {
    const currentPage = this._store.library.memoryBank.memoriesCurrentPage;
    const itemsPerPage = this._store.library.memoryBank.memoriesItemsPerPage;
    const articlesIds = this._store.library.memoryBank.memoriesIds;

    return this._getMemoriesIdsSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getLibraryMemoryBankTopicList() {
    return [
      {
        id: 1,
        value: 'Domestic',
        subtopics: [
          {
            id: 3,
            parentTopic: 1,
            value: 'Domestic Politics'
          }, {
            id: 4,
            parentTopic: 1,
            value: 'National Security'
          }, {
            id: 5,
            parentTopic: 1,
            value: 'Foreign Policy'
          }, {
            id: 6,
            parentTopic: 1,
            value: 'Economy and Business'
          }, {
            id: 7,
            parentTopic: 1,
            value: 'Law and Courts'
          }, {
            id: 8,
            parentTopic: 1,
            value: 'Social and Culture'
          }, {
            id: 9,
            parentTopic: 1,
            value: 'Local and State'
          }
        ]
      }, {
        id: 2,
        value: 'Foreign',
        subtopics: [
          {
            id: 10,
            parentTopic: 2,
            value: 'Africa'
          }, {
            id: 11,
            parentTopic: 2,
            value: 'Americas'
          }, {
            id: 12,
            parentTopic: 2,
            value: 'Asia and Pacific'
          }, {
            id: 13,
            parentTopic: 2,
            value: 'Europe'
          }, {
            id: 14,
            parentTopic: 2,
            value: 'Middle East'
          }, {
            id: 15,
            parentTopic: 2,
            value: 'Global Economy'
          }, {
            id: 16,
            parentTopic: 2,
            value: 'International Orgs and Issues'
          }
        ]
      }
    ];
  }

  // extemp
  // smart search
  private _getExtempSmartSearchDefaultQuery() {
    return {
      any: '',
      all: '',
      exact: '',
      none: '',
      in: 'title-and-content',
      published: new Date('2013-01-01').getTime(),
      app: 'library',
      by: 'relevancy'
    };
  }

  private _getExtempSmartSearchDefaultTimeframe() {
    return this._store.extemp.smartSearch.timeframes[1];
  }

  // news feed
  private _getExtempNewsFeedDefaultQuery() {
    const publications = this._getNewsFeedPublicationsQuery();

    return {
      any: '',
      all: '',
      exact: '',
      none: '',
      in: 'title-and-content',
      published: new Date('2013-01-01').getTime(),
      publications,
      by: 'relevancy'
    };
  }

  private _getExtempNewsFeedDefaultTimeframe() {
    return this._store.extemp.newsFeed.timeframes[1];
  }

  private _getExtempFoldersArticlesDefaultOrderSorter() {
    return this._store.extemp.folders.articlesOrderSorter[0];
  }
  private _getExtempFoldersArticlesDefaultReadFilter() {
    return this._store.extemp.folders.articlesReadFilter[0];
  }
  private _getExtempFoldersArticlesDefaultShowFilter() {
    return this._store.extemp.folders.articlesShowFilter[0];
  }
  private _getExtempFoldersArticlesDefaultContributorFilter() {
    return this._store.extemp.folders.articlesContributorFilter[0];
  }
  private _getExtempArticleFeedArticlesDefaultPublicationFilter() {
    return this._store.extemp.folders.articlesPublicationFilter[0];
  }
  private _getExtempFoldersDefaultOrderSorter() {
    return this._store.extemp.folders.foldersOrderSorter[0] || {};
  }

  private _getExtempSmartSearchCurrentArticlesIds() {
    const currentPage = this._store.extemp.smartSearch.articlesCurrentPage;
    const itemsPerPage = this._store.extemp.smartSearch.articlesItemsPerPage;
    const articlesIds = this._store.extemp.smartSearch.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getExtempNewsFeedCurrentArticlesIds() {
    const currentPage = this._store.extemp.newsFeed.articlesCurrentPage;
    const itemsPerPage = this._store.extemp.newsFeed.articlesItemsPerPage;
    const articlesIds = this._store.extemp.newsFeed.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getExtempFoldersDefaultArticleSearchOption() {
    return this._store.extemp.folders.articlesSearchOptions[0] || {};
  }

  private _getExtempFoldersCurrentArticlesIds() {
    const currentPage = this._store.extemp.folders.articlesCurrentPage;
    const itemsPerPage = this._store.extemp.folders.articlesItemsPerPage;
    const articlesIds = this._store.extemp.folders.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getExtempPracticeQuestionsCurrentQuestionsIds() {
    const currentPage = this._store.extemp.practiceQ.questionsCurrentPage;
    const itemsPerPage = this._store.extemp.practiceQ.questionsItemsPerPage;
    const questionsIds = this._store.extemp.practiceQ.questionsIds;

    return this._getSlice(currentPage, itemsPerPage, questionsIds);
  }

  // congress
  private _getCongressSmartSearchDefaultQuery() {
    return {
      any: '',
      all: '',
      exact: '',
      none: '',
      in: 'title-and-content',
      published: new Date('2013-01-01').getTime(),
      app: 'library',
      by: 'relevancy'
    };
  }

  private _getCongressSmartSearchDefaultTimeframe() {
    return this._store.congress.smartSearch.timeframes[1];
  }

  // news feed
  private _getCongressNewsFeedDefaultQuery() {
    const publications = this._getNewsFeedPublicationsQuery();

    return {
      any: '',
      all: '',
      exact: '',
      none: '',
      in: 'title-and-content',
      published: new Date('2013-01-01').getTime(),
      publications,
      by: 'relevancy'
    };
  }

  private _getCongressNewsFeedDefaultTimeframe() {
    return this._store.congress.newsFeed.timeframes[1];
  }

  private _createStatesFilter(statesList) {
    const contributorFilter = [{
      title: 'All States',
      type: ''
    }];

    const sortedContributors = statesList
      .map((key) => {
        const states = this._utilService.getStates();
        states['-'] = {
          name: 'Nationals',
          abbreviation: '-'
        };

        const state = states[key];

        return {
          title: state.name,
          type: state.abbreviation
        };
      });

    return contributorFilter.concat(sortedContributors);
  }

  private _getCongressDocktesDateFilter(): any[] {
    const filter: any[] = [{
      title: 'All Years',
      type: {
        start: 0,
        end: undefined
      }
    }];

    const currentDate = new Date('Sep 30 2018');
    const endDate: Date = new Date( `Jul 01 ${currentDate.getMonth() < 6 ? currentDate.getFullYear() : currentDate.getFullYear() + 1 }`);

    for (let year = new Date('Jul 01 2015'); year.getTime() < endDate.getTime(); year.setFullYear( year.getFullYear()+1 )) {
      const nextYear = new Date( `Jul 01 ${year.getFullYear()+1}`);
      filter.unshift({
        title: `${year.getFullYear()} - ${nextYear.getFullYear()}`,
        type: {
          start: year.getTime(),
          end: nextYear.getTime()
        }
      });
    }

    return filter;
  }

  private _getCongressDocketsDefaultOrderSorter() {
    return this._store.congress.dockets.docketsOrderSorter[0];
  }

  private _getCongressDocketsDefaultSavedFilter() {
    return this._store.congress.dockets.docketsSavedFilter[0];
  }

  private _getCongressDocketsDefaultDateFilter() {
    return this._store.congress.dockets.docketsDateFilter[0];
  }

  private _getCongressBillsDefaultOrderSorter() {
    return this._store.congress.dockets.billsOrderSorter[0];
  }

  private _getCongressDocketsCurrentPage() {
    return this._store.congress.dockets.pages.default;
  }

  private _getCongressDocketsDefaultSideFilter() {
    return this._store.congress.speeches.sideFilter[0];
  }

  private _getCongressDocketsDefaultAuthorFilter() {
    return this._store.congress.speeches.authorFilter[0];
  }

  private _getCongressArgumentsDefaultSideFilter() {
    return this._store.congress.argumentsContainer.sideFilter[0];
  }

  private _getCongressArgumentsDefaultSort() {
    return this._store.congress.argumentsContainer.sort[0];
  }

  private _getCongressDocketsDefaultStateFilter() {
    return this._store.congress.dockets.docketsStatesFilter[0];
  }

  private _getCongressSmartSearchCurrentArticlesIds() {
    const currentPage = this._store.congress.smartSearch.articlesCurrentPage;
    const itemsPerPage = this._store.congress.smartSearch.articlesItemsPerPage;
    const articlesIds = this._store.congress.smartSearch.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getCongressNewsFeedCurrentArticlesIds() {
    const currentPage = this._store.congress.newsFeed.articlesCurrentPage;
    const itemsPerPage = this._store.congress.newsFeed.articlesItemsPerPage;
    const articlesIds = this._store.congress.newsFeed.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getCongressDocketsCurrentDocketsIds() {
    const currentPage = this._store.congress.dockets.docketsCurrentPage;
    const itemsPerPage = this._store.congress.dockets.docketsItemsPerPage;
    const articlesIds = this._store.congress.dockets.docketsIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getCongressBillsCurrentArticlesIds() {
    const currentPage = this._store.congress.dockets.articles.articlesCurrentPage;
    const itemsPerPage = this._store.congress.dockets.articles.articlesItemsPerPage;
    const articlesIds = this._store.congress.dockets.articles.articlesIds;

    return this._getSlice(currentPage, itemsPerPage, articlesIds);
  }

  private _getCongressDocketsCurrentSpeechesIds() {
    const currentPage = this._store.congress.dockets.speeches.speechesCurrentPage;
    const itemsPerPage = this._store.congress.dockets.speeches.speechesItemsPerPage;
    const speechesIds = this._store.congress.dockets.speeches.speechesIds;

    return this._getSlice(currentPage, itemsPerPage, speechesIds);
  }

  private _getCongressArgumentsCurrentArgumentsIds() {
    const currentPage = this._store.congress.argumentsContainer.argumentsCurrentPage;
    const itemsPerPage = this._store.congress.argumentsContainer.argumentsItemsPerPage;
    const speechesIds = this._store.congress.argumentsContainer.argumentsIds;

    return this._getSlice(currentPage, itemsPerPage, speechesIds);
  }



  // save to
  private _getExtempCurrentSort(payload?) {
    let currentSort = payload;

    if (!currentSort) {
      const lastSortType = this._localStorageProvider.get('extemp_folders');
      let lastSortPosition = this._store.saveTo.extemp.sorts
        .map((sort) => sort.type)
        .indexOf(lastSortType);

      if (lastSortPosition < 0)
        lastSortPosition = 0;

      currentSort = this._store.saveTo.extemp.sorts[lastSortPosition];
    }

    this._localStorageProvider.set('extemp_folders', currentSort.type);
    return currentSort;
  }

  private _initCategories(payload) {
    return Object.keys(payload)
      .map((categoryKey) => {
        const category = payload[categoryKey];

        return {
          id: category.id,
          name: category.name,
          label: category.label,
          color: category.color
        };
      })
      .sort((first, second) => this._collator.compare(first.name, second.name));
  }

  private _resetExtemp() {
    const emptyState = {
      folders: [],
      currentFilter: null,
    };

    return Object.assign({}, this._store.saveTo.extemp, emptyState);
  }

  private _initFolders(payload) {
    const currentSort = this._store.saveTo.extemp.currentSort;

    const categories = this._store.saveTo.extemp.categories
      .reduce((accumulatorCategories, currentCategory) => {
        accumulatorCategories[currentCategory.id] = currentCategory;

        return accumulatorCategories;
      }, {});

    const selectedFolders = payload.selectedFolders
      .reduce((accumulatedSelectedFolders, currentSelectedFolder) => {
        accumulatedSelectedFolders[currentSelectedFolder] = true;

        return accumulatedSelectedFolders;
      }, {});

    const folders = Object.keys(payload.folders)
      .map((folderKey) => {
        const folder = payload.folders[folderKey];

        let subfolders = Object.keys(folder.subfolders)
          .map((subfolderKey) => {
            const subfolder = folder.subfolders[subfolderKey];

            return {
              id: subfolder.id,
              name: subfolder.name,
              updated: subfolder.updated,
              selected: selectedFolders[subfolder.id] || false,
              canDelete: false
            };
          })

        switch (currentSort.type) {
          case 'recent':
            subfolders.sort((first, second) => second.updated - first.updated);
            break;
          case 'a-z':
            subfolders.sort((first, second) => this._collator.compare(first.name, second.name));
            break;
        }

        return {
          id: folder.id,
          name: folder.name,
          updated: folder.updated,
          category: Object.assign({}, categories[folder.category]),
          subfolders,
          toggled: false,
          selected: selectedFolders[folder.id] || false,
          canDelete: false
        };
      })

    switch (currentSort.type) {
      case 'recent':
        folders.sort((first, second) => second.updated - first.updated);
        break;
      case 'a-z':
        folders.sort((first, second) => this._collator.compare(first.name, second.name));
        break;
    }

    return folders;
  }

  private _sortFolders() {
    const currentSort = this._store.saveTo.extemp.currentSort;

    switch (currentSort.type) {
      case 'recent':
        return this._store.saveTo.extemp.folders
          .map((folder) => {
            const subfolders = folder.subfolders
              .sort((first, second) => second.updated - first.updated);
            return Object.assign({}, folder, { subfolders });
          })
          .sort((first, second) => second.updated - first.updated);
      case 'a-z':
        return this._store.saveTo.extemp.folders
          .map((folder) => {
            const subfolders = folder.subfolders
              .sort((first, second) => this._collator.compare(first.name, second.name));
            return Object.assign({}, folder, { subfolders });
          })
          .sort((first, second) => this._collator.compare(first.name, second.name));
    }
  }

  private _updateFolders(payload) {
    return this._store.saveTo.extemp.folders
      .map((folder) => {
        const updatedFolder = payload[folder.id];

        if (updatedFolder) {
          const subfolders = folder.subfolders
            .map((subfolder) => {
              const updatedSubfolder = updatedFolder.subfolders[subfolder.id];

              if (updatedSubfolder)
                return Object.assign({}, subfolder, { id: updatedSubfolder.id, canDelete: false });
              return subfolder;
            });
          return Object.assign({}, folder, { id: updatedFolder.id, canDelete: false, subfolders });
        }
        return folder;
      });
  }

  private _toggleFolder(payload) {
    return this._store.saveTo.extemp.folders
      .map((folder) => {
        if (folder.id === payload.id)
          return Object.assign({}, folder, { toggled: !folder.toggled });
        return folder;
      });
  }

  private _selectFolder(payload) {
    return this._store.saveTo.extemp.folders
      .map((folder) => {
        if (folder.id === payload.id)
          return Object.assign({}, folder, { selected: !folder.selected });
        return folder;
      });
  }

  private _createFolder(payload) {
    const currentSort = this._store.saveTo.extemp.currentSort;

    const folder = {
      id: this._generateId(),
      name: payload.name,
      updated: Date.now(),
      category: payload.category,
      subfolders: [],
      toggled: false,
      selected: payload.selected,
      canDelete: true
    };

    const folders = [...this._store.saveTo.extemp.folders, folder];

    switch (currentSort.type) {
      case 'recent':
        folders.sort((first, second) => second.updated - first.updated);
        break;
      case 'a-z':
        folders.sort((first, second) => this._collator.compare(first.name, second.name));
        break;
    }

    return folders;
  }

  private _deleteFolder(payload) {
    return this._store.saveTo.extemp.folders
      .filter((folder) => {
        return folder.id !== payload.id;
      });
  }

  private _selectSubfolder(payload) {
    return this._store.saveTo.extemp.folders
      .map((folder) => {
        if (folder.id === payload.folder.id) {
          const subfolders = folder.subfolders
            .map((subfolder) => {
              if (subfolder.id === payload.subfolder.id)
                return Object.assign({}, subfolder, { selected: !subfolder.selected });
              return subfolder;
            });
          return Object.assign({}, folder, { subfolders });
        }
        return folder;
      });
  }

  private _createSubfolder(payload) {
    const currentSort = this._store.saveTo.extemp.currentSort;

    const subfolder = {
      id: this._generateId(),
      name: payload.name,
      updated: Date.now(),
      selected: payload.selected,
      canDelete: true
    };

    const folders = this._store.saveTo.extemp.folders
      .map((folder) => {
        if (folder.id === payload.folder.id) {
          const subfolders = [...folder.subfolders, subfolder];

          switch (currentSort.type) {
            case 'recent':
              subfolders.sort((first, second) => second.updated - first.updated);
              break;
            case 'a-z':
              subfolders.sort((first, second) => this._collator.compare(first.name, second.name));
              break;
          }

          return Object.assign({}, folder, { updated: Date.now(), subfolders });
        }
        return folder;
      });

    switch (currentSort.type) {
      case 'recent':
        folders.sort((first, second) => second.updated - first.updated);
        break;
      case 'a-z':
        folders.sort((first, second) => this._collator.compare(first.name, second.name));
        break;
    }

    return folders;
  }

  private _deleteSubfolder(payload) {
    return this._store.saveTo.extemp.folders
      .map((folder) => {
        if (folder.id === payload.folder.id) {
          const subfolders = folder.subfolders
            .filter((subfolder) => {
              return subfolder.id !== payload.subfolder.id;
            });
          return Object.assign({}, folder, { updated: Date.now(), subfolders });
        }
        return folder;
      });
  }



  private _resetCongress() {
    const emptyState = {
      dockets: [],
      currentFilter: null,
    };

    return Object.assign({}, this._store.saveTo.congress, emptyState);
  }

  private _initDockets(payload) {
    const selectedBills = payload.selectedBills
      .reduce((accumulatedSelectedBills, currentSelectedBill) => {
        accumulatedSelectedBills[currentSelectedBill] = true;

        return accumulatedSelectedBills;
      }, {});

    return Object.keys(payload.dockets)
      .map((docketKey) => {
        const docket = payload.dockets[docketKey];

        const bills = Object.keys(docket.bills)
          .map((billKey) => {
            const bill = docket.bills[billKey];
            const title = `${bill.billNo}. ${bill.title}`;

            return {
              id: bill.id,
              no: bill.billNo,
              title,
              selected: selectedBills[bill.id] || false
            };
          })
          .sort((first, second) => this._collator.compare(first.no, second.no));

        return {
          id: docket.id,
          title: docket.title,
          bills,
          toggled: false
        };
      })
      .sort((first, second) => this._collator.compare(first.title, second.title));
  }

  private _toggleDocket(payload) {
    return this._store.saveTo.congress.dockets
      .map((docket) => {
        if (docket.id === payload.id)
          return Object.assign({}, docket, { toggled: !docket.toggled });
        return docket;
      });
  }

  private _selectBill(payload) {
    return this._store.saveTo.congress.dockets
      .map((docket) => {
        if (docket.id === payload.docket.id) {
          const bills = docket.bills
            .map((bill) => {
              if (bill.id === payload.bill.id)
                return Object.assign({}, bill, { selected: !bill.selected });
              return bill;
            });
          return Object.assign({}, docket, { bills });
        }
        return docket;
      });
  }



  private _generateId() {
    this._id -= 1;
    return this._id;
  }
}
