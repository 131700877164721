import {
  Directive,
  HostListener
} from '@angular/core';

import { DropdownComponent } from './dropdown/dropdown.component';

@Directive({
  selector: '[pp-dropdown-toggle]'
})
export class DropdownToggleDirective {
  constructor(private _ppDropdown: DropdownComponent) {}

  @HostListener('click', ['$event'])
  toggleOpen($event: MouseEvent) {
    $event.stopPropagation();

    this._ppDropdown.toggleDropdown();
  }
}