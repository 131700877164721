import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'input[type=radio][pp-input-radio]',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent implements OnInit {
  @Input('inputRadioFor') for: string;
  @Input('inputRadioSize') size: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) {}

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.size)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.size}`);
  }
}
