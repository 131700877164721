import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pp-spinner',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input('spinnerFor') for: string;
  @Input('spinnerSize') size: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) {}

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.size)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.size}`);
  }
}
