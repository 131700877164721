import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UlComponent } from './ul.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [UlComponent],
  exports: [UlComponent]
})
export class UlModule { }
