import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import {
  INCREMENT_REQUESTS,
  DECREMENT_REQUESTS
} from '../models/constants';

import { Routes } from '../models/routes';

import { DataService } from './data.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private _sessionIdentifier = 'unk';

  constructor(
    private _httpClient: HttpClient,
    private _dataProvider: DataService,
    private _utilProvider: UtilService
  ) { }

  set sessionIdentifier(sessionIdentifier: string) {
    this._sessionIdentifier = sessionIdentifier;
  }

  getConnectionStatus(): Observable<any> {
    const requestOptionsArgs: any = {};

    return this._get(Routes.getBase, requestOptionsArgs, false);
  }

  getCookie(): Observable<any> {
    return this._get(Routes.getCookie);
  }

  setCookie(): Observable<any> {
    return this._get(Routes.setCookie);
  }

  getProfile(): Observable<any> {
    return this._get(Routes.profile);
  }

  getLogin(app: string, email: string, password: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', this._utilProvider.makeBasicAuthentication(email, password));

    let params = new HttpParams();
    params = params.set('app', app);

    const withCredentials = true;

    const requestOptionsArgs: any = {
      headers,
      params,
      withCredentials
    };

    return this._get(Routes.login, requestOptionsArgs);
  }

  getLogout(): Observable<any> {
    return this._get(Routes.logout);
  }

  getLibraryWsToken(): Observable<any> {
    return this._get(Routes.libraryWsToken);
  }

  getExtempWsToken(): Observable<any> {
    return this._get(Routes.extempWsToken);
  }

  getCongressWsToken(): Observable<any> {
    return this._get(Routes.congressWsToken);
  }

  getLibrarySessionIdentifier(): Observable<any> {
    return this._get(Routes.librarySessionIdentifier);
  }

  getExtempSessionIdentifier(): Observable<any> {
    return this._get(Routes.extempSessionIdentifier);
  }

  getCongressSessionIdentifier(): Observable<any> {
    return this._get(Routes.congressSessionIdentifier);
  }

  getMarkEvent(identifier: string, number: number) {
    return this._get(`${Routes.markEvent}/${identifier}/${number}`);
  }

  postResetPassword(dataToSend): Observable<any> {
    return this._post(Routes.resetPassword, dataToSend);
  }

  postUserOptions(dataToSend): Observable<any> {
    return this._post(Routes.userOptions, dataToSend);
  }
  
  postChangeTeam(dataToSend): Observable<any> {
    return this._post(Routes.changeDefaultTeam, dataToSend);
  }

  postContributors(dataToSend): Observable<any> {
    return this._post(Routes.contributors, dataToSend);
  }

  postPublications(dataToSend): Observable<any> {
    return this._post(Routes.publications, dataToSend);
  }

  postArticleViewArticle(dataToSend): Observable<any> {
    return this._post(Routes.articleViewArticle, dataToSend);
  }

  postArticleViewCreateHighlight(dataToSend): Observable<any> {
    return this._post(Routes.articleViewCreateHighlight, dataToSend);
  }

  postArticleViewDeleteHighlight(dataToSend): Observable<any> {
    return this._post(Routes.articleViewDeleteHighlight, dataToSend);
  }

  postNewsFeedArticleViewArticle(dataToSend): Observable<any> {
    return this._post(Routes.newsFeedArticleViewArticle, dataToSend);
  }

  postSaveToLibrary(dataToSend): Observable<any> {
    return this._post(Routes.saveToLibrary, dataToSend);
  }

  postSaveToExtemp(dataToSend): Observable<any> {
    return this._post(Routes.saveToExtemp, dataToSend);
  }

  postSaveToCongress(dataToSend): Observable<any> {
    return this._post(Routes.saveToCongress, dataToSend);
  }

  postExtempCreateFolders(dataToSend): Observable<any> {
    return this._post(Routes.createExtempFolders, dataToSend);
  }

  postArticleViewWordDefinition(dataToSend): Observable<any> {
    return this._post(Routes.articleViewCreateWord, dataToSend);
  }

  postLibraryDeleteVocabulary(dataToSend): Observable<any> {
    return this._post(Routes.deleteVocabulary, dataToSend);
  }

  postMarkAsReadArgumentAnalysis(dataToSend): Observable<any> {
    return this._post(Routes.markAsReadArgumentAnalysis, dataToSend);
  }

  postMarkAsReadPracticeQuestion(dataToSend): Observable<any> {
    return this._post(Routes.markAsReadPracticeQuestion, dataToSend);
  }

  postMarkAsReadHowWhatWhereWhenWhy(dataToSend): Observable<any> {
    return this._post(Routes.markAsReadHowWhatWhereWhenWhy, dataToSend);
  }

  postMarkAsReadSignificance(dataToSend): Observable<any> {
    return this._post(Routes.markAsReadSignificance, dataToSend);
  }

  postMarkAsReadSummary(dataToSend): Observable<any> {
    return this._post(Routes.markAsReadSummary, dataToSend);
  }

  postMarkAsReadThreeFacts(dataToSend): Observable<any> {
    return this._post(Routes.markAsReadThreeFacts, dataToSend);
  }

  postArticleViewMarkAsRead(dataToSend): Observable<any> {
    return this._post(Routes.articleViewMarkAsRead, dataToSend);
  }

  postSaveArgument(dataToSend): Observable<any> {
    return this._post(Routes.saveArgument, dataToSend);
  }

  postArgumentShrinkedList(dataToSend): Observable<any> {
    return this._post(Routes.argumentShrinkedList, dataToSend);
  }

  postArgumentDetails(dataToSend): Observable<any> {
    return this._post(Routes.argumentDetails, dataToSend);
  }

  postArgumentDelete(dataToSend): Observable<any> {
    return this._post(Routes.argumentDelete, dataToSend);
  }

  postCoreTeamOptions(dataToSend): Observable<any> {
    return this._post(Routes.coreTeamOptions, dataToSend);
  }

  postSmartSearch(dataToSend): Observable<any> {
    return this._post(Routes.smartSearch, dataToSend);
  }

  postNewsFeed(dataToSend): Observable<any> {
    return this._post(Routes.newsFeed, dataToSend);
  }

  postNewsFeedArticlesDetails(dataToSend): Observable<any> {
    return this._post(Routes.newsFeedArticlesDetails, dataToSend);
  }

  postRoundStatistics(dataToSend): Observable<any> {
    return this._post(Routes.roundStatistics, dataToSend);
  }

  postFullSync(dataToSend): Observable<any> {
    return this._post(Routes.fullSync, dataToSend);
  }

  postPartialSync(dataToSend): Observable<any> {
    return this._post(Routes.partialSync, dataToSend);
  }

  postCountPdfs(dataToSend): Observable<any> {
    return this._post(Routes.countPdfs, dataToSend);
  }

  postEditArticle(dataToSend): Observable<any> {
    return this._post(Routes.editArticle, dataToSend);
  }

  postFlagArticle(dataToSend): Observable<any> {
    return this._post(Routes.flagArticle, dataToSend);
  }

  postDeleteArticle(dataToSend): Observable<any> {
    return this._post(Routes.deleteArticle, dataToSend);
  }

  postArticlesIds(dataToSend): Observable<any> {
    return this._post(Routes.articlesIds, dataToSend);
  }

  postExtempFolders(dataToSend): Observable<any> {
    return this._post(Routes.extempFolders, dataToSend);
  }

  postCongressDockets(dataToSend): Observable<any> {
    return this._post(Routes.congressDockets, dataToSend);
  }

  postUploadPdf(dataToSend): Observable<any> {
    return this._postFormData(Routes.uploadPdf, dataToSend);
  }

  postAskCoachToUpgrade(dataToSend): Observable<any> {
    return this._post(Routes.askCoachToUpgrade, dataToSend);
  }

  postSubscriptionEndDate(dataToSend): Observable<any> {
    return this._post(Routes.postSubscriptionEndDate, dataToSend);
  }

  // library api
  postLibraryArticleFeedArticlesIds(dataToSend): Observable<any> {
    return this._post(Routes.libraryArticleFeedArticlesIds, dataToSend);
  }

  postLibraryArticleFeedArticlesDetails(dataToSend): Observable<any> {
    return this._post(Routes.libraryArticleFeedArticlesDetails, dataToSend);
  }

  postLibraryArticleFeedArticleSummary(dataToSend): Observable<any> {
    return this._post(Routes.libraryArticleFeedArticleSummary, dataToSend);
  }

  postLibraryTopicList(): Observable<any> {
    return this._post(Routes.libraryTopicList, {});
  }

  postLibraryCreateMemory(dataToSend): Observable<any> {
    return this._post(Routes.createMemory, dataToSend);
  }

  postLibraryEditMemory(dataToSend): Observable<any> {
    return this._post(Routes.editMemory, dataToSend);
  }

  postLibraryMemoryUpdateMastered(dataToSend): Observable<any> {
    return this._post(Routes.memoryUpdateMastered, dataToSend);
  }

  postLibraryDeleteMemory(dataToSend): Observable<any> {
    return this._post(Routes.deleteMemory, dataToSend);
  }

  postLibraryMemoriesIds(dataToSend): Observable<any> {
    return this._post(Routes.listMemoriesIds, dataToSend);
  }

  postLibraryMemoriesDetails(dataToSend): Observable<any> {
    return this._post(Routes.memoryDetails, dataToSend);
  }

  postLibraryVocabularyEntriesIds(dataToSend): Observable<any> {
    return this._post(Routes.libraryVocabularyEntriesIds, dataToSend);
  }

  postLibraryVocabularyEntriesDetails(dataToSend): Observable<any> {
    return this._post(Routes.libraryVocabularyEntriesDetails, dataToSend);
  }

  postLibraryVocabularyUpdateMastered(dataToSend): Observable<any> {
    return this._post(Routes.vocabularyUpdateMastered, dataToSend);
  }

  postLibraryVocabularyWordDefinition(dataToSend): Observable<any> {
    return this._post(Routes.vocabularyWordDefinition, dataToSend);
  }

  // extemp api
  postExtempRoundStatistics(dataToSend): Observable<any> {
    return this._post(Routes.extempRoundStatistics, dataToSend);
  }

  postExtempFoldersCategories(dataToSend): Observable<any> {
    return this._post(Routes.extempFoldersCategories, dataToSend);
  }

  postExtempFoldersFolders(dataToSend): Observable<any> {
    return this._post(Routes.extempFoldersFolders, dataToSend);
  }

  postExtempFoldersArticlesIds(dataToSend): Observable<any> {
    return this._post(Routes.extempFoldersArticlesIds, dataToSend);
  }

  postExtempFoldersArticlesDetails(dataToSend): Observable<any> {
    return this._post(Routes.extempFoldersArticlesDetails, dataToSend);
  }

  postExtempFoldersArticleSummary(dataToSend): Observable<any> {
    return this._post(Routes.extempFoldersArticleSummary, dataToSend);
  }

  postExtempFoldersChangeCategory(dataToSend): Observable<any> {
    return this._post(Routes.extempFoldersChangeCategory, dataToSend);
  }

  postExtempCreateFoldersSubfolders(dataToSend): Observable<any> {
    return this._post(Routes.extempCreateFoldersSubfolders, dataToSend);
  }

  postExtempDeleteFoldersSubfolders(dataToSend): Observable<any> {
    return this._post(Routes.extempDeleteFoldersSubfolders, dataToSend);
  }

  postExtempEditFoldersSubfolders(dataToSend): Observable<any> {
    return this._post(Routes.extempEditFoldersSubfolders, dataToSend);
  }

  postExtempMergeFoldersSubfolders(dataToSend): Observable<any> {
    return this._post(Routes.extempMergeFoldersSubfolders, dataToSend);
  }

  postExtempCreateCategory(dataToSend): Observable<any> {
    return this._post(Routes.extempCreateCategory, dataToSend);
  }

  postExtempEditCategory(dataToSend): Observable<any> {
    return this._post(Routes.extempEditCategory, dataToSend);
  }

  postExtempDeleteCategory(dataToSend): Observable<any> {
    return this._post(Routes.extempDeleteCategory, dataToSend);
  }

  postExtempDrawQuestions(dataToSend): Observable<any> {
    return this._post(Routes.extempDrawQuestions, dataToSend);
  }

  postExtempPracticeQuestionsQuestionsIds(dataToSend): Observable<any> {
    return this._post(Routes.extempPracticeQuestionsIds, dataToSend);
  }

  postExtempPracticeQuestionsQuestionsDetails(dataToSend): Observable<any> {
    return this._post(Routes.extempPracticeQuestionsDetails, dataToSend);
  }

  postExtempNewQuestion(dataToSend): Observable<any> {
    return this._post(Routes.extempCreatePracticeQuestion, dataToSend);
  }

  postExtempListTopics(dataToSend): Observable<any> {
    return this._post(Routes.listPracticeTopics, dataToSend);
  }

  postExtempUsedQuestion(dataToSend): Observable<any> {
    return this._post(Routes.questionUsed, dataToSend);
  }

  postExtempBulkActionArticles(dataToSend): Observable<any> {
    return this._post(Routes.bulkActionArticles, dataToSend);
  }

  // congress api
  postCongressRoundStatistics(dataToSend): Observable<any> {
    return this._post(Routes.congressRoundStatistics, dataToSend);
  }

  postCongressDocketsArticlesDetails(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsArticlesDetails, dataToSend);
  }

  postCongressDocketsArticleSummary(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsArticleSummary, dataToSend);
  }

  postCongressDocketsDocketsIds(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsDocketsIds, dataToSend);
  }

  postCongressDocketsDocketsSearchIds(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsDocketsSearchIds, dataToSend);
  }

  postCongressDocketsDocketsDetails(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsDocketsDetails, dataToSend);
  }

  postCongressDocketsDocketsBills(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsDocketsBills, dataToSend);
  }

  postCongressRequestDocket(formData) {
    return this._postFormData(Routes.congressDocketsRequest, formData);
  }

  postCongressDocketBookmark(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketBookmark, dataToSend);
  }

  postCongressDocketBookmarkRemove(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketBookmarkRemove, dataToSend);
  }

  postCongressDocketsDocket(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsDocket, dataToSend);
  }

  postCongressDocketsBillArticleIds(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketsBillArticleIds, dataToSend);
  }

  postCongressArticlesBill(dataToSend): Observable<any> {
    return this._post(Routes.congressArticlesBill, dataToSend);
  }

  postCongressSpeechSave(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechSave, dataToSend);
  }

  postCongressSpeechDelete(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechDelete, dataToSend);
  }

  postCongressSpeechIds(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechIds, dataToSend);
  }

  postCongressSpeechDetails(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechDetails, dataToSend);
  }

  postCongressSpeechContent(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechContent, dataToSend);
  }

  postCongressArticleBulkCopy(dataToSend): Observable<any> {
    return this._post(Routes.congressArticleBulkCopy, dataToSend);
  }

  postCongressArticleBulkDelete(dataToSend): Observable<any> {
    return this._post(Routes.congressArticleBulkDelete, dataToSend);
  }

  postCongressArticleBulkMove(dataToSend): Observable<any> {
    return this._post(Routes.congressArticleBulkMove, dataToSend);
  }

  postCongressSpeechBulkCopy(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechBulkCopy, dataToSend);
  }

  postCongressSpeechBulkDelete(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechBulkDelete, dataToSend);
  }

  postCongressSpeechBulkMove(dataToSend): Observable<any> {
    return this._post(Routes.congressSpeechBulkMove, dataToSend);
  }

  postCongressArgumentIds(dataToSend): Observable<any> {
    return this._post(Routes.congressArgumentIds, dataToSend);
  }

  postCongressArgumentDetails(dataToSend): Observable<any> {
    return this._post(Routes.congressArgumentDetails, dataToSend);
  }

  postCongressArgumentsSpeech(dataToSend): Observable<any> {
    return this._post(Routes.congressArgumentsSpeech, dataToSend);
  }

  postCongressStates(dataToSend): Observable<any> {
    return this._post(Routes.congressStates, dataToSend);
  }

  postCongressDocketListIds(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketListIds, dataToSend);
  }

  postCongressDocketListDetails(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketListDetails, dataToSend);
  }

  postCongressBillList(dataToSend): Observable<any> {
    return this._post(Routes.congressBillList, dataToSend);
  }

  postCongressDocketTeamCheck(dataToSend): Observable<any> {
    return this._post(Routes.congressDocketTeamCheck, dataToSend);
  }
  
  postGetChallengesScore(dataToSend): Observable<any> {
    return this._post(Routes.userChallengesScore, dataToSend);
  }

  postGetChallengesProgress(dataToSend): Observable<any> {
    return this._post(Routes.userChallengesProgress, dataToSend);
  }

  // billing
  postBillingTeamCreate(dataToSend) {
    return this._post(Routes.billingTeamCreate, dataToSend);
  }

  postBillingTeamRenew(dataToSend) {
    return this._post(Routes.billingTeamRenew, dataToSend);
  }

  postBillingTeamUpgrade(dataToSend) {
    return this._post(Routes.billingTeamUpgrade, dataToSend);
  }

  postWelcomePackageRequest(dataToSend) {
    return this._post(Routes.welcomePackageRequest, dataToSend);
  }

  postWelcomePackageRefuse(dataToSend) {
    return this._post(Routes.welcomePackageRefuse, dataToSend);
  }

  postExtempAdvancedCategories(dataToSend) {
    return this._post(Routes.extempAdvancedCategories, dataToSend);
  }

  postBillingLastTransaction(dataToSend) {
    return this._post(Routes.billingLastTransaction, dataToSend);
  }

  postDiscountList(dataToSend) {
    return this._post(Routes.discountList, dataToSend);
  }

  postBillingPaymentHistory(dataToSend) {
    return this._post(Routes.billingPaymentHistory, dataToSend);
  }

  postBillingPlanStatus(dataToSend) {
    return this._post(Routes.billingPlanStatus, dataToSend);
  }

  postBillingReferrals(dataToSend) {
    return this._post(Routes.billingReferrals, dataToSend);
  }

  postReferralSend(dataToSend) {
    return this._post(Routes.referralSend, dataToSend);
  }

  postBillingWelcomePackage(dataToSend) {
    return this._post(Routes.billingWelcomePackage, dataToSend);
  }

  // signup
  postSignup(dataToSend) {
    return this._post(Routes.signup, dataToSend);
  }

  postSignupEmailChange(dataToSend) {
    return this._post(Routes.signupEmailChange, dataToSend);
  }

  postSignupEmailResend(dataToSend) {
    return this._post(Routes.signupEmailResend, dataToSend);
  }

  postSignupEmailVerify(dataToSend) {
    return this._post(Routes.signupEmailVerify, dataToSend);
  }

  postJoinTeam(dataToSend) {
    return this._post(Routes.joinTeam, dataToSend);
  }

  postRequestJoinTeam(dataToSend) {
    return this._post(Routes.requestJoinTeam, dataToSend);
  }

  postSearchTeamByContent(dataToSend) {
    return this._post(Routes.searchTeamByContent, dataToSend);
  }

  postSignupTeamByKey(dataToSend) {
    return this._post(Routes.signupTeamByKey, dataToSend);
  }


  private _get(route: string, requestOptionsArgs: any = null, notify = true) {
    if (!requestOptionsArgs) {
      let headers = new HttpHeaders();
      headers = headers.append('Session-Id', this._sessionIdentifier);
      headers = headers.append('Content-Type', 'application/json');

      const withCredentials = true;

      requestOptionsArgs = {
        headers,
        withCredentials
      };
    }

    this._increment(notify);

    return this._httpClient.get(route, requestOptionsArgs)
      .pipe(
        catchError((error) => throwError(error)),
        finalize(() => this._decrement(notify))
      );
  }

  private _post(route: string, dataToSend: any, requestOptionsArgs: any = null, notify = true) {
    if (!requestOptionsArgs) {
      let headers = new HttpHeaders();
      headers = headers.append('Session-Id', this._sessionIdentifier);
      headers = headers.append('Content-Type', 'application/json');

      const withCredentials = true;

      requestOptionsArgs = {
        headers,
        withCredentials
      };
    }

    this._increment(notify);

    return this._httpClient.post(route, dataToSend, requestOptionsArgs)
      .pipe(
        catchError((error) => throwError(error)),
        finalize(() => this._decrement(notify))
      );
  }

  private _postFormData(route: string, formData: FormData) {
    let headers = new HttpHeaders();
    headers = headers.append('Session-Id', this._sessionIdentifier);

    const requestOptionsArgs: any = {
      headers,
      withCredentials: true
    };

    this._increment(true);

    return this._httpClient.post(route, formData, requestOptionsArgs)
      .pipe(
        catchError((error) => throwError(error)),
        finalize(() => this._decrement(true))
      );
  }

  private _increment(notify: boolean) {
    if (notify)
      this._dataProvider.dispatch({
        type: INCREMENT_REQUESTS
      });
  }

  private _decrement(notify: boolean) {
    if (notify)
      this._dataProvider.dispatch({
        type: DECREMENT_REQUESTS
      });
  }
}
