export const ONLINE = 'ONLINE';
export const OFFLINE = 'OFFLINE';

export const INCREMENT_REQUESTS = 'INCREMENT_REQUESTS';
export const DECREMENT_REQUESTS = 'DECREMENT_REQUESTS';

export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_STAMPS = 'UPDATE_STAMPS';
export const UPDATE_CURRENT_TEAM = 'UPDATE_CURRENT_TEAM';
export const TOGGLE_NAV_MENU = 'TOGGLE_NAV_MENU';
export const UPDATE_HAS_FILTERS_MENU = 'UPDATE_HAS_FILTERS_MENU';
export const TOGGLE_FILTERS_MENU = 'TOGGLE_FILTERS_MENU';
export const TOGGLE_USER_MENU = 'TOGGLE_USER_MENU';
export const UPDATE_SMART_SEARCH_OPEN = 'UPDATE_SMART_SEARCH_OPEN';
export const UPDATE_NEWS_FEED_OPEN = 'UPDATE_NEWS_FEED_OPEN';
export const UPDATE_CONTRIBUTORS = 'UPDATE_CONTRIBUTORS';
export const UPDATE_PUBLICATIONS = 'UPDATE_PUBLICATIONS';
export const UPDATE_FTU_OPTIONS = 'UPDATE_FTU_OPTIONS';
export const UPDATE_BANNER_STATE = 'UPDATE_BANNER_STATE';
export const RESET_BANNERS = 'RESET_BANNERS';
export const UPDATE_CHALLENGE_PROGRESS = 'UPDATE_CHALLENGE_PROGRESS';

// notifications
export const RESET_N_NOTIFICATIONS = 'RESET_N_NOTIFICATIONS';
export const CREATE_N_NOTIFICATION = 'CREATE_N_NOTIFICATION';
export const DELETE_N_NOTIFICATION = 'DELETE_N_NOTIFICATION';

// article view
export const RESET_AV_VIEWS = 'RESET_AV_VIEWS';
export const UPDATE_AV_DATA = 'UPDATE_AV_DATA';
export const UPDATE_AV_ARTICLE = 'UPDATE_AV_ARTICLE';
export const UPDATE_AV_ARTICLE_READ_ON = 'UPDATE_AV_ARTICLE_READ_ON';
export const TOGGLE_AV_SEARCH = 'UPDATE_AV_TOGGLE_SEARCH';
export const UPDATE_AV_SEARCH_QUERY = 'UPDATE_AV_SEARCH_QUERY';
export const UPDATE_AV_SEARCH_TOTAL_TOKENS = 'UPDATE_AV_SEARCH_TOTAL_TOKENS';
export const UPDATE_AV_SEARCH_CURRENT_TOKEN = 'UPDATE_AV_SEARCH_CURRENT_TOKEN';
export const CREATE_AV_HIGHLIGHT = 'CREATE_AV_HIGHLIGHT';
export const DELETE_AV_HIGHLIGHT = 'DELETE_AV_HIGHLIGHT';

export const UPDATE_AV_CURRENT_VIEW = 'UPDATE_AV_CURRENT_VIEW';
export const UPDATE_AV_CURRENT_THEME = 'UPDATE_AV_CURRENT_THEME';
export const UPDATE_AV_CURRENT_FONT_FAMILY = 'UPDATE_AV_CURRENT_FONT_FAMILY';
export const UPDATE_AV_PREVIOUS_FONT_SIZE = 'UPDATE_AV_PREVIOUS_FONT_SIZE';
export const UPDATE_AV_NEXT_FONT_SIZE = 'UPDATE_AV_NEXT_FONT_SIZE';
export const UPDATE_AV_CURRENT_HIGHLIGHTER = 'UPDATE_AV_CURRENT_HIGHLIGHTER';

// speech view
export const UPDATE_SV_DATA = 'UPDATE_SV_DATA';
export const UPDATE_SV_TEAM_SETTINGS = 'UPDATE_SV_TEAM_SETTINGS';
export const UPDATE_SV_ARGUMENTS = 'UPDATE_SV_ARGUMENTS';
export const UPDATE_SV_CONTRIBUTOR = 'UPDATE_SV_CONTRIBUTOR';
export const UPDATE_SV_SPEECH = 'UPDATE_SV_SPEECH';
export const UPDATE_SV_SPEECH_CONTENT = 'UPDATE_SV_SPEECH_CONTENT';
export const UPDATE_SV_STATE_EDIT = 'UPDATE_SV_STATE_EDIT';
export const UPDATE_SV_STATE_VIEW = 'UPDATE_SV_STATE_VIEW';

// offline
// main
export const INIT_OL = 'INIT_OL';
export const UPDATE_OL_SYNC_STAMPS = 'UPDATE_OL_SYNC_STAMPS';
export const UPDATE_OL_SYNC_STATUS = 'UPDATE_OL_SYNC_STATUS';
export const UPDATE_OL_SYNC_OPTION_PDFS = 'UPDATE_OL_SYNC_OPTION_PDFS';
export const UPDATE_OL_SYNC_OPTION_NEWS_FEED = 'UPDATE_OL_SYNC_OPTION_NEWS_FEED';
export const UPDATE_OL_SYNC_OPTION_LIBRARY = 'UPDATE_OL_SYNC_OPTION_LIBRARY';
export const UPDATE_OL_SYNC_OPTION_EXTEMP = 'UPDATE_OL_SYNC_OPTION_EXTEMP';
export const UPDATE_OL_SYNC_OPTION_CONGRESS = 'UPDATE_OL_SYNC_OPTION_CONGRESS';

export const UPDATE_OL_SYNC_COUNT_PDFS = 'UPDATE_OL_SYNC_COUNT_PDFS';

// library
// fast-catch
export const UPDATE_LFC_ARTICLE = 'UPDATE_LFC_ARTICLE';
// smart search
export const TOGGLE_LSS_EDIT = 'TOGGLE_LSS_EDIT';
export const RESET_LSS_QUERY = 'RESET_LSS_QUERY';
export const UPDATE_LSS_QUERY = 'UPDATE_LSS_QUERY';
export const UPDATE_LSS_CURRENT_TIMEFRAME = 'UPDATE_LSS_CURRENT_TIMEFRAME';
export const UPDATE_LSS_ARTICLES_CURRENT_PAGE = 'UPDATE_LSS_ARTICLES_CURRENT_PAGE';
export const UPDATE_LSS_ARTICLES_ARTICLES_IDS = 'UPDATE_LSS_ARTICLES_ARTICLES_IDS';
export const UPDATE_LSS_ARTICLES_ARTICLES = 'UPDATE_LSS_ARTICLES_ARTICLES';
export const UPDATE_LSS_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_LSS_ARTICLES_ARTICLE_SUMMARY';
// news feed
export const RESET_LNF = 'RESET_LNF';
export const TOGGLE_LNF_EDIT = 'TOGGLE_LNF_EDIT';
export const RESET_LNF_QUERY = 'RESET_LNF_QUERY';
export const UPDATE_LNF_QUERY = 'UPDATE_LNF_QUERY';
export const UPDATE_LNF_CURRENT_TIMEFRAME = 'UPDATE_LNF_CURRENT_TIMEFRAME';
export const UPDATE_LNF_ARTICLES_CURRENT_PAGE = 'UPDATE_LNF_ARTICLES_CURRENT_PAGE';
export const UPDATE_LNF_ARTICLES_ARTICLES_IDS = 'UPDATE_LNF_ARTICLES_ARTICLES_IDS';
export const UPDATE_LNF_ARTICLES_ARTICLES = 'UPDATE_LNF_ARTICLES_ARTICLES';
export const UPDATE_LNF_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_LNF_ARTICLES_ARTICLE_SUMMARY';
// main
// article feed
export const UPDATE_LAF_ARTICLES_CONTRIBUTOR_FILTER = 'UPDATE_LAF_ARTICLES_CONTRIBUTOR_FILTER';
export const UPDATE_LAF_ARTICLES_PUBLICATION_FILTER = 'UPDATE_LAF_ARTICLES_PUBLICATION_FILTER';
export const RESET_LAF_ARTICLES_FILTERS = 'RESET_LAF_ARTICLES_FILTERS';
export const UPDATE_LAF_ARTICLES_CURRENT_ORDER_SORTER = 'UPDATE_LAF_ARTICLES_CURRENT_ORDER_SORDER';
export const UPDATE_LAF_ARTICLES_CURRENT_READ_FILTER = 'UPDATE_LAF_ARTICLES_CURRENT_READ_FILTER';
export const UPDATE_LAF_ARTICLES_CURRENT_SHOW_FILTER = 'UPDATE_LAF_ARTICLES_CURRENT_SHOW_FILTER';
export const UPDATE_LAF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER = 'UPDATE_LAF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER';
export const UPDATE_LAF_ARTICLES_CURRENT_PUBLICATION_FILTER = 'UPDATE_LAF_ARTICLES_CURRENT_PUBLICATION_FILTER';
export const UPDATE_LAF_ARTICLES_CURRENT_APP_FILTER = 'UPDATE_LAF_ARTICLES_CURRENT_APP_FILTER';
export const UPDATE_LAF_ARTICLES_CURRENT_PAGE = 'UPDATE_LAF_ARTICLES_CURRENT_PAGE';
export const UPDATE_LAF_ARTICLES_ARTICLES_IDS = 'UPDATE_LAF_ARTICLES_ARTICLES_IDS';
export const UPDATE_LAF_ARTICLES_ARTICLES = 'UPDATE_LAF_ARTICLES_ARTICLES';
export const UPDATE_LAF_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_LAF_ARTICLES_ARTICLE_SUMMARY';

//memory bank
export const UPDATE_LMB_PAGE_VIEW = 'UPDATE_LMB_PAGE_VIEW';
export const RESET_LMB_MEMORIES_FILTERS = 'RESET_LMB_MEMORIES_FILTERS';
export const UPDATE_LMB_MEMORIES_CURRENT_ORDER_SORTER = 'UPDATE_LMB_MEMORIES_CURRENT_ORDER_SORTER';
export const UPDATE_LMB_MEMORIES_CURRENT_MASTERED_FILTER = 'UPDATE_LMB_MEMORIES_CURRENT_MASTERED_FILTER';
export const UPDATE_LMB_MEMORIES_CURRENT_CONTRIBUTOR_FILTER = 'UPDATE_LMB_MEMORIES_CURRENT_CONTRIBUTOR_FILTER';
export const UPDATE_LMB_MEMORIES_CURRENT_DATE_FILTER = 'UPDATE_LMB_MEMORIES_CURRENT_DATE_FILTER';
export const UPDATE_LMB_MEMORIES_TOPICS_FILTER = 'UPDATE_LMB_MEMORIES_TOPICS_FILTER';
export const UPDATE_LMB_MEMORIES_SEARCH_KEYWORD = 'UPDATE_LMB_MEMORIES_SEARCH_KEYWORD';
export const UPDATE_LMB_MEMORIES_IDS = 'UPDATE_LMB_MEMORIES_IDS';
export const UPDATE_LMB_MEMORIES_DETAILS = 'UPDATE_LMB_MEMORIES_DETAILS';
export const UPDATE_LMB_MEMORIES_CURRENT_PAGE = 'UPDATE_LMB_MEMORIES_CURRENT_PAGE';

// vocabulary
export const RESET_LV_FILTERS = 'RESET_LV_FILTERS';
export const UPDATE_LV_CURRENT_ORDER_SORTER = 'UPDATE_LV_CURRENT_ORDER_SORTER';
export const UPDATE_LV_CURRENT_REVIEW_FILTER = 'UPDATE_LV_CURRENT_REVIEW_FILTER';
export const UPDATE_LV_CURRENT_STATUS_FILTER = 'UPDATE_LV_CURRENT_STATUS_FILTER';
export const UPDATE_LV_CURRENT_PAGE = 'UPDATE_LV_CURRENT_PAGE';
export const UPDATE_LV_ENTRIES_IDS = 'UPDATE_LV_ENTRIES_IDS';
export const UPDATE_LV_ENTRIES = 'UPDATE_LV_ENTRIES';
export const UPDATE_LV_CURRENT_SEARCH_KEYWORD = 'UPDATE_LV_CURRENT_SEARCH_KEYWORD';
export const UPDATE_LV_CURRENT_DATE_FILTER = 'UPDATE_LV_CURRENT_DATE_FILTER';
export const UPDATE_LV_ENTRIES_FLASHCARD = 'UPDATE_LV_ENTRIES_FLASHCARD';

// extemp
// smart search
export const TOGGLE_ESS_EDIT = 'TOGGLE_ESS_EDIT';
export const RESET_ESS_QUERY = 'RESET_ESS_QUERY';
export const UPDATE_ESS_QUERY = 'UPDATE_ESS_QUERY';
export const UPDATE_ESS_CURRENT_TIMEFRAME = 'UPDATE_ESS_CURRENT_TIMEFRAME';
export const UPDATE_ESS_ARTICLES_CURRENT_PAGE = 'UPDATE_ESS_ARTICLES_CURRENT_PAGE';
export const UPDATE_ESS_ARTICLES_ARTICLES_IDS = 'UPDATE_ESS_ARTICLES_ARTICLES_IDS';
export const UPDATE_ESS_ARTICLES_ARTICLES = 'UPDATE_ESS_ARTICLES_ARTICLES';
export const UPDATE_ESS_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_ESS_ARTICLES_ARTICLE_SUMMARY';
// news feed
export const RESET_ENF = 'RESET_ENF';
export const TOGGLE_ENF_EDIT = 'TOGGLE_ENF_EDIT';
export const RESET_ENF_QUERY = 'RESET_ENF_QUERY';
export const UPDATE_ENF_QUERY = 'UPDATE_ENF_QUERY';
export const UPDATE_ENF_CURRENT_TIMEFRAME = 'UPDATE_ENF_CURRENT_TIMEFRAME';
export const UPDATE_ENF_ARTICLES_CURRENT_PAGE = 'UPDATE_ENF_ARTICLES_CURRENT_PAGE';
export const UPDATE_ENF_ARTICLES_ARTICLES_IDS = 'UPDATE_ENF_ARTICLES_ARTICLES_IDS';
export const UPDATE_ENF_ARTICLES_ARTICLES = 'UPDATE_ENF_ARTICLES_ARTICLES';
export const UPDATE_ENF_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_ENF_ARTICLES_ARTICLE_SUMMARY';
// main
export const UPDATE_E_FOLDERS_CATEGORIES = 'UPDATE_E_FOLDERS_CATEGORIES';
export const UPDATE_EF_ARTICLES_CONTRIBUTOR_FILTER = 'UPDATE_EF_ARTICLES_CONTRIBUTOR_FILTER';
export const UPDATE_EF_ARTICLES_PUBLICATION_FILTER = 'UPDATE_EF_ARTICLES_PUBLICATION_FILTER';
export const RESET_EF_ARTICLES_FILTERS = 'RESET_EF_ARTICLES_FILTERS';
export const UPDATE_EF_ARTICLES_CURRENT_ORDER_SORTER = 'UPDATE_EF_ARTICLES_CURRENT_ORDER_SORDER';
export const UPDATE_EF_ARTICLES_CURRENT_READ_FILTER = 'UPDATE_EF_ARTICLES_CURRENT_READ_FILTER';
export const UPDATE_EF_ARTICLES_CURRENT_SHOW_FILTER = 'UPDATE_EF_ARTICLES_CURRENT_SHOW_FILTER';
export const UPDATE_EF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER = 'UPDATE_EF_ARTICLES_CURRENT_CONTRIBUTOR_FILTER';
export const UPDATE_EF_ARTICLES_CURRENT_PUBLICATION_FILTER = 'UPDATE_EF_ARTICLES_CURRENT_PUBLICATION_FILTER';
export const UPDATE_EF_ARTICLES_CURRENT_PAGE = 'UPDATE_EF_ARTICLES_CURRENT_PAGE';
export const UPDATE_EF_ARTICLES_ARTICLES_IDS = 'UPDATE_EF_ARTICLES_ARTICLES_IDS';
export const UPDATE_EF_ARTICLES_ARTICLES_IDS_CURRENT_FOLDER = 'UPDATE_EF_ARTICLES_ARTICLES_IDS_CURRENT_FOLDER';
export const UPDATE_EF_ARTICLES_ORDERED_IDS = 'UPDATE_EF_ARTICLES_ORDERED_IDS';
export const UPDATE_EF_ARTICLES_ARTICLES = 'UPDATE_EF_ARTICLES_ARTICLES';
export const UPDATE_EF_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_EF_ARTICLES_ARTICLE_SUMMARY';
export const UPDATE_EF_FOLDERS_CURRENT_ORDER_SORTER = 'UPDATE_EF_FOLDERS_CURRENT_ORDER_SORTER';
export const UPDATE_EF_SELECTION = 'UPDATE_EF_SELECTION';
export const UPDATE_EF_FOLDERS_MULTISELECT = 'UPDATE_EF_FOLDERS_MULTISELECT';
export const UPDATE_EF_FOLDERS = 'UPDATE_EF_FOLDERS';
export const UPDATE_EF_INPUT_FOLDERS = 'UPDATE_EF_INPUT_FOLDERS';
export const UPDATE_EF_FILTERED_FOLDERS = 'UPDATE_EF_FILTERED_FOLDERS';
export const UPDATE_EF_CATEGORIES_FILTER = 'UPDATE_EF_CATEGORIES_FILTER';
export const UPDATE_EE_SELECTED_ARTICLES = 'UPDATE_EE_SELECTED_ARTICLES';
export const UPDATE_EE_IS_ARTICLES_MULTISELECT = 'UPDATE_EE_IS_ARTICLES_MULTISELECT';
export const UPDATE_EE_SELECT_ALL_ARTICLES = 'UPDATE_EE_SELECT_ALL_ARTICLES';
//practice questions extemp
export const UPDATE_EPQ_DRAW_QUESTIONS = 'UPDATE_EPQ_DRAW_QUESTIONS';
export const UPDATE_EPQ_SELECTED = 'UPDATE_EPQ_SELECTED';
export const RESET_EPQ = 'RESET_EPQ';
export const UPDATE_EPQ_QUESTIONS_IDS = 'UPDATE_EPQ_QUESTIONS_IDS';
export const UPDATE_EPQ_QUESTIONS_QUESTIONS = 'UPDATE_EPQ_QUESTIONS_QUESTIONS';
export const UPDATE_EPQ_CURRENT_PAGE = 'UPDATE_EPQ_CURRENT_PAGE';
export const UPDATE_EPQ_FILTERS = 'UPDATE_EPQ_FILTERS';
export const UPDATE_EPQ_QUESTIONS_STATE = 'UPDATE_EPQ_QUESTIONS_STATE';

// round - extemp
export const ADD_R_ARTICLE = 'ADD_R_ARTICLE';
export const ADD_R_NEWS_FEED_ARTICLE = 'ADD_R_NEWS_FEED_ARTICLE';
export const REMOVE_R_ARTICLE = 'REMOVE_R_ARTICLE';
export const START_R_ROUND = 'START_R_ROUND';
export const STOP_R_ROUND = 'STOP_R_ROUND';
export const PAUSE_R_ROUND = 'PAUSE_R_ROUND';
export const UPDATE_R_ARTICLES = 'UPDATE_R_ARTICLES';
export const UPDATE_R_SELECTED_ARTICLE = 'UPDATE_R_SELECTED_ARTICLE';
export const UPDATE_R_PREVIOUS_ARTICLE = 'UPDATE_R_PREVIOUS_ARTICLE';
export const UPDATE_R_NEXT_ARTICLE = 'UPDATE_R_NEXT_ARTICLE';
export const START_PAUSE_R_TIMER = 'START_PAUSE_R_TIMER';
export const INVERT_R_TIMER = 'INVERT_R_TIMER';
export const RESTART_R_TIMER = 'RESTART_R_TIMER';

// congress
// smart search
export const TOGGLE_CSS_EDIT = 'TOGGLE_CSS_EDIT';
export const RESET_CSS_QUERY = 'RESET_CSS_QUERY';
export const UPDATE_CSS_QUERY = 'UPDATE_CSS_QUERY';
export const UPDATE_CSS_CURRENT_TIMEFRAME = 'UPDATE_CSS_CURRENT_TIMEFRAME';
export const UPDATE_CSS_ARTICLES_CURRENT_PAGE = 'UPDATE_CSS_ARTICLES_CURRENT_PAGE';
export const UPDATE_CSS_ARTICLES_ARTICLES_IDS = 'UPDATE_CSS_ARTICLES_ARTICLES_IDS';
export const UPDATE_CSS_ARTICLES_ARTICLES = 'UPDATE_CSS_ARTICLES_ARTICLES';
export const UPDATE_CSS_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_CSS_ARTICLES_ARTICLE_SUMMARY';
// news feed
export const RESET_CNF = 'RESET_CNF';
export const TOGGLE_CNF_EDIT = 'TOGGLE_CNF_EDIT';
export const RESET_CNF_QUERY = 'RESET_CNF_QUERY';
export const UPDATE_CNF_QUERY = 'UPDATE_CNF_QUERY';
export const UPDATE_CNF_CURRENT_TIMEFRAME = 'UPDATE_CNF_CURRENT_TIMEFRAME';
export const UPDATE_CNF_ARTICLES_CURRENT_PAGE = 'UPDATE_CNF_ARTICLES_CURRENT_PAGE';
export const UPDATE_CNF_ARTICLES_ARTICLES_IDS = 'UPDATE_CNF_ARTICLES_ARTICLES_IDS';
export const UPDATE_CNF_ARTICLES_ARTICLES = 'UPDATE_CNF_ARTICLES_ARTICLES';
export const UPDATE_CNF_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_CNF_ARTICLES_ARTICLE_SUMMARY';
// main
export const UPDATE_CD_DOCKETS = 'UPDATE_CD_DOCKETS';
export const UPDATE_CD_DOCKETS_DOCKET = 'UPDATE_CD_DOCKETS_DOCKET';
export const UPDATE_CD_DOCKETS_DOCKETS_IDS_ORDER = 'UPDATE_CD_DOCKETS_DOCKETS_IDS_ORDER';
export const UPDATE_EF_ARTICLES_CURRENT_SEARCH_ORDER = 'UPDATE_EF_ARTICLES_CURRENT_SEARCH_ORDER';
export const UPDATE_CD_DOCKETS_DOCKETS_IDS = 'UPDATE_CD_DOCKETS_DOCKETS_IDS';
export const UPDATE_CD_DOCKETS_CURRENT_PAGE = 'UPDATE_CD_DOCKETS_CURRENT_PAGE';
export const RESET_CD_DOCKETS_FILTERS = 'RESET_CD_DOCKETS_FILTERS';
export const UPDATE_CD_DOCKETS_CURRENT_ORDER_SORTER = 'UPDATE_CD_DOCKETS_CURRENT_ORDER_SORTER';
export const UPDATE_CD_DOCKETS_CURRENT_SAVED_FILTER = 'UPDATE_CD_DOCKETS_CURRENT_SAVED_FILTER';
export const UPDATE_CD_DOCKETS_CURRENT_STATE_FILTER = 'UPDATE_CD_DOCKETS_CURRENT_STATE_FILTER';
export const UPDATE_CD_DOCKETS_CURRENT_DATE_FILTER = 'UPDATE_CD_DOCKETS_CURRENT_DATE_FILTER';
export const UPDATE_CD_DOCKETS_STATES_FILTER = 'UPDATE_CD_DOCKETS_STATES_FILTER';
export const UPDATE_CD_BILLS_CURRENT_ORDER_SORTER = 'UPDATE_CD_BILLS_CURRENT_SORTER';
export const RESET_CD_BILLS_FILTERS = 'RESET_CD_BILLS_FILTERS';
export const UPDATE_CDB_ARTICLES = 'UPDATE_CDB_ARTICLES';
export const UPDATE_CDB_ARTICLE_IDS = 'UPDATE_CDB_ARTICLE_IDS';
export const UPDATE_CDB_ARTICLES_CURRENT_PAGE = 'UPDATE_CDB_ARTICLES_CURRENT_PAGE';
export const UPDATE_CDBB_SPEECH = 'UPDATE_CDBB_SPEECH';
export const UPDATE_CDBB_SPEECHES = 'UPDATE_CDBB_SPEECHES';
export const UPDATE_CDBB_SPEECHES_IDS = 'UPDATE_CDBB_SPEECHES_IDS';
export const UPDATE_CDBB_SPEECH_CONTENT = 'UPDATE_CDBB_SPEECH_CONTENT';
export const UPDATE_CDBB_SPEECHES_CURRENT_PAGE = 'UPDATE_CDBB_SPEECHES_CURRENT_PAGE';
export const UPDATE_CDBB_SPEECHES_CURRENT_SIDE_FILTER = 'UPDATE_CDBB_SPEECHES_CURRENT_SIDE_FILTER';
export const UPDATE_CDBB_SPEECHES_CURRENT_AUTHOR_FILTER = 'UPDATE_CDBB_SPEECHES_CURRENT_AUTHOR_FILTER';
export const UPDATE_CR_ADDED_DOCKETS = 'UPDATE_CR_ADDED_DOCKETS';
export const UPDATE_CD_ARTICLES_ARTICLE_SUMMARY = 'UPDATE_CD_ARTICLES_ARTICLE_SUMMARY';

export const UPDATE_CD_CURRENT_PAGE_BILL_LIST = 'UPDATE_CD_CURRENT_PAGE_BILL_LIST';
export const UPDATE_CD_CURRENT_PAGE_BILL_DETAILS = 'UPDATE_CD_CURRENT_PAGE_BILL_DETAILS';
export const UPDATE_CD_CURRENT_PAGE_DOCKET_DETAILS = 'UPDATE_CD_CURRENT_PAGE_DOCKET_DETAILS';
export const UPDATE_CD_CURRENT_PAGE_DOCKET_LIST = 'UPDATE_CD_CURRENT_PAGE_DOCKET_LIST';
export const UPDATE_CD_HAS_DOCKETS = 'UPDATE_CD_HAS_DOCKETS';

export const RESET_CDBL_FILTERS = 'RESET_CDBL_FILTERS';
export const UPDATE_CDBL_BILLS = 'UPDATE_CDBL_BILLS';
export const UPDATE_CDBL_BILLS_FILTERED = 'UPDATE_CDBL_BILLS_FILTERED';
export const UPDATE_CDBL_CURRENT_DOCKET = 'UPDATE_CDBL_CURRENT_DOCKET';
export const UPDATE_CDBL_DOCKETS = 'UPDATE_CDBL_DOCKETS';
export const UPDATE_CDBL_CURRENT_ORDER_SORTER = 'UPDATE_CDBL_CURRENT_ORDER_SORTER';
export const UPDATE_CDBL_INPUT = 'UPDATE_CDBL_INPUT';

export const RESET_CDDD_FILTERS = 'RESET_CDDD_FILTERS';
export const UPDATE_CDDD_BILLS = 'UPDATE_CDDD_BILLS';
export const UPDATE_CDDD_BILLS_FILTERED = 'UPDATE_CDDD_BILLS_FILTERED';
export const UPDATE_CDDD_CURRENT_ORDER_SORTER = 'UPDATE_CDDD_CURRENT_ORDER_SORTER';
export const UPDATE_CDDD_INPUT = 'UPDATE_CDDD_INPUT';

export const UPDATE_CDDL_INPUT = 'UPDATE_CDDL_INPUT';

export const UPDATE_C_ARGUMENT_CONTENT = 'UPDATE_C_ARGUMENT_CONTENT';
export const UPDATE_C_ARGUMENTS_IDS = 'UPDATE_C_ARGUMENTS_IDS';
export const UPDATE_C_ARGUMENTS = 'UPDATE_C_ARGUMENTS';
export const UPDATE_C_ARGUMENTS_CURRENT_SIDE_FILTER = 'UPDATE_C_ARGUMENTS_CURRENT_SIDE_FILTER';
export const UPDATE_C_ARGUMENTS_CURRENT_SORT = 'UPDATE_C_ARGUMENTS_CURRENT_SORT';
export const UPDATE_C_ARGUMENTS_CURRENT_PAGE = 'UPDATE_C_ARGUMENTS_CURRENT_PAGE';

// round - congress
export const CREATE_R_ITEMS = 'CREATE_R_ITEMS';
export const DELETE_R_ITEMS = 'DELETE_R_ITEMS';
export const UPDATE_R_SELECTED_ITEM = 'UPDATE_R_SELECTED_ITEM';
export const UPDATE_R_PREVIOUS_ITEM = 'UPDATE_R_PREVIOUS_ITEM';
export const UPDATE_R_NEXT_ITEM = 'UPDATE_R_NEXT_ITEM';
export const UPDATE_R_TITLE = 'UPDATE_R_TITLE';
export const UPDATE_R_SELECTED_DOCKET = 'UPDATE_R_SELECTED_DOCKET';
export const UPDATE_R_NEXT_STEP = 'UPDATE_R_NEXT_STEP';
export const UPDATE_R_PREVIOUS_STEP = 'UPDATE_R_PREVIOUS_STEP';
export const UPDATE_R_ROUND_RESTART_STEPS = 'UPDATE_R_ROUND_RESTART_STEPS';
export const UPDATE_R_ADDED_ARTICLES = 'UPDATE_R_ADDED_ARTICLES';

// precedence tracker (new version) - congress
export const UPDATE_PT_ADD_SPEECH = 'UPDATE_PT_ADD_SPEECH';
export const UPDATE_PT_ADD_QUESTION = 'UPDATE_PT_ADD_QUESTION';
export const UPDATE_PT_APPEND_LEGISLATORS = 'UPDATE_PT_APPEND_LEGISLATORS';
export const UPDATE_PT_DELETE_LEGISLATOR = 'UPDATE_PT_DELETE_LEGISLATOR';
export const UPDATE_PT_DELETE_QUESTION = 'UPDATE_PT_DELETE_QUESTION';
export const UPDATE_PT_DELETE_SPEECH = 'UPDATE_PT_DELETE_SPEECH';
export const UPDATE_PT_EDIT_LEGISLATOR = 'UPDATE_PT_EDIT_LEGISLATOR';
export const UPDATE_PT_EDIT_QUESTION = 'UPDATE_PT_EDIT_QUESTION';
export const UPDATE_PT_EDIT_SPEECH_BILL = 'UPDATE_PT_EDIT_SPEECH_BILL';
export const UPDATE_PT_EDIT_SPEECH_SIDE = 'UPDATE_PT_EDIT_SPEECH_SIDE';
export const UPDATE_PT_EDIT_SPEECH_TIMESTAMP = 'UPDATE_PT_EDIT_SPEECH_TIMESTAMP';
export const UPDATE_PT_INIT = 'UPDATE_PT_INIT';
export const UPDATE_PT_PRESIDING_OFFICER = 'UPDATE_PT_PRESIDING_OFFICER';
export const UPDATE_PT_REDO = 'UPDATE_PT_REDO';
export const UPDATE_PT_REMOVE_PRESIDING_OFFICER = 'UPDATE_PT_REMOVE_PRESIDING_OFFICER';
export const UPDATE_PT_RESET = 'UPDATE_PT_RESET';
export const UPDATE_PT_RESET_ACTIVITY = 'UPDATE_PT_RESET_ACTIVITY';
export const UPDATE_PT_SESSION = 'UPDATE_PT_SESSION';
export const UPDATE_PT_SORT_OPTION = 'UPDATE_PT_SORT_OPTION';
export const UPDATE_PT_STATE = 'UPDATE_PT_STATE';
export const UPDATE_PT_STOP = 'UPDATE_PT_STOP';
export const UPDATE_PT_UNDO = 'UPDATE_PT_UNDO';

// WEB SOCKETS
export const WS_AM_I_ALONE = 'AM_I_ALONE';
export const WS_TEAM_CHANGED = 'changeTeam';
export const WS_CATCH_ARTICLE = 'catchArticle';
export const WS_CATCH_AND_SAVE_TO_CONGRESS = 'catchAndSaveToCongress';
export const WS_CATCH_AND_SAVE_TO_EXTEMP = 'catchAndSaveToExtemp';
export const WS_CREATE_HIGHLIGHT = 'createHighlight';
export const WS_DELETE_DOCUMENT = 'deleteDocument';
export const WS_DELETE_HIGHLIGHT = 'deleteHighlight';
export const WS_DOCKET_REMOVE = 'removeTeamDocket';
export const WS_DOCKET_REMOVE_ERROR = 'removeTeamDocketError';
export const WS_EDIT_CONGRESS = 'editCongress';
export const WS_EDIT_DOCUMENT = 'editDocument';
export const WS_EDIT_EXTEMP = 'editExtemp';
export const WS_NEW_MEMBER = 'newMember';
export const WS_PURGE_ARTICLES = 'purgeAnnounce';
export const WS_REMOVE_MEMBERS = 'removeMembers';
export const WS_SAVE_TO_CONGRESS = 'saveToCongress';
export const WS_SAVE_TO_EXTEMP = 'saveToExtemp';
export const WS_NEW_CATEGORY = 'createCategory';
export const WS_EDIT_CATEGORY = 'editCategory';
export const WS_DELETE_CATEGORY = 'deleteCategory';
export const WS_NEW_FOLDER = 'createFolders';
export const WS_EDIT_FOLDER = 'editFolder';
export const WS_MERGE_FOLDER = 'mergeFolders';
export const WS_DELETE_FOLDER = 'deleteFolders';
export const WS_CHANGE_CATEGORY_FOLDER = 'changeCategory';
export const WS_ADD_DOCKET = 'addTeamDocket';
export const WS_ADD_SPEECH = 'saveSpeech';
export const WS_EDIT_SPEECH = 'editSpeech';
export const WS_DELETE_SPEECH = 'deleteSpeech';
export const WS_TEAM_SETTINGS_SPEECH = 'editTeamSettings';
export const WS_ADD_ARGUMENT = 'saveArgument';
export const WS_DELETE_ARGUMENT = 'deleteArgument';
export const WS_EDIT_ARGUMENT = 'editArgument';
export const WS_PRIVACY_ARGUMENT = 'editTeamPrivacyArgument';
export const WS_CREATE_MEMORY = 'createMemory';
export const WS_EDIT_MEMORY = 'editMemory';
export const WS_DELETE_MEMORY = 'deleteMemory';
export const WS_CREATE_VOCABULARY = 'createVocabularyEntry';
export const WS_DELETE_VOCABULARY = 'deleteVocabularyEntry';
export const WS_CHALLENGE_UPDATED = 'challengeUpdate';
export const WS_SPEECH_BULK_COPY_MOVE = 'speechBulkCopyMove';
export const WS_SPEECH_BULK_DELETE = 'speechBulkDelete';

// save to
export const INIT_ST_E_CATEGORIES = 'INIT_ST_E_CATEGORIES';

export const INIT_ST_E_FOLDERS = 'INIT_ST_E_FOLDERS';
export const UPDATE_ST_E_FOLDERS = 'UPDATE_ST_E_FOLDERS';
export const CHANGE_ST_E_SORT = 'CHANGE_ST_E_SORT';
export const CHANGE_ST_E_FILTER = 'CHANGE_ST_E_FILTER';
export const TOGGLE_ST_E_TOGGLED_FOLDER = 'TOGGLE_ST_E_TOGGLED_FOLDER';
export const TOGGLE_ST_E_SELECTED_FOLDER = 'TOGGLE_ST_E_SELECTED_FOLDER';
export const CREATE_ST_E_FOLDER = 'CREATE_ST_E_FOLDER';
export const DELETE_ST_E_FOLDER = 'DELETE_ST_E_FOLDER';
export const TOGGLE_ST_E_SELECTED_SUBFOLDER = 'TOGGLE_ST_E_SELECTED_SUBFOLDER';
export const CREATE_ST_E_SUBFOLDER = 'CREATE_ST_E_SUBFOLDER';
export const DELETE_ST_E_SUBFOLDER = 'DELETE_ST_E_SUBFOLDER';

export const INIT_ST_C_DOCKETS = 'INIT_ST_C_DOCKETS';
export const CHANGE_ST_C_FILTER = 'CHANGE_ST_C_FILTER';
export const TOGGLE_ST_C_TOGGLED_DOCKET = 'TOGGLE_ST_C_TOGGLED_DOCKET';
export const TOGGLE_ST_C_SELECTED_BILL = 'TOGGLE_ST_C_SELECTED_BILL';
