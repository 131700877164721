import { TTeamType, ETeamType } from "../../../models/team-type";

export enum EBillingAction {
  Create,
  Renew,
  Upgrade
}

export type TBillingAction = 
  EBillingAction.Create |
  EBillingAction.Renew |
  EBillingAction.Upgrade;

export class BillingActionFactory {
  constructor() {}

  public getBillingAction( currentTeamTypeId: TTeamType,
                           desiredTeamType: TTeamType,
                           desiredBillingAction: TBillingAction): TBillingAction {
    switch (currentTeamTypeId) {
      case ETeamType.PremiumTeam:
        return this._getBillingActionPremiumTeam(desiredTeamType, desiredBillingAction);
      case ETeamType.PremiumOne:
        return this._getBillingActionPremiumOne(desiredTeamType, desiredBillingAction);
      case ETeamType.FreeTeam:
        return this._getBillingActionFreeTeam(desiredTeamType, desiredBillingAction);
      case ETeamType.FreeOne:
        return EBillingAction.Upgrade;
      default:
        return EBillingAction.Create;
    }
  }

  private _getBillingActionPremiumTeam(desiredTeamType: TTeamType, desiredBillingAction: TBillingAction): TBillingAction {
    switch (desiredTeamType) {
      case ETeamType.PremiumTeam:
        return desiredBillingAction;
      case ETeamType.PremiumOne:
      case ETeamType.FreeTeam:
      case ETeamType.FreeOne:
      default:
        return EBillingAction.Create;
    }
  }

  private _getBillingActionPremiumOne(desiredTeamType: TTeamType, desiredBillingAction: TBillingAction): TBillingAction {
    switch (desiredTeamType) {
      case ETeamType.PremiumOne:
        return desiredBillingAction;
      case ETeamType.PremiumTeam:
      case ETeamType.FreeTeam:
      case ETeamType.FreeOne:
      default:
        return EBillingAction.Create;
    }
  }

  private _getBillingActionFreeTeam(desiredTeamType: TTeamType, desiredBillingAction: TBillingAction): TBillingAction{
    switch (desiredTeamType) {
      case ETeamType.PremiumTeam:
        if (desiredBillingAction === EBillingAction.Create)
          return EBillingAction.Create;
        return EBillingAction.Upgrade;          
      case ETeamType.PremiumOne:
        return EBillingAction.Create;          
      case ETeamType.FreeTeam:
      case ETeamType.FreeOne:
      default:
        return;
    }
  }
}