import {
  Component,
  Input,
  HostListener,
  Optional,
  ViewEncapsulation
} from '@angular/core';

import { IBillingTeam } from '../../../models/interfaces';

import { TeamListValueAccessor } from '../team-list/team-list.component';

@Component({
  selector: 'team-list-item',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './team-list-item.component.html',
  styleUrls: ['./team-list-item.component.scss'],
  host: {
    '[class.ti-row]': 'true'
  }
})
export class TeamListItemComponent {
  @Input()
  team: IBillingTeam | undefined;

  constructor(@Optional()
              private _teamListValueAccessor: TeamListValueAccessor) { }

  @HostListener('click')
  onClick() {
    if (this._teamListValueAccessor)
      this._teamListValueAccessor.writeValue(this.team);
  }
}
