import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { Subscription } from 'rxjs';

import { HttpService } from '../../../../services/http.service';
import { UtilService } from '../../../../services/util.service';

import { ETeamType, TTeamType } from '../../../../models/team-type';
import {
  IBillingPaymentData,
  IBillingPaymentMethod
} from '../../models/interfaces';
import { IState } from '../../../../models/interfaces';
import { EBillingPaymentType, BillingPaymentTypeFactory } from '../../models/payment';
import { EBillingAction, BillingActionFactory, TBillingAction } from '../../models/billing-action';
import { map, finalize } from 'rxjs/operators';

@Component({
  selector: 'billing-renew',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-renew.component.html',
  styleUrls: ['./billing-renew.component.scss']
})
export class BillingRenewComponent implements OnDestroy, OnInit {
  @Input()
  for = 'extemp';

  @Input()
  teamId: number;

  @Input()
  currentTeamType: TTeamType | undefined;

  @Input()
  recipientName: string;

  @Output()
  onFinish = new EventEmitter();

  billingAction: TBillingAction = EBillingAction.Renew;
  currentStep: string;
  isLoading: boolean = false;
  membersLimit: number;
  proApps: number;
  lastPayment: IBillingPaymentData;
  steps: {[key: string]: string};

  private _lastTransactionSubscription: Subscription;

  readonly TEAM_TYPE;

  constructor(
    private _httpProvider: HttpService,
    private _utilProvider: UtilService,
  ) {
    this._initSteps();

    this.TEAM_TYPE = ETeamType;
  }

  ngOnDestroy() {
    if (this._lastTransactionSubscription)
      this._lastTransactionSubscription.unsubscribe();
  }

  ngOnInit() {
    // if (this.currentTeamType !== undefined)
    //   this.billingAction = new BillingActionFactory().getBillingAction(this.currentTeamType, this.currentTeamType, EBillingAction.Renew);

    const lastTransaction$ = this._createBillingRenewDetails$({teamId: this.teamId});
    this._lastTransactionSubscription = lastTransaction$.subscribe((payload) => this._setPaymentData(payload));
  }

  finish() {
    this.onFinish.emit();
  }

  setCurrentStep(step: string) {
    this.currentStep = step;
  }

  private _createBillingRenewDetails$(dataToSend) {
    this.isLoading = true;

    return this._httpProvider.postBillingLastTransaction(dataToSend)
      .pipe(
        map((response: any) => response.result),
        finalize(() => this.isLoading = false),
      );
  }

  private _getDefaultCurrentStep(): string {
    return this.steps.root;
  }

  private _setPaymentData(payload) {
    const payment: IBillingPaymentMethod = new BillingPaymentTypeFactory().getPaymentObjectByValue()[payload.method];
    this.membersLimit = payload.membersLimit;
    this.proApps = payload.proApps;

    switch (payment.id) {
      case EBillingPaymentType.Card:
        this.lastPayment = {
          app: payload.proApps,
          cost: payload.amount,
          endDate: payload.endDate,
          membersLimit: payload.membersLimit,
          subscriptionLength: payload.covers,
          paid: payload.paid,
          payment: {
            payment,
            address: payload.details.address,
            city: payload.details.city,
            firstName: payload.details.firstName,
            lastName: payload.details.lastName,
            state: this._utilProvider.toArray( this._utilProvider.getStates() ).find((state: IState) => state.abbreviation === payload.details.state),
            zip: payload.details.zip
          }
        };
        break;
      case EBillingPaymentType.CheckDT:
        this.lastPayment = {
          app: payload.proApps,
          cost: payload.amount,
          endDate: payload.endDate,
          membersLimit: payload.membersLimit,
          subscriptionLength: payload.covers,
          paid: payload.paid,
          payment: {
            payment,
            administrativeContact: payload.details.adminName,
            schoolDistrictName: payload.details.districtName,
            email: payload.details.email,
            otherSchools: payload.details.otherSchools,
            title: payload.details.title
          }
        };
        break;
      case EBillingPaymentType.CheckIn:
      case EBillingPaymentType.CheckPO:
      default:
        this.lastPayment = {
          app: payload.proApps,
          cost: payload.amount,
          endDate: payload.endDate,
          membersLimit: payload.membersLimit,
          subscriptionLength: payload.covers,
          paid: payload.paid,
          payment: {payment}
        };
        break;
    }
  }

  private _initSteps(): void {
    this.steps = {
      root: 'root',
      welcomePackages: 'welcomePackages'
    };
    this.setCurrentStep( this._getDefaultCurrentStep() );
  }
}
