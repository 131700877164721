import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'button[aux-button-icon], a[aux-button-icon]',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './aux-button-icon.component.html',
  styleUrls: ['./aux-button-icon.component.scss']
})
export class AuxButtonIconComponent implements OnInit {
  @Input()
  for: string;

  @Input()
  appearance: string;

  @Input()
  size: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) {}

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.appearance)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.appearance}`);

    if (this.size)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.size}`);
  }
}
