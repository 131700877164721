import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

import { Subscription } from "rxjs";
import { finalize, map, debounceTime } from "rxjs/operators";

import { HttpService } from "../../../../services/http.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { ValidatorsService } from "../../../../services/validators.service";
import { UtilService } from "../../../../services/util.service";

import {
  IState
} from "../../../../models/interfaces";
import { CREATE_N_NOTIFICATION } from "../../../../models/constants";
import { IBillingWelcomePackageData } from '../../models/interfaces';

@Component({
  selector: 'billing-welcome-package-request',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-welcome-package-request.component.html',
  styleUrls: ['./billing-welcome-package-request.component.scss']
})
export class BillingWelcomePackageRequestComponent implements OnDestroy, OnInit {
  @Input()
  for = 'extemp';

  @Input()
  recipientName;

  @Input()
  teamId;

  @Output()
  onFinish = new EventEmitter();

  isLoading: boolean = false;
  welcomePackageForm: FormGroup;
  welcomePackageData: IBillingWelcomePackageData;
  
  readonly states: IState[];

  private readonly _errors;

  private _dataSubscription: Subscription;
  private _welcomePackageSubscription: Subscription;
  private _updateWelcomePackageSubscription: Subscription;

  constructor(
    private _httpProvider: HttpService,
    private _elementRef: ElementRef,
    private _notificationsProvider: NotificationsService,
    private _renderer: Renderer2,
    private _validatorsProvider: ValidatorsService,
    private _utilProvider: UtilService,
  ) {
    this.states = this._utilProvider.toArray( this._utilProvider.getStates() );
    this._errors = {
      required: {
        recipientName: 'Recipient Name is required',
        address1: 'Address line 1 is required',
        city: 'City is required',
        state: 'State is required',
        zip: 'Zip is required',
        tel: 'Phone Number is required'
      },
      minlength: {
        recipientName: 'Recipient Name is too short',
        district: 'District is too short',
        address1: 'Address line 1 is too short',
        city: 'City is too short',
        tel: 'Phone number is too short'
      },
      maxlength: {
        address1: 'Address 1 is too long',
        address2: 'Address 2 is too long',
        city: 'City is too long',
        recipientName: 'Recipient Name is too long',
        tel: 'Phone number is too long',
        zip: 'Zip is too long',
      },
      pattern: {
        tel: 'Phone number must be a number',
        zip: 'Zip must be a number of 5 digits'
      }
    };
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    this._createWelcomePackageForm();

    const data$ = this._createData$({teamId: this.teamId});
    this._dataSubscription = data$.subscribe((data) => this.welcomePackageData = data);

    const updateWelcomePackage$ = this._createUpdateWelcomePackageForm$(this.welcomePackageForm);
    this._updateWelcomePackageSubscription = updateWelcomePackage$.subscribe();
  }

  ngOnDestroy() {
    if (this._dataSubscription)
    this._dataSubscription.unsubscribe();

    if (this._welcomePackageSubscription)
      this._welcomePackageSubscription.unsubscribe();

    if (this._updateWelcomePackageSubscription)
      this._updateWelcomePackageSubscription.unsubscribe();
  }

  request(form: FormGroup) {
    if (this._welcomePackageSubscription)
      this._welcomePackageSubscription.unsubscribe();

    if (this._showFormErrors(form, this._errors))
      return;

    let formValues = this.welcomePackageForm.value;
    let dataToSend = {
      teamId: this.teamId, 
      wpData: {
        address1: formValues.address1,
        address2: formValues.address2 || '',
        city: formValues.city,
        recipientName: formValues.recipientName,
        state: formValues.state.abbreviation,
        tel: formValues.tel,
        useSchoolAddress: formValues.useSchoolAddress,
        zip: formValues.zip,
      }
    };

    this.isLoading = true;

    const welcomePackage = this._createRequest$(dataToSend);
    this._welcomePackageSubscription = welcomePackage
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(
        () => {
          this._notificationSuccess('Thanks! Your package will be mailed and it should reach you in 1-2 weeks.');
          this.onFinish.emit();
        },
        (error) => this._showHttpErrorNotification(error)
      );
  }

  private _createData$(dataToSend) {
    this.isLoading = false;

    return this._httpProvider.postBillingWelcomePackage(dataToSend)
      .pipe(
        map((response: any) => response.result),
        map((data) => {
          const state = this._utilProvider.getStates()[data.state];

          return {
            address1: data.address,
            city: data.city,
            recipientName: this.recipientName,
            state,
            tel: data.tel,
            zip: data.zip,
          };
        }),
        finalize(() => this.isLoading = true),
      );
  }

  private _createWelcomePackageForm() {
    this.welcomePackageForm = new FormGroup({
      address1: new FormControl(null,
        [Validators.required,
        Validators.minLength(5),
        Validators.maxLength(254)]),
      address2: new FormControl(null, [Validators.maxLength(254)]),
      city: new FormControl(null,
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(254)]),
      recipientName: new FormControl(null,
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(254)]),
      state: new FormControl(null, [Validators.required]),
      tel: new FormControl(null,
        [Validators.required,
        Validators.minLength(10),
        Validators.maxLength(19),
        Validators.pattern( this._utilProvider.getNumberRegex() )]),
      useSchoolAddress: new FormControl(this._getDefaultUseSchoolAddress(), [Validators.required]),
      zip: new FormControl(null,
        [Validators.required,
        Validators.pattern( this._utilProvider.getZipRegex() )]),
    });
  }

  private _createUpdateWelcomePackageForm$(formGroup) {
    return formGroup.get('useSchoolAddress')
      .valueChanges
      .pipe(
        debounceTime(50),
        map((useSchoolAddress: boolean) => {
          this._updateWelcomePackageForm(useSchoolAddress, this.welcomePackageData);
        }),
      );
  }

  private _createRequest$(dataToSend) {
    return this._httpProvider.postWelcomePackageRequest(dataToSend);
  }

  private _getDefaultUseSchoolAddress(): boolean {
    return false;
  }

  private _notificationError(message: string) {
    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: {
        for: 'error',
        showClose: true,
        timeout: 5000,
        label: message
      }
    });
  }

  private _notificationSuccess(message: string) {
    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: {
        for: 'success',
        showClose: true,
        timeout: 5000,
        label: message
      }
    });
  }

  private _showFormErrors(formGroup: FormGroup, errorMessages: any) {
    const controls = Object.keys(formGroup.controls);
    let hasError: boolean = false;

    controls.forEach((controlName: string) => {
      const control = formGroup.controls[controlName];

      if (control instanceof FormGroup) {
        hasError = this._showFormErrors(control, errorMessages) ? true : hasError;
        return;
      }

      if (control.valid)
        return;

      hasError = true;
      Object.keys(control.errors)
        .forEach((error: string) => {
          this._notificationError(errorMessages[error][controlName]);      
        });
    }, this);

    return hasError;
  }

  private _showHttpErrorNotification(error) {
    const message = this._validatorsProvider.getHttpError('welcomePackageRequest_' + error.message);

    const notification = {
      for: 'error',
      showClose: true,
      timeout: 5000,
      label: message || error.message
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }

  private _updateWelcomePackageForm(useSchoolAddress: boolean, welcomePackageData: IBillingWelcomePackageData): void {
    if (useSchoolAddress) {
      this.welcomePackageForm.patchValue(welcomePackageData);
      return;
    }
    this.welcomePackageForm.reset({
      useSchoolAddress: this._getDefaultUseSchoolAddress()
    });
  }
}