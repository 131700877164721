import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import { DropdownValueAccessor } from '../dropdown/dropdown.component';

@Component({
  selector: 'pp-dropdown-item',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent implements OnInit {
  @Input('dropdownItemItem')
  item: any;
  @Input('dropdownItemFor')
  for: string;
  @Input('dropdownItemSize')
  size: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2,
              @Optional()
              private _dropdownValueAccessor: DropdownValueAccessor) {}

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.size)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.size}`);
  }

  @HostListener('click')
  onClick() {
    if (this._dropdownValueAccessor)
      this._dropdownValueAccessor.writeValue(this.item);
  }
}