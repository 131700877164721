import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { IAction } from '../models/interfaces';

import {
  RESET_N_NOTIFICATIONS,
  CREATE_N_NOTIFICATION,
  DELETE_N_NOTIFICATION,
} from '../models/constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private _notificationsStore: any;
  private _maxNotifications = 3;
  private _queuedNotifications = [];

  private _notificationsStore$: BehaviorSubject<any>;
  get notificationsStore$() {
    return this._notificationsStore$;
  }

  constructor() {
    this._notificationsStore = {};

    this._notificationsStore.notifications = [];

    this._notificationsStore$ = new BehaviorSubject(this._notificationsStore);
  }

  dispatch(action: IAction) {
    let output;

    switch (action.type) {
      case RESET_N_NOTIFICATIONS:
        this._queuedNotifications = [];
        this._notificationsStore.notifications = [];
        break;
      case CREATE_N_NOTIFICATION:
        if (this._notificationsStore.notifications.length === this._maxNotifications)
          this._queuedNotifications.push(action.payload);
        else
          this._notificationsStore.notifications.push(action.payload);
        break;
      case DELETE_N_NOTIFICATION:
        output = this._notificationsStore.notifications.indexOf(action.payload);
        if (output > -1)
          this._notificationsStore.notifications.splice(output, 1);

        if (this._queuedNotifications.length) {
          output = this._queuedNotifications.shift();
          this._notificationsStore.notifications.push(output);
        }
        break;
    }

    // console.log(action.type, this._notificationsStore$.observers.length);

    this._notificationsStore$.next(this._notificationsStore);
  }
}
