import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from "@angular/router";

import { Subscription } from "rxjs";
import { Routes } from '../../models/routes';

@Component({
  selector: 'pp-signup',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  invitationToken: string;
  goto: string;

  for: string = 'extemp';

  private _queryParamsSubscription: Subscription;

  constructor(private _activatedRoute: ActivatedRoute,
              private _router: Router) { }

  ngOnInit() {
    if ( this._queryParamsSubscription )
      this._queryParamsSubscription.unsubscribe();
    this._queryParamsSubscription = this._activatedRoute
      .queryParams
      .subscribe(params => {
        this.invitationToken = params['token']
        this.goto = params['goto']
      });
  }

  ngOnDestroy() {
    if ( this._queryParamsSubscription )
      this._queryParamsSubscription.unsubscribe();
  }

  finish() {
    if(this.goto === "tournament"){
      //navigate to classroom tournament
      window.location.assign(`${Routes.classroomClient}/#/start?redirectTo=dashboard/tournament`);
      return;
    }
    if (!this.invitationToken)
      this._router.navigate(['/signup-continue']);
    else
      this._router.navigate(['/status'], { queryParams: {  type: '0', reason: '1', target: '1' } });
  }
}
