import { Injectable, NgZone } from '@angular/core';

import {
  FormGroup,
} from "@angular/forms";

import { Observable, of } from "rxjs";
import { map, concatMap } from 'rxjs/operators';

import { CREATE_N_NOTIFICATION } from "../../../models/constants";

import { HttpService } from '../../../services/http.service';
import { NotificationsService } from "../../../services/notifications.service";
import { StripeService } from "./stripe.service";
import { UtilService } from "../../../services/util.service";

import { TTeamType } from '../../../models/team-type';
import { BillingTeamTypeFactory } from '../models/billing-team-type';
import { IBillingTeamType } from '../models/interfaces';
import { EBillingAction, TBillingAction } from '../models/billing-action';

type FormType = 'plan' | 'payment' | 'team';
// type UpgradeType = 'proTeam' | 'proOne' | 'freeTeam' | 'freeOne';

@Injectable()
export class BillingService {

  constructor(
    private _httpProvider: HttpService,
    private _notificationsProvider: NotificationsService,
    private _stripeProvider: StripeService,
    private _utilProvider: UtilService,
    private _zone: NgZone,
    ) { }

  getActionRoute$(action: TBillingAction, dataToSend) {
    switch (action) {
      case EBillingAction.Create:
        return this._httpProvider.postBillingTeamCreate(dataToSend)
      case EBillingAction.Renew:
        return this._httpProvider.postBillingTeamRenew(dataToSend);
      case EBillingAction.Upgrade:
        return this._httpProvider.postBillingTeamUpgrade(dataToSend);
    }
  }

 /**
  * @param forms - array of FormGroup(forms[0]-plan, forms[1]-payment, forms[2]-team)
  */
  getData(payload: {teamId: number, teamType: TTeamType, forms: FormGroup[], action: TBillingAction}) {
    return this.getStripeToken(payload.forms[1])
      .pipe(
        map(({ status, response }) => {
          // console.log('billing', status, response);
          return response;
        }),
        map((strpResponse) => this.getDataPayment(payload.forms[0], payload.forms[1], strpResponse, payload.teamType)),
        concatMap((data) => {
          switch (payload.action) {
            case EBillingAction.Create:
              return this._getCreationData$(payload, data);
            case EBillingAction.Renew:
              return this._getRenewData$(payload, data);
            case EBillingAction.Upgrade:
              return this._getUpgradeData$(payload, data);
            default:
              return of({});
          }
        }),
      );
  }

  getDataPayment(planForm: FormGroup, paymentForm: FormGroup, stripeResponse: any, teamType: TTeamType): Object {
    if (!planForm && !paymentForm) return {};

    let data: any = {
      discount: -13,
      district: {},
      paymentDetails: {},
      paymentMethod: '',
      plan: {},
      useSameCard: false
    };

    if (paymentForm) {
      let value = paymentForm.value;
      data.paymentMethod = paymentForm.value.payment.value;
      if (data.paymentMethod === 'card')
        data.paymentDetails = {
          address: value.address,
          cardToken: stripeResponse.id,
          city: value.city,
          firstName: value.firstName,
          lastName: value.lastName,
          state: value.state.abbreviation,
          zip: value.zip
        };

      if (data.paymentMethod === 'check-dt')
        data.district = {
          adminName: value.administrativeContact,
          districtName: value.schoolDistrictName,
          email: value.email,
          title: value.title,
          otherSchools: value.otherSchools
        };
    }
    
    if (planForm) {
      if (planForm.value.discount) data.discount = planForm.value.discount.id;
      const usersNo = this._utilProvider.getUsers(teamType);
      const app = planForm.value.app;
      const subscription = planForm.value.subscriptionLength;
      data.plan = {
        apps: app ? app.id : undefined,
        membersLimit: usersNo[ planForm.value.membersLimit ] || undefined,
        subscriptionLength: subscription ? subscription.value : undefined
      }
    }

    return data;
  }

  getDataReferrals(planForm: FormGroup): any[] {
    if (!planForm || !planForm.value.referral) return [];

    const value = planForm.value;
    
    return [{
      referralName: value.referral.label,
      referralValue: value[value.referral.detailsRequired] || ''
    }];
  }

  getDataTeam(teamForm: FormGroup): Object {
    if (!teamForm) return {};

    const value = teamForm.value;
    const address = value.address2 ? value.address1 + ' ' + value.address2 : value.address1;
    const tel = value.phone.ext ? value.phone.area + value.phone.telNo + value.phone.ext : value.phone.area + value.phone.telNo;

    return {
      address,
      city: value.city,
      district: value.district || '',
      name: value.schoolName,
      state: value.state.abbreviation,
      tel,
      type: value.schoolType.value,
      zip: `${value.zip}`
    };
  }

  checkFormErrors(formGroup: FormGroup, type: FormType) {
    const controls = Object.keys(formGroup.controls);
    const errorMessages: any = this._getErrorMessage(type);
    let hasError: boolean = false;

    controls.forEach((controlName: string) => {
      const control = formGroup.controls[controlName];

      if (control instanceof FormGroup) {
        hasError = this.checkFormErrors(control, errorMessages) ? true : hasError;
        return;
      }

      if (control.valid || control.status === 'DISABLED') // angular considers disabled inputs being invalid inputs
        return;

      hasError = true;
      Object.keys(control.errors)
        .forEach((error: string) => {
          this._notificationError(errorMessages[error][controlName]);
        });
    }, this);

    return hasError;
  }

  getStripeToken(paymentForm: FormGroup) {
    const data = of({status: {}, response: {id: ''}});
    if (!paymentForm || paymentForm.value.payment.value !== 'card')
      return data;
    if (paymentForm.value.payment.value === 'card') {
      const dataToSend = {
        name: `${paymentForm.value.firstName} ${paymentForm.value.lastName}`,
        number: paymentForm.value.cardNumber,
        cvc: paymentForm.value.cvc,
        exp_month: paymentForm.value.expMonth.value,
        exp_year: paymentForm.value.expYear.value
      };
      return this._stripeProvider.getStripeToken(dataToSend)
        .pipe(
          map(({status, response}) => {
            return this._zone.run(() => {
              if (status !== 200)
                 throw new Error(response.error.message);
              return {status, response};
            });
          }),
        );
    }
  }

  private _getCreationData$(payload: {teamId: number, teamType: TTeamType, forms: FormGroup[]}, paymentData) {
    return of({})
      .pipe(
        map(() => {
          const team: IBillingTeamType = new BillingTeamTypeFactory().createBillingTeamType(payload.teamType);
          return {
            paymentData,
            referrals:[{}],
            teamData: {},
            teamId: payload.teamId,
            type: team.value
          };
        }),
        map((data) => {
          data.referrals = this.getDataReferrals(payload.forms[0]);
          return data;
        }),
        map((data) => {
          data.teamData = this.getDataTeam(payload.forms[2]);
          return data;
        }),
      );
  }

  private _getRenewData$(payload: {teamId: number, teamType: TTeamType, forms: FormGroup[]}, paymentData) {
    return of({})
      .pipe(
        map(() => {
          return {
            paymentData,
            teamId: payload.teamId
          };
        }),
      );
  }

  private _getUpgradeData$(payload: {teamId: number, teamType: TTeamType, forms: FormGroup[]}, paymentData) {
    return of({})
      .pipe(
        map(() => {
          const team: IBillingTeamType = new BillingTeamTypeFactory().createBillingTeamType(payload.teamType);
          return {
            paymentData,
            referrals:[{}],
            teamData: {},
            teamId: payload.teamId,
            upgradeType: team.value
          };
        }),
        map((data) => {
          data.referrals = this.getDataReferrals(payload.forms[0]);
          return data;
        }),
        map((data) => {
          data.teamData = this.getDataTeam(payload.forms[2]);
          return data;
        }),
      );
  }

  private _getErrorMessage(type: FormType) {
    switch(type) {
      case 'plan':
        return {
          required: {
            app: 'Please select an app',
            campName: 'Please provide the camp name',
            coachName: 'Please provide the coach name',
            referral: 'Referral is required',
            studentName: 'Please provide the student name',
            subscriptionLength: 'Please provide the subscription period'
          },
          minlength: {
            campName: 'Camp name is too short',
            coachName: 'Coach name is too short',
            studentName: 'Student name is too short'
          },
          maxlength: {
            campName: 'Camp name is too long',
            coachName: 'Coach name is too long',
            studentName: 'Student name is too long'
          }
        };
      case 'payment':
        return {
          required: {
            paymentMethod: 'Please select a payment method',
            firstName: 'First name is required',
            lastName: 'Last name is required',
            email: 'Email is required',
            address: 'Address is required',
            city: 'City is required',
            state: 'State is required',
            zip: 'Zip is required',
            cardNumber: 'Card number is required',
            cvc: 'CVC is required',
            expMonth: 'Expiration month is required',
            expYear: 'Expiration year is required',
            schoolDistrictName: 'School district is required',
            administrativeContact: 'Administrative Contact is required',
            otherSchools: 'Please mention other schools which will be paying through your District Admin. Otherwise select another payment method or email hello@prepd.in for a resolution.',
            title: 'Title is required'
          },
          minlength: {
            firstName: 'First name is too short',
            lastName: 'Last name is too short',
            zip: 'Zip is too short',
            // cardNumber: 'Card number is too short',
            cvc: 'CVC is too short',
            schoolDistrictName: 'School district is too short',
            administrativeContact: 'Administrative Contact is too short',
            otherSchools: 'Other schools field is too short',
            title: 'Title is too short'
          },
          maxlength: {
            firstName: 'First name is too long',
            lastName: 'Last name is too long',
            zip: 'Zip is too long',
            // cardNumber: 'Card number is too long',
            cvc: 'CVC is too long',
            schoolDistrictName: 'School district is too long',
            administrativeContact: 'Administrative Contact is too long',
            otherSchools: 'Other schools field is too long',
            title: 'Title is too long'
          },
          email: {
            email: 'Email is invalid'
          },
          pattern: {
            cardNumber: 'Card number must be between 10 and 19 digits long.',
            cvc: 'CVC must be a number with length ranging from 3 to 4 digits',
            zip: 'Zip must be a number of 5 digits',
            email: 'Email is invalid'
          }
        };
      case 'team':
      default:
        return {
          required: {
            schoolName: 'School name is required',
            // district: 'District is required',
            schoolType: 'School type is required',
            address1: 'Address is required',
            city: 'City is required',
            state: 'State is required',
            zip: 'Zip is required',
            area: 'Area code is required',
            telNo: 'Phone Number is required'
          },
          minlength: {
            schoolName: 'School name is too short',
            district: 'District is too short',
            address1: 'Address is too short',
            address2: 'The second address is too short',
            city: 'City is too short',
            area: 'Area code is too short',
            telNo: 'Phone number is too short'
          },
          maxlength: {
            schoolName: 'School name is too long',
            area: 'Area code is too long',
            telNo: 'Phone number is too long'
          },
          pattern: {
            zip: 'Zip must be a number of 5 digits',
            area: 'Area code must be a number',
            telNo: 'Phone number must be a number',
            ext: 'Ext must be a number'
          }
        };
    }
  }

  private _notificationError(message: string) {
    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: {
        for: 'error',
        showClose: true,
        timeout: 5000,
        label: message
      }
    });
  }
}
