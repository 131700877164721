import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputRadioComponent } from './input-radio.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InputRadioComponent],
  exports: [InputRadioComponent]
})
export class InputRadioModule { }
