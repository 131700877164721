import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

import { Subscription } from "rxjs";
import { finalize } from 'rxjs/operators';

import { HttpService } from "../../../../services/http.service";
import { NotificationsService } from "../../../../services/notifications.service";

import { CREATE_N_NOTIFICATION } from "../../../../models/constants";

@Component({
  selector: 'billing-extemp-preparation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-extemp-preparation.component.html',
  styleUrls: ['./billing-extemp-preparation.component.scss']
})
export class BillingExtempPreparationComponent implements OnInit {
  @Input()
  for = 'extemp';

  @Input()
  label;

  @Input()
  teamId;

  @Output()
  onContinue = new EventEmitter();

  formExtempPreparation: FormGroup;
  isLoading: boolean = false;
  categoryTypes = {
    simple: 0,
    advanced: 1
  };
  categories = {
    simple: [],
    advanced: {
      domestic: [],
      foreign: [],
      misc: []
    }
  };

  private _extempPreparationSubscription: Subscription;

  private readonly _errors = {
    notAllowed: 'The current membership is not allowed to change the categories!',
    categoriesModified: 'The team already has some custom categories.' 
  };

  constructor(
    private _httpProvider: HttpService,
    private _elementRef: ElementRef,
    private _notificationsProvider: NotificationsService,
    private _renderer: Renderer2,
  ) { }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    this._createFormExtempPreparation();
    this._createCategories();
  }

  ngOnDestroy() {
    if (this._extempPreparationSubscription)
      this._extempPreparationSubscription.unsubscribe();
  }

  continue() {
    if (this.formExtempPreparation.value.categoryType === this.categoryTypes.simple) {
      this.onContinue.emit();
      return;
    }

    if (this._extempPreparationSubscription)
      this._extempPreparationSubscription.unsubscribe();

    this.isLoading = true;

    const extempPreparation$ = this._httpProvider.postExtempAdvancedCategories({teamId: this.teamId});
    this._extempPreparationSubscription = extempPreparation$
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(
        () => this.onContinue.emit(),
        (error) => this._notificationError( this._errors[error.message] )
      );
  }

  private _createCategories() {
    this.categories.simple.push({name: 'Domestic', label: 'D', color: '#2064A0'});
    this.categories.simple.push({name: 'Foreign', label: 'F', color: '#A52534'});
    this.categories.simple.push({name: 'Misc', label: 'M', color: '#9B9B9B'});
    // this.categories.simple.push({name: 'International', label: 'I', color: '#309c52'}); // -> international?

    this.categories.advanced.domestic.push({name: 'Domestic Politics', label: 'DP', color: '#2E8ADA'});
    this.categories.advanced.domestic.push({name: 'National Security', label: 'NS', color: '#003E73'});
    this.categories.advanced.domestic.push({name: 'Foreign Policy', label: 'FP', color: '#098F7E'});
    this.categories.advanced.domestic.push({name: 'Economics and Business', label: 'EB', color: '#0A9C44'});
    this.categories.advanced.domestic.push({name: 'Law and Courts', label: 'LC', color: '#634272'});
    this.categories.advanced.domestic.push({name: 'Social, Culture, and Tech', label: 'SC', color: '#7DC23B'});
    this.categories.advanced.domestic.push({name: 'Local and State', label: 'LS', color: '#11D984'});

    this.categories.advanced.foreign.push({name: 'Africa', label: 'AF', color: '#5E471F'});
    this.categories.advanced.foreign.push({name: 'America', label: 'AM', color: '#FF9B16'});
    this.categories.advanced.foreign.push({name: 'Asia and Pacific', label: 'AP', color: '#EA5B5D'});
    this.categories.advanced.foreign.push({name: 'Europe', label: 'EU', color: '#961A95'});
    this.categories.advanced.foreign.push({name: 'Middle East', label: 'ME', color: '#140000'});
    this.categories.advanced.foreign.push({name: 'Global Economy', label: 'GE', color: '#AE9743'});
    this.categories.advanced.foreign.push({name: 'International Orgs and Issues', label: 'II', color: '#6BBDFF'});

    this.categories.advanced.misc.push({name: 'Misc', label: 'M', color: '#9B9B9B'});
  }

  private _createFormExtempPreparation() {
    this.formExtempPreparation = new FormGroup({
      categoryType: new FormControl(this.categoryTypes.simple, [Validators.required])
    });
  }

  private _notificationError(label: string) {
    const notification = {
      for: 'error',
      showClose: true,
      timeout: 5000,
      label
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }
}