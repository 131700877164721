import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignupComponent } from "./pages/signup/signup.component";
import { SignupContinueComponent } from "./pages/signup-continue/signup-continue.component";
import { StatusComponent } from './modules/status/status.component';

const routes: Routes = [
  { path: 'signup', component: SignupComponent },
  { path: 'signup-continue', component: SignupContinueComponent },
  { path: 'status', component: StatusComponent },

  { path: '', redirectTo: 'signup', pathMatch: 'full' },
  { path: '**', redirectTo: 'signup', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }