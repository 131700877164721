import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pp-frame',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {
  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    this._renderer.setStyle(this._elementRef.nativeElement, 'backgroundImage', `url('assets/prepd-shared/img/png/signup-background.png`);
  }
}
