import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
  NgZone
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

import { Subscription } from "rxjs";
import { concatMap, finalize, debounceTime, map } from 'rxjs/operators';

import { BillingService } from "../../services/billing.service";
import { HttpService } from "../../../../services/http.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { UtilService } from "../../../../services/util.service";
import { ValidatorsService } from "../../../../services/validators.service";

import { CREATE_N_NOTIFICATION } from "../../../../models/constants";
import { BillingPaymentTypeFactory } from '../../models/payment';
import {
  IState,
  ITeamType
} from "../../../../models/interfaces";
import {
  Pricing,
  EPlan,
  TPlan
} from "../../models/pricing";
import {
  ETeamType,
  TeamTypeFactory,
  TTeamType
} from '../../../../models/team-type';
import {
  IBillingAppType,
  IBillingDiscount,
  IBillingPaymentCard,
  IBillingReferral,
  IBillingSubscriptionLength,
  IMonth,
  IYear,
  IBillingPaymentMethod,
  IBillingPaymentData,
} from '../../models/interfaces';
import {
  TBillingAppType,
  EBillingAppType,
  BillingAppTypeFactory
} from '../../models/app';
import { TBillingAction, EBillingAction } from '../../models/billing-action';

@Component({
  selector: 'billing-one-premium',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-one-premium.component.html',
  styleUrls: ['./billing-one-premium.component.scss']
})
export class BillingOnePremiumComponent implements OnDestroy, OnInit {
  @Input()
  appSelected: number;

  @Input()
  billingAction: TBillingAction | undefined;

  @Input()
  currentTeamType: TTeamType | undefined;

  @Input()
  for = 'extemp';

  @Input()
  isReferralRequired: boolean = true;

  @Input()
  paymentData: IBillingPaymentData = {};

  @Input()
  teamId: number;

  @Output()
  onFinish = new EventEmitter();

  currentStep: any;
  isLoading: boolean = false;
  pricing: Pricing;
  steps: any;

  planForm: FormGroup;
  paymentForm: FormGroup;
  
  readonly APPS_TYPE = EBillingAppType;
  readonly APPS_TYPE_ARRAY: IBillingAppType[];
  readonly PREMIUM_ONE: ITeamType = new TeamTypeFactory().createTeamType(ETeamType.PremiumOne);
  readonly REFERRALS: IBillingReferral[];
  readonly SUBSCRIPTION_LENGTH_OPTIONS: IBillingSubscriptionLength[];

  readonly MONTHS: IMonth[];
  readonly PAYMENT_METHODS: IBillingPaymentMethod[];
  readonly STATES: IState[];
  readonly YEARS: IYear[];

  private _discountListSubscription: Subscription;
  private _purchaseSubscription: Subscription;
  private _updateAppSubscription: Subscription;
  private _updateAppNoPriceSubscription: Subscription;
  private _updateDiscountSubscription: Subscription;
  private _updateSubscriptionLengthSubscription: Subscription;

  constructor(
    private _billingProvider: BillingService,
    private _httpProvider: HttpService,
    private _elementRef: ElementRef,
    private _notificationsProvider: NotificationsService,
    private _renderer: Renderer2,
    private _utilProvider: UtilService,
    private _validatorsProvider: ValidatorsService,
    private _zone: NgZone,
  ) {
    this._initSteps();

    this.APPS_TYPE_ARRAY = this._getAppsTypeArray();
    this.SUBSCRIPTION_LENGTH_OPTIONS = this._utilProvider.toArray( this._utilProvider.getSubscriptionLengthOptions() );
    if (this.isReferralRequired)
      this.REFERRALS = this._utilProvider.getReferralSources();

    this.PAYMENT_METHODS = new BillingPaymentTypeFactory().getPaymentArrayUsed();
    this.MONTHS = this._utilProvider.getMonths();
    this.STATES = this._utilProvider.toArray( this._utilProvider.getStates() );
    const currentYear = new Date().getFullYear();
    this.YEARS = this._utilProvider.getYears(currentYear, currentYear+10);
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    const app: IBillingAppType = this.APPS_TYPE_ARRAY.find((app: IBillingAppType) => app.id === this.appSelected) || this._getDefaultApp();
    const membersLimit: number = this._getDefaultUserNo();

    this.planForm = this._createPlanForm(this.isReferralRequired);
    this.planForm.patchValue({app, membersLimit});

    this.paymentForm = this._createPaymentForm();
    this.paymentForm.patchValue(this.paymentData.payment || {});

    this.pricing = this._getPricing(this.planForm);

    this.getDiscountList();

    const updateAppNoPrice$ = this._createUpdateAppNoPrice$(this.planForm);
    this._updateAppNoPriceSubscription = updateAppNoPrice$.subscribe();

    const updateSubscriptionLength$ = this._createUpdateSubscriptionLengthPrice$(this.planForm);
    this._updateSubscriptionLengthSubscription = updateSubscriptionLength$.subscribe();
  }

  ngOnDestroy() {
    if (this._discountListSubscription)
      this._discountListSubscription.unsubscribe();

    if (this._purchaseSubscription)
      this._purchaseSubscription.unsubscribe();

    if (this._updateAppSubscription)
      this._updateAppSubscription.unsubscribe();

    if (this._updateAppNoPriceSubscription)
      this._updateAppNoPriceSubscription.unsubscribe();

    if (this._updateDiscountSubscription)
      this._updateDiscountSubscription.unsubscribe();

    if (this._updateSubscriptionLengthSubscription)
      this._updateSubscriptionLengthSubscription.unsubscribe();
  }

  selectReferral(referral: IBillingReferral) {
    this.REFERRALS.forEach((ref: IBillingReferral) => {
      if (this.planForm.get(ref.detailsRequired)) {
        this.planForm.removeControl(ref.detailsRequired);
      }
    })

    if ( referral.detailsRequired )
      this.planForm.addControl(referral.detailsRequired, new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]));
  }

  getDiscountList() {
    if (this._discountListSubscription)
      this._discountListSubscription.unsubscribe();

    const dataToSend = {
      teamId: this.teamId
    };

    this.isLoading = true;

    const discountList$ = this._createDiscountList$(dataToSend);
    this._discountListSubscription = discountList$
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(
        (discountList: IBillingDiscount[]) => {
          this.planForm.addControl('discount', new FormControl(''));
          this.pricing.setDiscounts(discountList);

          const updateDiscount$ = this._createUpdateDiscountPrice$(this.planForm);
          this._updateDiscountSubscription = updateDiscount$.subscribe();

          if (this.pricing.discountsSelectable.length > 1)
            this.planForm.get('discount').setValue(this._getDefaultDiscount());
        },
        () => {}
      );
  }

  finish(dataToSend) {
    this.onFinish.emit(dataToSend);
  }

  goToStep(index: number = 0, formGroup?: FormGroup) {
    if (formGroup && this._manageErrorMessages(formGroup))
      return;

    if (!this.steps[index].isAccessible)
      return;

    const length = this.steps.length;

    if ( index < 0 || index > length ) return;
    else this._setCurrentStep(this.steps[index]);
  }

  nextStep(formGroup?: FormGroup): void {
    if (formGroup && this._manageErrorMessages(formGroup))
      return;

    let index = this.steps.indexOf(this.currentStep) + 1;
    index = index > 0 ? index : 1;
    this.steps[index].isAccessible = true;
    this.goToStep(index, formGroup);
  }

  previousStep(): void {
    let index = this.steps.indexOf(this.currentStep) - 1;
    index = index >= 0 ? index : 0;
    this.goToStep(index);
  }

  purchase() {
    if (this._purchaseSubscription)
      this._purchaseSubscription.unsubscribe();

    this.isLoading = true;
    let app: TBillingAppType;

    this._purchaseSubscription = this._billingProvider.getData({
      teamId: this.teamId,
      teamType: ETeamType.PremiumOne,
      forms: [
        this.planForm,
        this.paymentForm,
        undefined
      ],
      action: this.billingAction
    })
    .pipe(
      concatMap((dataToSend: any) => {
        app = dataToSend.paymentData.plan.apps;
        return this._createPurchase$(dataToSend);
      }),
      finalize(() => this.isLoading = false),
    ).subscribe(
      (result) => this._zone.run(() => this.finish({app: app, newTeamId: result.teamId})),
      (error) => {
        this._zone.run(() => {
          this._showHttpErrorNotification(error);
        });
      }
    );
  }

  private _createPlanForm(isReferralRequired: boolean): FormGroup {
    let form = new FormGroup({
      app: new FormControl( null, Validators.required),
      subscriptionLength: new FormControl( this._getDefaultSubscriptionLength(), Validators.required),
      membersLimit: new FormControl( null, Validators.required)
    });

    if (isReferralRequired)
      form.addControl('referral', new FormControl(null, Validators.required))

    return form;
  }

  private _createPaymentForm(): FormGroup {
    let form: FormGroup = new FormGroup({
      payment: new FormControl(this._getDefaultPaymentMethod(), Validators.required),
      address: new FormControl(null, Validators.required),
      cardNumber: new FormControl(null,
        [Validators.required,
         Validators.pattern(this._utilProvider.getCardNumberRegex())
        ]),
      city: new FormControl(null, Validators.required),
      cvc: new FormControl(null,
        [Validators.required,
         Validators.minLength(3),
         Validators.maxLength(4),
         Validators.pattern(this._utilProvider.getCVCRegex())
        ]),
      firstName: new FormControl(null,
        [Validators.required,
         Validators.minLength(1),
         Validators.maxLength(100)
        ]),
      lastName: new FormControl(null,
        [Validators.required,
         Validators.minLength(1),
         Validators.maxLength(100)
        ]),
      expMonth: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      expYear: new FormControl(null, Validators.required),
      zip: new FormControl(null,
        [Validators.required,
         Validators.pattern(this._utilProvider.getZipRegex())
        ])
    });

    return form;
  }

  private _createUpdateAppNoPrice$(planForm: FormGroup) {
    return planForm.get('app')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((option) => this.pricing.setAppsNo(option.no)),
      );
  }

  private _createUpdateSubscriptionLengthPrice$(planForm: FormGroup) {
    return planForm.get('subscriptionLength')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((option) => this.pricing.setSubscriptionLength(option.value)),
      );
  }

  private _createUpdateDiscountPrice$(planForm: FormGroup) {
    return planForm.get('discount')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((discount: IBillingDiscount) => this.pricing.setDiscount(discount)),
      );
  }

  private _createDiscountList$(dataToSend) {
    return this._httpProvider.postDiscountList(dataToSend)
      .pipe(
        map((response: any) => response.result.discounts),
        map((result: any[]) => {
          return result.map((rawDiscount) => this._utilProvider.copy<IBillingDiscount>(rawDiscount))
        }),
      );
  }

  private _createPurchase$(dataToSend) {
    return this._billingProvider.getActionRoute$(this.billingAction, dataToSend)
      .pipe(
        map((response: any) => response.result),
      );
  }

  private _getAppsTypeArray(): IBillingAppType[] {
    const array = [EBillingAppType.ExtempCongress, EBillingAppType.Extemp, EBillingAppType.Congress];
    const appTypeFactory = new BillingAppTypeFactory();

    return array
      .map((id: TBillingAppType) => appTypeFactory.createAppType(id));
  }

  private _getDefaultCurrentStep(): any {
    return this.steps[0];
  }

  private _getDefaultApp(): IBillingAppType {
    return this.APPS_TYPE_ARRAY[0];
  }

  private _getDefaultDiscount(): IBillingDiscount {
    return this.pricing.discountsSelectable[1];
  }

  private _getDefaultSubscriptionLength(): IBillingSubscriptionLength {
    return this.SUBSCRIPTION_LENGTH_OPTIONS[1];
  }

  private _getDefaultUserNo(): number {
    return 0;
  }

  private _getDefaultPaymentMethod(): IBillingPaymentMethod {
    return this.PAYMENT_METHODS[0];
  }

  private _getPricing(planForm) {
    const planValue = this.planForm.getRawValue();
    const usersNo = this._utilProvider.getUsers(ETeamType.PremiumOne);
    let plan: TPlan;

    switch(this.billingAction) {
      case EBillingAction.Renew:
        plan = EPlan.Renew;
        break;
      case EBillingAction.Upgrade:
        if (this.currentTeamType === ETeamType.PremiumOne)
          plan = EPlan.Upgrade;
        else
          plan = EPlan.OnePremiumCreation
        break;
      case EBillingAction.Create:
        plan = EPlan.OnePremiumCreation;
        break;
    }

    return new Pricing(plan,
      usersNo[ planValue.membersLimit ],
      planValue.subscriptionLength.value,
      planValue.app.no,
      planValue.discount,
      this.paymentData
    );
  }

  private _initSteps(): void {
    this.steps = [
      { label: '1. Plan', value: 'plan', isAccessible: true },
      { label: '2. Payment', value: 'payment', isAccessible: false },
      { label: '3. Checkout', value: 'checkout', isAccessible: false }
    ];
    this._setCurrentStep( this._getDefaultCurrentStep() );
  }

  private _manageErrorMessages(formGroup: FormGroup) {
    switch(formGroup) {
      case this.planForm:
        return this._billingProvider.checkFormErrors(formGroup, 'plan');
      case this.paymentForm:
        return this._billingProvider.checkFormErrors(formGroup, 'payment');
      default:
        return true;
    }
  }

  private _setCurrentStep(step: any) {
    this.currentStep = step;
  }

  private _showHttpErrorNotification(error) {
    const message = this._validatorsProvider.getHttpError(error.message);

    const notification = {
      for: 'error',
      showClose: true,
      timeout: 5000,
      label: message || error.message
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }
}