import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SignupComponent } from './pages/signup/signup.component';
import { SignupContinueComponent } from './pages/signup-continue/signup-continue.component';

import { FrameModule } from './modules/frame/frame.module';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { SignupModule } from './modules/signup/signup.module';
import { StatusModule } from './modules/status/status.module';

import { HttpService } from './services/http.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { NotificationsService } from './services/notifications.service';

const modules = [
  FrameModule,
  HttpClientModule,
  NotificationsModule,
  SignupModule,
  StatusModule,
];

const services = [
  HttpService, 
  NotificationsService,
];

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SignupContinueComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    ...modules,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },

    ...services,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
