import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
  NgZone
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

import { Subscription } from "rxjs";

import { BillingService } from "../../services/billing.service";
import { HttpService } from "../../../../services/http.service";
import { NotificationsService } from "../../../../services/notifications.service";
import { UtilService } from "../../../../services/util.service";
import { ValidatorsService } from "../../../../services/validators.service";

import { CREATE_N_NOTIFICATION } from "../../../../models/constants";
import { BillingPaymentTypeFactory, EBillingPaymentType } from '../../models/payment';
import {
  IState,
  ITeamType
} from "../../../../models/interfaces";
import {
  Pricing,
  EPlan,
  TPlan
} from "../../models/pricing";
import {
  TeamTypeFactory,
  ETeamType,
  TTeamType
} from '../../../../models/team-type';
import {
  IBillingAppType,
  IBillingDiscount,
  IBillingPaymentCard,
  IBillingPaymentMethod,
  IBillingReferral,
  IBillingSubscriptionLength,
  IBillingWelcomePackageData,
  IMonth,
  IYear,
  IBillingPaymentData,
} from '../../models/interfaces';
import {
  TBillingAppType,
  EBillingAppType,
  BillingAppTypeFactory
} from '../../models/app';
import { TBillingAction, EBillingAction } from '../../models/billing-action';
import { FormArray } from '@angular/forms/src/model';
import { EUserRole } from '../../../../models/user-role';
import { concatMap, finalize, map, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'billing-team-premium-upgrade',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-team-premium-upgrade.component.html',
  styleUrls: ['./billing-team-premium-upgrade.component.scss']
})
export class BillingTeamPremiumUpgradeComponent implements OnDestroy, OnInit {
  @Input()
  appSelected: number;

  @Input()
  for = 'extemp';

  @Input()
  isReferralRequired: boolean = true;

  @Input()
  membersLimit: number = this._getDefaultUserNo();

  @Input()
  paymentData: IBillingPaymentData = {};

  @Input()
  teamId: number;

  @Input()
  teamForm: FormGroup;

  @Output()
  onFinish = new EventEmitter();

  currentStep: any;
  isLoading: boolean = false;
  paymentForm: FormGroup;
  planForm: FormGroup;
  pricing: Pricing;
  steps: any;
  
  readonly APPS_TYPE_ARRAY: IBillingAppType[];
  readonly APPS_TYPE = EBillingAppType;
  readonly TEAM_PREMIUM: ITeamType = new TeamTypeFactory().createTeamType(ETeamType.PremiumTeam);
  readonly REFERRALS: IBillingReferral[];
  readonly SUBSCRIPTION_LENGTH_OPTIONS: IBillingSubscriptionLength[];
  readonly USERS_NO: number[];

  readonly CHECK_PRICE_LIMIT: number = 99;
  readonly MONTHS: IMonth[];
  readonly PAYMENT_METHODS: IBillingPaymentMethod[];
  readonly STATES: IState[];
  readonly YEARS: IYear[];
  readonly BillingPaymentType = EBillingPaymentType;

  private _membersLimit: number;

  private _discountListSubscription: Subscription;
  private _purchaseSubscription: Subscription;
  private _updateAppSubscription: Subscription;
  private _updateAppNoPriceSubscription: Subscription;
  private _updateDiscountSubscription: Subscription;
  private _updateMembersLimitSubscription: Subscription;
  private _updateSubscriptionLengthSubscription: Subscription;

  constructor(
    private _billingProvider: BillingService,
    private _httpProvider: HttpService,
    private _elementRef: ElementRef,
    private _notificationsProvider: NotificationsService,
    private _renderer: Renderer2,
    private _utilProvider: UtilService,
    private _validatorsProvider: ValidatorsService,
    private _zone: NgZone,
  ) {
    this._initSteps();

    this.APPS_TYPE_ARRAY = this._getAppsTypeArray();
    this.SUBSCRIPTION_LENGTH_OPTIONS = this._utilProvider.toArray( this._utilProvider.getSubscriptionLengthOptions() );
    if (this.isReferralRequired)
      this.REFERRALS = this._utilProvider.getReferralSources();
    this.USERS_NO = this._utilProvider.getUsers(ETeamType.PremiumTeam);
      
    this.PAYMENT_METHODS = new BillingPaymentTypeFactory().getPaymentArrayUsed();
    this.MONTHS = this._utilProvider.getMonths();
    this.STATES = this._utilProvider.toArray( this._utilProvider.getStates() );
    const currentYear = new Date().getFullYear();
    this.YEARS = this._utilProvider.getYears(currentYear, currentYear+10);
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);
      
    const app: IBillingAppType = this.APPS_TYPE_ARRAY.find((a: IBillingAppType) => a.id === this.appSelected);
    const membersLimit: number = this.USERS_NO.indexOf(this.membersLimit) > -1 ? this.USERS_NO.indexOf(this.membersLimit) : this.USERS_NO.indexOf(this._getDefaultUserNo());
    
    if (this.membersLimit)
      this._membersLimit = this.USERS_NO.indexOf(this.membersLimit);

    this.planForm = this._createPlanForm(this.isReferralRequired);
    this._patchValuePlanForm(app, membersLimit, this.appSelected);

    this.paymentForm = this._createPaymentForm(this.paymentData.payment);
    this.paymentForm.patchValue(this.paymentData.payment || {});

    this.pricing = this._getPricing(this.planForm);

    this.getDiscountList();

    const updateAppNoPrice$ = this._createUpdateAppNoPrice$(this.planForm);
    this._updateAppNoPriceSubscription = updateAppNoPrice$.subscribe();

    const updateMembersLimit$ = this._createUpdateMembersLimitPrice$(this.planForm);
    this._updateMembersLimitSubscription = updateMembersLimit$.subscribe();

    const updateSubscriptionLength$ = this._createUpdateSubscriptionLengthPrice$(this.planForm);
    this._updateSubscriptionLengthSubscription = updateSubscriptionLength$.subscribe();

    const updateApp$ = this._createUpdateApp$(this.planForm);
    this._updateAppSubscription = updateApp$.subscribe();
  }

  ngOnDestroy() {
    if (this._discountListSubscription)
      this._discountListSubscription.unsubscribe();

    if (this._purchaseSubscription)
      this._purchaseSubscription.unsubscribe();

    if (this._updateAppSubscription)
      this._updateAppSubscription.unsubscribe();

    if (this._updateAppNoPriceSubscription)
      this._updateAppNoPriceSubscription.unsubscribe();

    if (this._updateDiscountSubscription)
      this._updateDiscountSubscription.unsubscribe();

    if (this._updateMembersLimitSubscription)
      this._updateMembersLimitSubscription.unsubscribe();

    if (this._updateSubscriptionLengthSubscription)
      this._updateSubscriptionLengthSubscription.unsubscribe();
  }

  get isMaxMembership(): boolean {
    if (this.appSelected === EBillingAppType.ExtempCongress && this.membersLimit === this.USERS_NO[ this.USERS_NO.length - 1 ])
      return true;

    return false;
  }

  changeEvent($event) {
    if (!this.membersLimit)
      return;

    if ($event.target.value < this._membersLimit)
      this.planForm.get('membersLimit').setValue(this._membersLimit);
  }

  finish(dataToSend) {
    this.onFinish.emit(dataToSend);
  }

  goToStep(index: number = 0, formGroup?: FormGroup) {
    if ( formGroup )
      if (this._manageErrorMessages(formGroup))
        return;

    if (!this.steps[index].isAccessible)
      return;

    const length = this.steps.length;

    if ( index < 0 || index > length ) return;
    else this._setCurrentStep(this.steps[index]);
  }

  nextStep(formGroup?: FormGroup) {
    if (formGroup)
      if (this._manageErrorMessages(formGroup))
        return;

    let index = this.steps.indexOf(this.currentStep) + 1;
    index = index > 0 ? index : 1;
    this.steps[index].isAccessible = true;
    this.goToStep(index, formGroup);
  }

  previousStep() {
    let index = this.steps.indexOf(this.currentStep) - 1;
    index = index >= 0 ? index : 0;
    this.goToStep(index);
  }

  purchase() {
    if (this._purchaseSubscription)
      this._purchaseSubscription.unsubscribe();

    this.isLoading = true;
    let app: TBillingAppType;

    this._purchaseSubscription = this._billingProvider.getData({
      teamId: this.teamId,
      teamType: ETeamType.PremiumTeam,
      forms: [
        this.planForm,
        this.paymentForm,
        this.teamForm
      ],
      action: EBillingAction.Upgrade
    })
    .pipe(
      concatMap((dataToSend: any) => {
        app = dataToSend.paymentData.plan.apps;
        return this._createPurchase$(dataToSend);
      }),
      finalize(() => this.isLoading = false),
    ).subscribe(
      (result: any) => this._zone.run(() => this.finish({app: app, newTeamId: result.teamId})),
      (error) => {
        this._zone.run(() => {
          this._showHttpErrorNotification(error);
        });
      }
    );
  }

  selectReferral(referral: IBillingReferral) {
    this.REFERRALS.forEach((ref: IBillingReferral) => {
      if (this.planForm.get(ref.detailsRequired)) {
        this.planForm.removeControl(ref.detailsRequired);
      }
    })

    if ( referral.detailsRequired )
      this.planForm.addControl(referral.detailsRequired, new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]));
  }

  getDiscountList() {
    if (this._discountListSubscription)
      this._discountListSubscription.unsubscribe();

    const dataToSend = {teamId: this.teamId};

    this.isLoading = true;

    const discountList$ = this._createDiscountList$(dataToSend);
    this._discountListSubscription = discountList$
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(
        (discountList: IBillingDiscount[]) => {
          this.planForm.addControl('discount', new FormControl(''));
          this.pricing.setDiscounts(discountList);

          const updateDiscount$ = this._createUpdateDiscountPrice$(this.planForm);
          this._updateDiscountSubscription = updateDiscount$.subscribe();

          if (this.pricing.discountsSelectable.length > 1)
            this.planForm.get('discount').setValue(this._getDefaultDiscount());
        },
        () => {}
      );
  }

  selectPaymentMethod(form: FormGroup, paymentMethod: IBillingPaymentMethod) {
    this._resetPaymentForm(form);

    switch(paymentMethod.id) {
      case EBillingPaymentType.Card:
        form.addControl('address', new FormControl(null, Validators.required));
        form.addControl('cardNumber', new FormControl(null, 
          [Validators.required,
          Validators.pattern(this._utilProvider.getCardNumberRegex())
          ]));
        form.addControl('city', new FormControl(null, Validators.required));
        form.addControl('cvc', new FormControl(null, 
          [Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
          Validators.pattern(this._utilProvider.getCVCRegex())
          ]));
        form.addControl('firstName', new FormControl(null, 
          [Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
          ]));
        form.addControl('lastName', new FormControl(null, 
          [Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
          ]));
        form.addControl('expMonth', new FormControl(null, Validators.required));
        form.addControl('state', new FormControl(null, Validators.required));
        form.addControl('expYear', new FormControl(null, Validators.required));
        form.addControl('zip', new FormControl(null, 
          [Validators.required,
          Validators.pattern(this._utilProvider.getZipRegex())
          ]));
        break;
      case EBillingPaymentType.CheckPO:
        break;
      case EBillingPaymentType.CheckIn:
        break;
      case EBillingPaymentType.CheckDT:
        form.addControl('schoolDistrictName', new FormControl(null, 
          [Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
          ]));
        form.addControl('otherSchools', new FormControl(null, 
          [Validators.required,
          Validators.minLength(1),
          Validators.maxLength(300)
          ]));
        form.addControl('administrativeContact', new FormControl(null, 
          [Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
          ]));
        form.addControl('email', new FormControl(null, 
          [Validators.required,
          Validators.pattern(this._utilProvider.getEmailRegex())
          ]));
        form.addControl('title', new FormControl(null,
          [Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
          ]));
        break;
      default:
        break;
    }
  }

  private _createDiscountList$(dataToSend) {
    return this._httpProvider.postDiscountList(dataToSend)
      .pipe(
        map((response: any) => response.result.discounts),
        map((result: any[]) => {
          return result.map((rawDiscount) => this._utilProvider.copy<IBillingDiscount>(rawDiscount))
        }),
      );
  }

  private _createPurchase$(dataToSend) {
    return this._billingProvider.getActionRoute$(EBillingAction.Upgrade, dataToSend)
      .pipe(
        map((response: any) => response.result),
      );
  }

  private _createUpdateApp$(planForm: FormGroup) {
    return planForm.get('addApp')
      .valueChanges
      .pipe(
        debounceTime(100),
        map((value) => {
          const valuesRaw = (this.planForm.get('addApp') as FormGroup).getRawValue();
          const ids = Object.keys(valuesRaw);
          let app: IBillingAppType;

          if (ids.every((id) => valuesRaw[id]))
            app = new BillingAppTypeFactory().createAppType(EBillingAppType.ExtempCongress)
          else
            app = new BillingAppTypeFactory().createAppType( parseInt(ids.find((id) => valuesRaw[id]) ))

          planForm.get('app').setValue(app);
        }),
      );
  }

  private _createUpdateAppNoPrice$(planForm: FormGroup) {
    return planForm.get('app')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((option) => {
          this.pricing.setAppsNo(option.no);
        }),
      );
  }

  private _createUpdateDiscountPrice$(planForm: FormGroup) {
    return planForm.get('discount')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((discount: IBillingDiscount) => {
          this.pricing.setDiscount(discount);
        }),
      );
  }

  private _createUpdateSubscriptionLengthPrice$(planForm: FormGroup) {
    return planForm.get('subscriptionLength')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((option) => {
          this.pricing.setSubscriptionLength(option.value);
        }),
      );
  }

  private _createUpdateMembersLimitPrice$(planForm: FormGroup) {
    return planForm.get('membersLimit')
      .valueChanges
      .pipe(
        debounceTime(200),
        map((option) => {
          this.pricing.setUsersNo(this.USERS_NO[option]);
        }),
      );
  }

  private _createPlanForm(isReferralRequired: boolean): FormGroup {
    let form = new FormGroup({
      addApp: new FormGroup({
        [EBillingAppType.Extemp]: new FormControl(null, Validators.required),
        [EBillingAppType.Congress]: new FormControl(null, Validators.required)
      }),
      app: new FormControl( null, Validators.required),
      subscriptionLength: new FormControl( this._getDefaultSubscriptionLength(), Validators.required),
      membersLimit: new FormControl( null, Validators.required)
    });

    if (isReferralRequired)
      form.addControl('referral', new FormControl(null, Validators.required))

    return form;
  }

  private _createPaymentForm(paymentData: IBillingPaymentCard): FormGroup {
    const paymentType: IBillingPaymentMethod = paymentData ? paymentData.payment || this._getDefaultPaymentMethod() : this._getDefaultPaymentMethod();

    let form = new FormGroup({
      payment: new FormControl(paymentType, Validators.required)
    });

    this.selectPaymentMethod( form, paymentType );

    return form;
  }

  private _getAppsTypeArray(): IBillingAppType[] {
    const array = [EBillingAppType.Extemp, EBillingAppType.Congress];
    const appTypeFactory = new BillingAppTypeFactory();

    return array
      .map((id: TBillingAppType) => appTypeFactory.createAppType(id));
  }

  private _getDefaultCurrentStep(): any {
    return this.steps[0];
  }

  private _getDefaultApp(): IBillingAppType {
    return this.APPS_TYPE_ARRAY[0];
  }

  private _getDefaultDiscount(): IBillingDiscount {
    return this.pricing.discountsSelectable[1];
  }

  private _getDefaultSubscriptionLength(): IBillingSubscriptionLength {
    return this.SUBSCRIPTION_LENGTH_OPTIONS[1];
  }

  private _getDefaultUserNo(): number {
    return 2;
  }

  private _getDefaultPaymentMethod(): IBillingPaymentMethod {
    return new BillingPaymentTypeFactory().getPaymentObjectById()[ EBillingPaymentType.Card ];
  }

  private _getPricing(planForm) {
    const planValue = this.planForm.getRawValue();

    return new Pricing(EPlan.Upgrade,
      this.USERS_NO[ planValue.membersLimit ],
      planValue.subscriptionLength.value,
      planValue.app.no,
      planValue.discount,
      this.paymentData
    );
  }

  private _initSteps(): void {
    this.steps = [
      { label: '1. Plan', value: 'plan', isAccessible: true },
      { label: '2. Payment', value: 'payment', isAccessible: false },
      { label: '3. Checkout', value: 'checkout', isAccessible: false}
    ];
    this._setCurrentStep( this._getDefaultCurrentStep() );
  }

  private _setCurrentStep(step: any) {
    this.currentStep = step;
  }

  private _manageErrorMessages(formGroup: FormGroup) {
    switch(formGroup) {
      case this.planForm:
        return this._billingProvider.checkFormErrors(formGroup, 'plan');
      case this.paymentForm:
        return this._billingProvider.checkFormErrors(formGroup, 'payment');
      default:
        return true;
    }
  }

  private _patchValuePlanForm(app, membersLimit, appSelected: TBillingAppType) {
    if (!app && this.appSelected)
      app = new BillingAppTypeFactory().createAppType(appSelected);
    else if (!app)
      app = this._getDefaultApp();

    this.planForm.patchValue({ app, membersLimit });

    if (!appSelected)
      return;

    const extempValue: boolean = appSelected === EBillingAppType.Extemp || appSelected === EBillingAppType.ExtempCongress ? true : false;
    const congressValue: boolean = appSelected === EBillingAppType.Congress || appSelected === EBillingAppType.ExtempCongress ? true : false;

    this.planForm.patchValue({
      addApp: {
        [EBillingAppType.Extemp]: extempValue,
        [EBillingAppType.Congress]: congressValue
      }
    });

    if (extempValue)
      this.planForm.get('addApp').get(`${EBillingAppType.Extemp}`).disable();

    if (congressValue)
      this.planForm.get('addApp').get(`${EBillingAppType.Congress}`).disable();
  }

  private _resetPaymentForm(form: FormGroup) {
    form.removeControl('address');
    form.removeControl('cardNumber');
    form.removeControl('city');
    form.removeControl('cvc');
    form.removeControl('firstName');
    form.removeControl('lastName');
    form.removeControl('expMonth');
    form.removeControl('state');
    form.removeControl('expYear');
    form.removeControl('zip');

    form.removeControl('schoolDistrictName');
    form.removeControl('otherSchools');
    form.removeControl('administrativeContact');
    form.removeControl('email');
    form.removeControl('title');
  }

  private _resetSteps() {
    const stepDefault = this._getDefaultCurrentStep();
    this._setCurrentStep(stepDefault);
    this.steps.forEach((step) => {
      if (stepDefault.value === step.value) step.isAccessible = true;
      else step.isAccessible = false;
    })
  }

  private _showHttpErrorNotification(error) {
    const message = this._validatorsProvider.getHttpError(error.message);

    const notification = {
      for: 'error',
      showClose: true,
      timeout: 5000,
      label: message || error.message
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }

  private _showNotificationError(message: string) {
    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: {
        for: 'error',
        showClose: true,
        timeout: 5000,
        label: message
      }
    });
  }
}
