import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrameComponent } from './frame.component';

import { CardModule } from '../card/card.module';

const modules = [
  CardModule
];

@NgModule({
  imports: [
    CommonModule,

    ...modules
  ],
  declarations: [FrameComponent],
  exports: [FrameComponent]
})
export class FrameModule { }
