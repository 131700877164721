import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            // const notification = {
            //   for: 'error',
            //   label: 'You have been logged out.',
            //   timeout: 5000
            // };
            // this.notificationsProvider.dispatch({
            //   type: CREATE_N_NOTIFICATION,
            //   payload: notification
            // });
  
            this.router.navigate(['login']);
          }
          return throwError(error.error);
        })
      );
  }
}
