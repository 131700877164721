import {
  Component,
  ViewEncapsulation
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { NotificationsService } from "../../../services/notifications.service";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'pp-notifications',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fly', [
      state('in', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateX(100%)'
        }),
        animate('.16s ease-in')
      ]),
      transition('* => void', [
        animate('.16s ease-out', style({
          opacity: 0,
          transform: 'translateX(100%)'
        }))
      ])
    ])
  ],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  notificationsStore$: BehaviorSubject<any> = this._notificationsProvider.notificationsStore$;

  constructor(private _notificationsProvider: NotificationsService) {}
}