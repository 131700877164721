import { ITeamType } from "../models/interfaces";

export enum ETeamType {
  PremiumTeam = 1,  // PRO_TEAM  (1L, "Premium Team"); // paid team
  PremiumOne = 2,   // PRO_ONE   (2L, "Premium One");  // single user
  FreeTeam = 3,     // FREE_TEAM (3L, "Free Team");    // free team
  FreeOne = 4,      // FREE_ONE  (4L, "Free One");     // free user
  NA = -1           // UNDEFINED (-1L, "NA");          // no team
}

export type TTeamType =
  ETeamType.PremiumTeam |
  ETeamType.PremiumOne |
  ETeamType.FreeTeam |
  ETeamType.FreeOne |
  ETeamType.NA;

export class TeamType implements ITeamType {
  readonly id: number;
  readonly label: string;
  
  constructor(id: number, label: string) {
    this.id = id;
    this.label = label;
  }
}

export class TeamTypeFactory {
  constructor() {}

  public createTeamType(id: TTeamType): ITeamType {
    switch(id) {
      case ETeamType.PremiumTeam:
        return new TeamType(ETeamType.PremiumTeam, 'Premium Team');
      case ETeamType.PremiumOne:
        return new TeamType(ETeamType.PremiumOne, 'Premium One');
      case ETeamType.FreeTeam:
        return new TeamType(ETeamType.FreeTeam, 'Free Team');
      case ETeamType.FreeOne:
        return new TeamType(ETeamType.FreeOne, 'Free One');
      default:
        return new TeamType(ETeamType.NA, 'UNDEFINED');
    }
  }

  public getTeamTypeObject(): { [key: string]: ITeamType } {
    return {
      [ETeamType.PremiumTeam]: this.createTeamType(ETeamType.PremiumTeam),
      [ETeamType.PremiumOne]: this.createTeamType(ETeamType.PremiumOne),
      [ETeamType.FreeTeam]: this.createTeamType(ETeamType.FreeTeam),
      [ETeamType.FreeOne]: this.createTeamType(ETeamType.FreeOne),
      [ETeamType.NA]: this.createTeamType(ETeamType.NA)
    };
  }

  public getTeamTypeArray(): ITeamType[] {
    let roles: any[] = [ETeamType.PremiumTeam, ETeamType.PremiumOne, ETeamType.FreeTeam, ETeamType.FreeOne, ETeamType.NA];

    return roles.map((value) => this.createTeamType(value));
  }
}