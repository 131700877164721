import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {
  Router
} from "@angular/router";

import { Pricing, EPlan } from "../../models/pricing";
import { EBillingAction } from '../../models/billing-action';

@Component({
  selector: 'billing-one-creation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-one-creation.component.html',
  styleUrls: ['./billing-one-creation.component.scss']
})
export class BillingOneCreationComponent implements OnInit {
  @Input()
  for = 'extemp';

  @Input()
  teamId: number;

  BillingAction = EBillingAction;
  currentStep: any;
  pricingFreeOne: Pricing;
  pricingPremiumOne: Pricing;
  selectedPlan: string;

  readonly steps;

  constructor(private _elementRef: ElementRef,
              private _router: Router,
              private _renderer: Renderer2) {
    this.steps = {
      root: 'root',
      preparation: 'preparation',
      premium: 'premium'
    };
    this.pricingFreeOne = new Pricing(EPlan.OneFreeCreation, 1, 12, 1);
    this.pricingPremiumOne = new Pricing(EPlan.OnePremiumCreation, 1, 12, 1);
    this.setCurrentStep( this._getDefaultCurrentStep() );
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);
  }

  backToRoot() {
    this.setCurrentStep(this.steps.root);
  }

  goToStatus() {
    this._router.navigate(['/status'], { queryParams: {  type: '0', reason: '101', target: '1' } });
  }

  goToPreparation(selectedPlan: string = 'Premium One') {
    this.selectedPlan = selectedPlan;
    this.setCurrentStep(this.steps.preparation);
  }

  setCurrentStep(step: any) {
    this.currentStep = step;
  }

  private _getDefaultCurrentStep(): any {
    return this.steps.root;
  }
}
