import { Injectable } from '@angular/core';

import { Routes } from '../models/routes';
import { TTeamType, ETeamType } from '../models/team-type';
import { IBillingSubscriptionLength, IYear, IBillingReferral, IMonth, ISchoolType } from '../modules/billing/models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private readonly _argumentSide = {
    model: {
      1: { label: 'AFF', longLabel: 'Affirmative', value: 1, class: 'affirmative' },
      0: { label: 'NEG', longLabel: 'Negative', value: 0, class: 'negative' },
      2: { label: 'NEUTRAL', longLabel: 'Neutral', value: 2, class: 'neutral' }
    },
    valueAffirmative: 1,
    valueNegative: 0,
    valueNeutral: 2,
  }

  collator = Intl.Collator('en', { numeric: true });

  copy<T>(input: T): T {
    const result = <T>{};

    for (let key in input) {
      if (input.hasOwnProperty(key)) {
        result[key] = input[key];
      }
    }

    return result;
  }

  isEmpty(input) {
    return Object.keys(input).length === 0;
  }

  isBoolean(input) {
    return typeof input === 'boolean';
  }

  isNumber(input) {
    return typeof input === 'number';
  }

  makePicUrl(picId: number = 0) {
    if (picId)
      return `${Routes.pic}/${picId}`;

    return 'assets/prepd-shared/img/png/profile.png';
  }

  makeArticleViewArticlePdfUrl(teamId: number, documentId: number) {
    return `${Routes.articleViewArticlePdf}/${teamId}/${documentId}`;
  }

  makeDateString(date: number = 0) {
    return new Date(date).toJSON().split('T')[0];
  }

  makeDeferredNowTimestamp() {
    return Date.now() + 30000;
  }

  makeBasicAuthentication(email: string, password: string) {
    const token = `${email}:${password}`;
    const base64Token = this._toBase64(token);
    return `Basic ${base64Token}`;
  }

  private _toBase64(input: string) {
    return btoa(encodeURIComponent(input)
      .replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(`0x${p1}`));
      }));
  }

  getCategoriesColors() {
    return [
      'rgb( 99, 191,  88)',
      'rgb( 90, 176, 131)',
      'rgb( 91, 184, 205)',
      'rgb( 48, 123, 233)',
      'rgb( 30,  82, 159)',
      'rgb( 33,  40, 159)',
      'rgb( 95,  51, 177)',
      'rgb(251, 228, 120)',
      'rgb(224, 229,  74)',
      'rgb(189, 223,  68)',
      'rgb(144, 238,  77)',
      'rgb(242, 183,  89)',
      'rgb(237, 142,  55)',
      'rgb(139, 139, 139)',
      'rgb(143, 167, 168)',
      'rgb( 80, 157,  87)',
      'rgb(223,  61,  46)',
      'rgb(196,  53,  39)',
      'rgb(140,  45,  26)',
      'rgb(144,  98,  38)',
      'rgb(174, 135,  83)',
      'rgb(215, 196, 111)',
      'rgb(122,  66, 197)',
      'rgb(160,  67, 201)',
      'rgb(191,  82, 241)',
      'rgb(224,  88, 238)',
      'rgb(228,  80, 190)',
      'rgb(229,  74, 155)',
      'rgb(224,  66,  98)',
      'rgb(229,  64,  50)'
    ];
  }

  getStates() {
    return {
      'AL': { name: 'Alabama', abbreviation: 'AL' },
      'AK': { name: 'Alaska', abbreviation: 'AK' },
      'AS': { name: 'American Samoa', abbreviation: 'AS' },
      'AZ': { name: 'Arizona', abbreviation: 'AZ' },
      'AR': { name: 'Arkansas', abbreviation: 'AR' },
      'CA': { name: 'California', abbreviation: 'CA' },
      'CO': { name: 'Colorado', abbreviation: 'CO' },
      'CT': { name: 'Connecticut', abbreviation: 'CT' },
      'DE': { name: 'Delaware', abbreviation: 'DE' },
      'DC': { name: 'District of Columbia', abbreviation: 'DC' },
      'FM': { name: 'Fed. States of Micronesia', abbreviation: 'FM' },
      'FL': { name: 'Florida', abbreviation: 'FL' },
      'GA': { name: 'Georgia', abbreviation: 'GA' },
      'GU': { name: 'Guam', abbreviation: 'GU' },
      'HI': { name: 'Hawaii', abbreviation: 'HI' },
      'ID': { name: 'Idaho', abbreviation: 'ID' },
      'IL': { name: 'Illinois', abbreviation: 'IL' },
      'IN': { name: 'Indiana', abbreviation: 'IN' },
      'IA': { name: 'Iowa', abbreviation: 'IA' },
      'KS': { name: 'Kansas', abbreviation: 'KS' },
      'KY': { name: 'Kentucky', abbreviation: 'KY' },
      'LA': { name: 'Louisiana', abbreviation: 'LA' },
      'ME': { name: 'Maine', abbreviation: 'ME' },
      'MH': { name: 'Marshall Islands', abbreviation: 'MH' },
      'MD': { name: 'Maryland', abbreviation: 'MD' },
      'MA': { name: 'Massachusetts', abbreviation: 'MA' },
      'MI': { name: 'Michigan', abbreviation: 'MI' },
      'MN': { name: 'Minnesota', abbreviation: 'MN' },
      'MS': { name: 'Mississippi', abbreviation: 'MS' },
      'MO': { name: 'Missouri', abbreviation: 'MO' },
      'MT': { name: 'Montana', abbreviation: 'MT' },
      'NE': { name: 'Nebraska', abbreviation: 'NE' },
      'NV': { name: 'Nevada', abbreviation: 'NV' },
      'NH': { name: 'New Hampshire', abbreviation: 'NH' },
      'NJ': { name: 'New Jersey', abbreviation: 'NJ' },
      'NM': { name: 'New Mexico', abbreviation: 'NM' },
      'NY': { name: 'New York', abbreviation: 'NY' },
      'NC': { name: 'North Carolina', abbreviation: 'NC' },
      'ND': { name: 'North Dakota', abbreviation: 'ND' },
      'MP': { name: 'Northern Mariana Islands', abbreviation: 'MP' },
      'OH': { name: 'Ohio', abbreviation: 'OH' },
      'OK': { name: 'Oklahoma', abbreviation: 'OK' },
      'OR': { name: 'Oregon', abbreviation: 'OR' },
      'PW': { name: 'Palau', abbreviation: 'PW' },
      'PA': { name: 'Pennsylvania', abbreviation: 'PA' },
      'PR': { name: 'Puerto Rico', abbreviation: 'PR' },
      'RI': { name: 'Rhode Island', abbreviation: 'RI' },
      'SC': { name: 'South Carolina', abbreviation: 'SC' },
      'SD': { name: 'South Dakota', abbreviation: 'SD' },
      'TN': { name: 'Tennessee', abbreviation: 'TN' },
      'TX': { name: 'Texas', abbreviation: 'TX' },
      'UT': { name: 'Utah', abbreviation: 'UT' },
      'VT': { name: 'Vermont', abbreviation: 'VT' },
      'VI': { name: 'Virgin Islands', abbreviation: 'VI' },
      'VA': { name: 'Virginia', abbreviation: 'VA' },
      'WA': { name: 'Washington', abbreviation: 'WA' },
      'WV': { name: 'West Virginia', abbreviation: 'WV' },
      'WI': { name: 'Wisconsin', abbreviation: 'WI' },
      'WY': { name: 'Wyoming', abbreviation: 'WY' }
    };
  }

  getArgumentSide() {
    return this._argumentSide;
  }

  parseWordsApiResponse(result: any) {
    let wordData = {
      pronunciations: this._getPronunciations(result.pronunciation),
      definitions: [],
      synonym: [],
      antonym: [],
      topExample: []
    };

    if (result.results !== undefined) {
      result.results.forEach(element => {
        if (element.definition && element.partOfSpeech)
          wordData.definitions.push({ partOfSpeech: element.partOfSpeech, text: element.definition });
        if (element.synonyms)
          wordData.synonym = [...wordData.synonym, ...element.synonyms];
        if (element.antonyms)
          wordData.antonym = [...wordData.antonym, ...element.antonyms];
        if (element.examples)
          wordData.topExample = [...wordData.topExample, ...element.examples];
      });
    }
    return wordData;
  }

  private _getPronunciations(pronunciation: any) {
    if (typeof pronunciation === 'string') {
      return pronunciation;
    }
    if (typeof pronunciation === 'object') {
      return pronunciation.all;
    }
    return null;
  }

  getApps() {
    return {
      EXTEMP: {label: 'Extemp', value: 1, no: 1},
      CONGRESS: {label: 'Congress', value: 2, no: 1},
      EXTEMP_CONGRESS: {label: 'Extemp and Congress', value: 3, no: 2}
    };
  }

  getMonths(): IMonth[] {
    const array = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return array.reduce(( months: any[], label: string, value: number) => {
      months.push({label, value: value + 1});
      return months;
    }, []);
  }

  getSubscriptionLengthOptions(): {[key: number]: IBillingSubscriptionLength} {
    return {
      6: {label: '6 months', value: 6},
      12: {label: '12 months', value: 12}
    };
  }

  getYears(start: number, limit: number): IYear[] {
    let years: IYear[] = [];

    for (let i=start; i<limit ; i++) {
      years.push({label: i.toString(), value: i});
    }

    return years;
  }

  getReferralSources(): IBillingReferral[] {
    return [
      { label: 'From a coach', value: 'coach', detailsRequired: 'coachName' },
      { label: 'From a student', value: 'student', detailsRequired: 'studentName' },
      { label: 'From a camp', value: 'camp', detailsRequired: 'campName' },
      { label: 'Social Media', value: 'socialMedia', detailsRequired: '' },
      { label: 'Search engines', value: 'searchEngine', detailsRequired: '' },
      { label: 'SpeechGeek.com', value: 'speechGeek', detailsRequired: '' },
      { label: 'ExtempCentral.com', value: 'extempCentral', detailsRequired: '' },
      { label: 'ChampionBriefs.com', value: 'championBrief', detailsRequired: '' },
      { label: 'From the NSDA', value: 'nsda', detailsRequired: '' },
      { label: 'Reddit Debate', value: 'reddit', detailsRequired: ''},
      { label: 'None of the above', value: 'none', detailsRequired: '' }
    ];
  }

  getSchoolTypes(): {[key: string]: ISchoolType} {
    return {
      'public': { label: 'Public School', value: 'public' },
      'private': { label: 'Private School', value: 'private' },
      'home': { label: 'Home School', value: 'home' },
      'independent': { label: 'Independent', value: 'independent' }
    };
  }

  getUsers(teamType: TTeamType): number[] {
    if (teamType === ETeamType.FreeOne || teamType === ETeamType.PremiumOne)
      return [1];
    return [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 120, 140, 160, 180, 200];
  }

  getCardNumberRegex() {
    return /^[0-9]{10,19}$/;
  }

  getCVCRegex() {
    return /^[0-9]{3,4}$/;
  }

  getNumberRegex() {
    return /^\d+$/;
  }

  getEmailRegex() {
    return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i;
  }

  getEmailsRegex() {
    return /([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6})/gi;
  }

  getZipRegex() {
    return /^[0-9]{5}$/;
  }

  getMonthName(month: number) { //starting from 0
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[month];
  }

  toArray(states: any) {
    const keys = Object.keys(states);

    return keys.reduce((array, key: string) => {
      array.push( states[key] );
      return array;
    }, []);
  }
}
