import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatusComponent } from './status.component';

import { ButtonModule } from '../button/button.module';
import { FormUiModule } from '../form-ui/form-ui.module';
import { FrameModule } from '../frame/frame.module';

const components = [
  StatusComponent,
];

const modules = [
  ButtonModule,
  FormUiModule,
  FrameModule
];

@NgModule({
  imports: [
    CommonModule,

    ...modules,
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class StatusModule { }
