import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pp-category',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @Input() backgroundColor: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) { }

  ngOnInit() {
    if (this.backgroundColor)
      this._renderer.setStyle(this._elementRef.nativeElement, 'backgroundColor', this.backgroundColor);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['backgroundColor'].currentValue)
      this._renderer.setStyle(this._elementRef.nativeElement, 'backgroundColor', changes['backgroundColor'].currentValue);
  }
}
