import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  dropdowns = [];

  addDropdown(dropdown) {
    this.dropdowns.push(dropdown);
  }

  removeDropdown(dropdown) {
    const index = this.dropdowns.indexOf(dropdown);
    if (index > -1)
      this.dropdowns.splice(index, 1);
  }

  closeDropdowns() {
    this.dropdowns.forEach((dropdown) => {
      dropdown.closeDropdown();
    });
  }
}
