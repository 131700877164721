import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pp-dropdown-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {
  @Input('dropdownMenuPosition')
  position: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) {}

  ngOnInit() {
    if (this.position) {
      const placement = this.position.split(' ');
      this._renderer.addClass(this._elementRef.nativeElement, `-${placement[0]}`);
      this._renderer.addClass(this._elementRef.nativeElement, `-${placement[1]}`);
    }
  }
}
