import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  ElementRef,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'input[type=checkbox][pp-input-checkbox]',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit {
  @Input('inputCheckboxFor') for: string;
  @Input('inputCheckboxSize') size: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2) {}

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    if (this.size)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.size}`);
  }
}