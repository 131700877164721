import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

import { Subscription } from "rxjs";
import { finalize, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { HttpService } from '../../../../services/http.service';
import { NotificationsService } from "../../../../services/notifications.service";

import {
  IBillingTeam
} from '../../../../models/interfaces';
import { CREATE_N_NOTIFICATION } from "../../../../models/constants";
import { EUserRole, TUserRole } from '../../../../models/user-role';

@Component({
  selector: 'billing-team-join',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-team-join.component.html',
  styleUrls: ['./billing-team-join.component.scss']
})
export class BillingTeamJoinComponent implements OnInit {
  @Input()
  for: string = 'extemp';

  @Input()
  userType: TUserRole = EUserRole.Varsity;

  @Input()
  cancelButton: string = 'Cancel';

  @Output()
  onFinish = new EventEmitter();

  @Output()
  onCancel = new EventEmitter();

  currentTeam: IBillingTeam;
  formSearchByContent: FormGroup;
  formSearchByKey: FormGroup;
  isBadTeamKey: boolean = false;
  isLoading: boolean = false;
  searchTypes = {
    contentSearch: 0,
    teamKeySearch: 1
  };
  searchByKeyFControl: FormControl;
  searchByContentFControl: FormControl;
  searchTypeFControl: FormControl;
  teamListSBC: IBillingTeam[] = [];
  teamListSBK: IBillingTeam[] = [];
  UserRole = EUserRole;

  private _joinTeamSubscription: Subscription;
  private _requestJoinTeamSubscription: Subscription;
  private _searchTeamByContentSubscription: Subscription;
  private _searchTeamByKeySubscription: Subscription;
  private _searchTeamByContentFCSubscription: Subscription;
  private _searchTeamByKeyFCSubscription: Subscription;

  private readonly _errors = {
    'alreadyOnTeam': 'You are already a member of this team! ',
    'userLimit': 'Member limit for this team has been reached! ',
    'teamBlocked': 'This team is blocked. Please contact your coach or Prepd for more information',
    'badTeamKey': 'An error occured on the server regarding this team. Please contact Prepd! ',
    'teamFull': 'The team has reached its user limit. To join this team, please ask your coach to remove older members or increase the user limit through the Classroom Billing page. ',
    'noSpace': 'We\'re sorry, but you can\'t join this team right now. The team has reached its user limit. Ask your coach to increase the team\'s user limit through the Classroom or by contacting hello@prepd.in.',
    'noCoachFound': 'Team coach could not be found. Please contact hello@prepd.in',
    'noFreeMemberships': 'We\'re sorry, but you can\'t join this team right now. The team has reached its user limit. Ask your coach to increase the team\'s user limit through the Classroom or by contacting hello@prepd.in. Until then, you can complete the sign up process by joining Prepd without being a part of a team.',
    'noSuchRole': 'Oops! The selected role does not exist. Please select a different option or contact hello@prepd.in'
  };

  constructor(
    private _elementRef: ElementRef,
    private _httpProvider: HttpService,
    private _notificationsProvider: NotificationsService,
    private _renderer: Renderer2,
  ) { }

  ngOnInit() {
    this._createSearchByKeyFControl();
    this._createSearchByContentFControl();
    this._createSearchTypeFControl();
    this._createFormSearchByContent();
    this._createFormSearchByKey();

    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);
  }

  ngOnDestroy() {
    if (this._joinTeamSubscription)
      this._joinTeamSubscription.unsubscribe();

    if (this._requestJoinTeamSubscription)
      this._requestJoinTeamSubscription.unsubscribe();

    if ( this._searchTeamByContentSubscription )
      this._searchTeamByContentSubscription.unsubscribe();

    if ( this._searchTeamByKeySubscription )
      this._searchTeamByKeySubscription.unsubscribe();

    if ( this._searchTeamByContentFCSubscription )
      this._searchTeamByContentFCSubscription.unsubscribe();

    if ( this._searchTeamByKeyFCSubscription )
      this._searchTeamByKeyFCSubscription.unsubscribe();
  }

  joinTeam(role: TUserRole) {
    if (this._joinTeamSubscription)
      this._joinTeamSubscription.unsubscribe();

    const team: IBillingTeam = this.formSearchByKey.value.team;
    const dataToSend = { teamKey: team.key, role };

    this.isLoading = true;

    const joinTeam$ = this._httpProvider.postJoinTeam(dataToSend);
    this._joinTeamSubscription = joinTeam$
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(
        () => {
          let message: string;
          switch (role) {
            case EUserRole.Varsity:
            case EUserRole.Member:
              this._notification('success', `Request has been sent, please wait for approval from team's coach!`, 5000);
              this.onFinish.emit(({isJoinedTeam: true}));
              break;
            case EUserRole.Coach:
              this._notification('success', `Request has been sent, please wait for approval from team's coach!`, 5000);
              this.onFinish.emit(({isJoinedTeam: false}));
              break;
          }
        },
        (error) => this._notification('error', this._errors[error.message], 5000)
      );
  }

  requestJoinTeam(role: TUserRole) {
    if (this._requestJoinTeamSubscription)
      this._requestJoinTeamSubscription.unsubscribe();

    const team: IBillingTeam = this.formSearchByContent.value.team
    const dataToSend = { teamId: team.id, role };

    this.isLoading = true;

    const requestJoinTeam$ = this._httpProvider.postRequestJoinTeam(dataToSend);
    this._requestJoinTeamSubscription = requestJoinTeam$
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(
        () => {
          this._notification('success', `Request has been sent, please wait for approval from team's coach!`, 5000);
          this.onFinish.emit({isJoinedTeam: false});
        },
        (error) => this._notification('error', this._errors[error.message], 5000)
      );
  }

  searchTeamByContent(keyword: string) {
    if ( this._searchTeamByContentSubscription )
      this._searchTeamByContentSubscription.unsubscribe();

    const dataToSend = { keyword };

    const searchTeam$ = this._createSearchTeamByContent$(dataToSend);
    this._searchTeamByContentSubscription = searchTeam$
      .pipe(
        map((teamList: IBillingTeam[]) => this.teamListSBC = teamList),
      ).subscribe();
  }

  searchTeamByKey(teamKey: string) {
    if ( this._searchTeamByKeySubscription )
      this._searchTeamByKeySubscription.unsubscribe();

    const dataToSend = { teamKey };

    const searchTeam$ = this._createSearchTeamByKey$(dataToSend);
    this._searchTeamByKeySubscription = searchTeam$
      .pipe(
        map((teamList: IBillingTeam[]) => this.teamListSBK = teamList),
      ).subscribe(
        () => this.isBadTeamKey = false,
        (error) => {
          if (error.message === 'badTeamKey')
            this.isBadTeamKey = true;
        }
      );
  }

  setCurrentTeam(team: IBillingTeam) {
    this.currentTeam = team;
  }

  private _createSearchByKeyFControl() {
    const keyLength: number = 6;
    this.searchByKeyFControl = new FormControl('', [Validators.required, Validators.minLength(keyLength), Validators.maxLength(keyLength)]);

    this._searchTeamByKeyFCSubscription = this.searchByKeyFControl
      .valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((query: string) => {
        this.formSearchByKey.reset();

        if (this.searchByKeyFControl.valid)
          this.searchTeamByKey(query);
        else
          this.teamListSBK.splice(0,this.teamListSBK.length);
      });
  }

  private _createSearchByContentFControl() {
    this.searchByContentFControl = new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)])

    this._searchTeamByContentFCSubscription = this.searchByContentFControl
      .valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((query: string) => {
        this.formSearchByContent.reset();

        if (this.searchByContentFControl.valid)
          this.searchTeamByContent(query);
        else
          this.teamListSBC.splice(0,this.teamListSBC.length);
      });
  }

  private _createSearchTypeFControl() {
    this.searchTypeFControl = new FormControl(this.searchTypes.teamKeySearch);
  }

  private _createFormSearchByContent() {
    this.formSearchByContent = new FormGroup({
      team: new FormControl(null, Validators.required)
    });
  }

  private _createFormSearchByKey() {
    this.formSearchByKey = new FormGroup({
      team: new FormControl(null, Validators.required)
    });
  }

  private _createSearchTeamByContent$(dataToSend: any) {
    return this._httpProvider.postSearchTeamByContent(dataToSend)
      .pipe(
        map((response: any) => response.result.teams),
      );
  }

  private _createSearchTeamByKey$(dataToSend: any) {
    return this._httpProvider.postSignupTeamByKey(dataToSend)
      .pipe(
        map((response: any) => {
          let team: IBillingTeam = response.result;
          team.key = dataToSend.teamKey;
          return [team];
        }),
      );
  }

  private _notification(type: 'success' | 'error' | 'info', label: string, timeout: number) {
    const notification = {
      for: type,
      showClose: true,
      timeout,
      label
    };

    this._notificationsProvider.dispatch({
      type: CREATE_N_NOTIFICATION,
      payload: notification
    });
  }
}

enum EJoinTeam {
  JoinKey,
  RequestToJoin
};

type TJoinTeam =
  EJoinTeam.JoinKey |
  EJoinTeam.RequestToJoin;