import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

import { Subscription } from "rxjs";

import { BillingService } from "../../services/billing.service";
import { UtilService } from "../../../../services/util.service";

import { Pricing, EPlan } from "../../models/pricing";

import { ITeamType } from "../../../../models/interfaces";
import { ETeamType, TeamTypeFactory } from '../../../../models/team-type';
import { TBillingAction } from '../../models/billing-action';
import { EBillingAppType } from '../../models/app';
import { concatMap, finalize, map } from 'rxjs/operators';

@Component({
  selector: 'billing-team-free',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-team-free.component.html',
  styleUrls: ['./billing-team-free.component.scss']
})
export class BillingTeamFreeComponent implements OnDestroy, OnInit {
  @Input()
  for = 'extemp';

  @Input()
  billingAction: TBillingAction | undefined;

  @Input()
  teamId: number;

  @Input()
  teamForm: FormGroup;

  @Output()
  onFinish = new EventEmitter();

  planForm: FormGroup;
  isLoading: boolean = false;
  pricingFreeTeam: Pricing;
  pricingPremiumTeam: Pricing;  // temp

  private _purchaseSubscription: Subscription;

  readonly USERS_NO: number[];
  readonly TEAM_FREE: ITeamType;

  constructor(
    private _billingProvider: BillingService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _utilProvider: UtilService,
  ) {
    this.USERS_NO = this._utilProvider.getUsers(ETeamType.FreeTeam);
    this.TEAM_FREE = new TeamTypeFactory().createTeamType(ETeamType.FreeTeam);
  }

  ngOnInit() {
    if (this.for)
      this._renderer.addClass(this._elementRef.nativeElement, `-${this.for}`);

    this.pricingFreeTeam = new Pricing(EPlan.TeamFreeCreation, this._getDefaultUserNo(), 12, 2);
    this.pricingPremiumTeam = new Pricing(EPlan.TeamPremiumCreation, this._getDefaultUserNo(), 12, 2); // temp

    this._createPlanForm();
  }

  ngOnDestroy() {
    if (this._purchaseSubscription)
      this._purchaseSubscription.unsubscribe();
  }

  finish(dataToSend) {
    this.onFinish.emit(dataToSend);
  }

  purchase() {
    if (this._purchaseSubscription)
      this._purchaseSubscription.unsubscribe();

    this.isLoading = true;
      
    this._purchaseSubscription = this._billingProvider.getData({
      teamId: this.teamId,
      teamType: ETeamType.FreeTeam,
      forms: [
        this.planForm,
        undefined,
        this.teamForm
      ],
      action: this.billingAction
    })
    .pipe(
      concatMap((dataToSend: any) => this._createPurchase$(dataToSend)),
      finalize(() => this.isLoading = false),
    ).subscribe((result) => this.finish({app: EBillingAppType.ExtempCongress, newTeamId: result.teamId}));
  }

  private _createPlanForm() {
    this.planForm = new FormGroup({
      membersLimit: new FormControl(this._getDefaultUserNo(), Validators.required)
    });
  }

  private _createPurchase$(dataToSend) {
    return this._billingProvider.getActionRoute$(this.billingAction, dataToSend)
      .pipe(
        map((response: any) => response.result),
      );
  }

  private _getDefaultUserNo(): number {
    return 0;
  }

  private _manageErrorMessages(formGroup: FormGroup) {
    switch(formGroup) {
      case this.planForm:
        return this._billingProvider.checkFormErrors(formGroup, 'plan');
      default:
        return true;
    }
  }
}
